import React from "react";
import { compose } from 'recompose';
import _ from 'lodash';
import {
  Spinner,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Table,
  Button,
  Row,
  Col,
  Modal, ModalHeader, ModalBody, ModalFooter,
  Input,
  Media
} from "reactstrap";
import "bootstrap/dist/css/bootstrap.css";
import "assets/scss/paper-dashboard.scss";
import "assets/demo/demo.css";
import { Link } from 'react-router-dom';

//import Button from "components/CustomButton/CustomButton.jsx";

import { withAuthorization, withEmailVerification } from 'old-components/Session';
import { withFirebase } from 'old-components/Firebase';

import hulu from "assets/images/hulu.png";
import peacock from "assets/images/peacock.png";
import vudu from "assets/images/vudu.png";
import moviesanywhere from "assets/images/moviesanywhere.png";
import amazon from "assets/images/amazon.png";
import youtube from "assets/images/youtube.png";
import netflix from "assets/images/netflix.png";
import disney from "assets/images/disney.png";

const links = {
  youtube: 'https://www.youtube.com/watch?v=',
  netflix: 'https://www.netflix.com/watch/',
  amazon: 'https://www.amazon.com/gp/video/detail/',
  hulu: 'https://www.hulu.com/watch/',
  peacock: 'https://www.peacocktv.com/watch/playback/vod/',
  vudu: 'https://www.vudu.com/content/movies/play/',
  moviesanywhere: 'https://moviesanywhere.com/movie/',
  disney: 'https://www.disneyplus.com/video/',
}

const thead = ["Included", "Type", "Severity", "Time", "Duration", "Action", "Description", "ID"];
const tbody = [
  {
    className: "",
    data: ["included", "type", "severity", "time", "duration", "action", "end", "description", "id"]
  },
];

class RegularTables extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      documentTitle: '',
      documentDisclaimer: '',
      documentData: null,
      documentOffers: [],
      documentVideo: {
        poster: null,
        title: '',
        plot: '',
        year: '',
        production: '',
        runtime: '',
        rated: '',
        actors: [],
        genre: [],
        ratings: [],
      },
      tbody: [],
      filterSources: [],
      filterId: '',
      filterType: '',
      filterDescription: '',
      filterStart: '',
      filterEnd: '',
      filterAction: '',
      videoId: '',
      modalType: '',
      currentUser: null,
      filtersEnabled: 0,
      filtersTotal: null,
      suggestive1: true,
      suggestive2: true,
      suggestive3: true,
      suggestive4: true,
      suggestive5: true,
      language1: true,
      language2: true,
      language3: true,
      language4: true,
      language5: true,
      violence1: true,
      violence2: true,
      violence3: true,
      violence4: true,
      violence5: true,
      disturbing1: true,
      disturbing2: true,
      disturbing3: true,
      disturbing4: true,
      disturbing5: true,
      narcotics1: true,
      narcotics2: true,
      narcotics3: true,
      narcotics4: true,
      narcotics5: true,
      miscellaneous1: true,
      miscellaneous2: true,
      miscellaneous3: true,
      miscellaneous4: true,
      miscellaneous5: true,
    };

    this.exceptFilter = this.exceptFilter.bind(this);
    this.exceptFilterGroup = this.exceptFilterGroup.bind(this);
  }

  // Creates (or uncreates) a filter exception for this user and this video
  exceptFilter(data, event) {

    var videoId = this.props.match.params.id;
    var currentUser = this.state.currentUser;

    var checked = event.target.checked;

    console.log('Filter exception user=' + this.state.currentUser.uid + ', video=' + videoId + ', filter=' + data.id + ', checked=' + checked);

    // Make sure we have input
    if (videoId && currentUser) {

      var addException = false;

      // Check if this user has exceptions for this video
      var videoExceptions = _.get(currentUser.filterExceptions, videoId);

      // If they have NO exceptions for this video, create one
      if (!videoExceptions) {

        // Add this video and filter to the exceptions
        if (!currentUser.filterExceptions) {
          currentUser.filterExceptions = {};
        }
        currentUser.filterExceptions[videoId] = [ parseInt(data.id) ];
        addException = true;
      }

      // If they DO have exceptions, we either add this as one, or remove it (and consequently all exceptions if this is the last one)
      else {

        // IF WE ARE ADDING
        if (!checked) {

          // Add this video and filter to the exceptions
          currentUser.filterExceptions[videoId].push(parseInt(data.id));
          addException = true;
        }

        // IF WE ARE REMOVING
        else {

          // Remove it
          var indexOfTheOne = currentUser.filterExceptions[videoId].indexOf(parseInt(data.id));
          currentUser.filterExceptions[videoId].splice(indexOfTheOne, 1);

          // If there are none left, delete the filterException completely
          if (currentUser.filterExceptions[videoId].length === 0) {
            delete currentUser.filterExceptions[videoId]
          }
        }
      }

      // Update the data in Firestore
      var userRef = this.props.firebase.db.collection('users').doc(currentUser.uid);
      userRef.update({
        filterExceptions: currentUser.filterExceptions,
      })

      // Update the tbody so that locally we see it as an exception
      var tbodyState = this.state.tbody;

      // Get the one
      var item = _.findIndex(tbodyState, function(obj) {
        return obj.data.id === data.id;
      }.bind(this));

      // Update the item
      tbodyState[item].data.included = checked;

      // Update state
      this.setState({
        tbodyState,
        filtersEnabled: (addException ? this.state.filtersEnabled - 1 : this.state.filtersEnabled + 1),
      });
    }

    // Error
    else {
      console.log('ERROR! Invalid inputs.');
    }
  }

  // Pre-set the exceptFilterGroup state
  exceptFilterGroupPre(type, level, event) {

    if (type === 'suggestive') {
      if (level === 1) {
        this.setState({
          suggestive1: event.target.checked,
        });
      } else if (level === 2) {
        this.setState({
          suggestive2: event.target.checked,
        });
      } else if (level === 3) {
        this.setState({
          suggestive3: event.target.checked,
        });
      } else if (level === 4) {
        this.setState({
          suggestive4: event.target.checked,
        });
      } else if (level === 5) {
        this.setState({
          suggestive5: event.target.checked,
        });
      }
    }

    if (type === 'language') {
      if (level === 1) {
        this.setState({
          language1: event.target.checked,
        });
      } else if (level === 2) {
        this.setState({
          language2: event.target.checked,
        });
      } else if (level === 3) {
        this.setState({
          language3: event.target.checked,
        });
      } else if (level === 4) {
        this.setState({
          language4: event.target.checked,
        });
      } else if (level === 5) {
        this.setState({
          language5: event.target.checked,
        });
      }
    }

    if (type === 'violence') {
      if (level === 1) {
        this.setState({
          violence1: event.target.checked,
        });
      } else if (level === 2) {
        this.setState({
          violence2: event.target.checked,
        });
      } else if (level === 3) {
        this.setState({
          violence3: event.target.checked,
        });
      } else if (level === 4) {
        this.setState({
          violence4: event.target.checked,
        });
      } else if (level === 5) {
        this.setState({
          violence5: event.target.checked,
        });
      }
    }

    if (type === 'disturbing') {
      if (level === 1) {
        this.setState({
          disturbing1: event.target.checked,
        });
      } else if (level === 2) {
        this.setState({
          disturbing2: event.target.checked,
        });
      } else if (level === 3) {
        this.setState({
          disturbing3: event.target.checked,
        });
      } else if (level === 4) {
        this.setState({
          disturbing4: event.target.checked,
        });
      } else if (level === 5) {
        this.setState({
          disturbing5: event.target.checked,
        });
      }
    }

    if (type === 'narcotics') {
      if (level === 1) {
        this.setState({
          narcotics1: event.target.checked,
        });
      } else if (level === 2) {
        this.setState({
          narcotics2: event.target.checked,
        });
      } else if (level === 3) {
        this.setState({
          narcotics3: event.target.checked,
        });
      } else if (level === 4) {
        this.setState({
          narcotics4: event.target.checked,
        });
      } else if (level === 5) {
        this.setState({
          narcotics5: event.target.checked,
        });
      }
    }

    if (type === 'miscellaneous') {
      if (level === 1) {
        this.setState({
          miscellaneous1: event.target.checked,
        });
      } else if (level === 2) {
        this.setState({
          miscellaneous2: event.target.checked,
        });
      } else if (level === 3) {
        this.setState({
          miscellaneous3: event.target.checked,
        });
      } else if (level === 4) {
        this.setState({
          miscellaneous4: event.target.checked,
        });
      } else if (level === 5) {
        this.setState({
          miscellaneous5: event.target.checked,
        });
      }
    }

    this.exceptFilterGroup(type, level, event);
  }

  // Batch create (or uncreate) filter exceptions
  exceptFilterGroup(type, level, event) {

    var on = event.target.checked;

    console.log('Here! type=' + type + ', level=' + level + ', on=' + on + '...');

    var videoId = this.state.filterId;
    var currentUser = this.state.currentUser;

    //console.log('Group filter exception user=' + this.state.currentUser.uid + ', video=' + videoId + ', on=' + on + ', level=' + level + ', type=' + type);

    // Make sure we have input
    if (videoId && currentUser) {

      var changedExceptions = [];
      var changedNumber = 0;

      // Check if this user has exceptions for this video
      var videoExceptions = _.get(currentUser.filterExceptions, videoId);

      // If they have NO exceptions for this video, create one
      if (!videoExceptions) {

        // If they are enabling filters, then we do nothing (because they already have everything enabled)
        if (!on) {

          // Loop through all filters of this type and severity to set them to off
          for (var key in this.state.tbody) {

            var filter = this.state.tbody[key];

            // We only deal with it if the severity and the type match what we are changing
            if (filter.data.severity == level && filter.data.type == type) {

              // Add this video and filter to the exceptions
              if (!currentUser.filterExceptions) {
                currentUser.filterExceptions = {};
              }
              if (!currentUser.filterExceptions[videoId]) {
                currentUser.filterExceptions[videoId] = [ parseInt(filter.data.id) ];
              } else {
                currentUser.filterExceptions[videoId].push(parseInt(filter.data.id));
              }
              changedExceptions[key] = false;
              changedNumber -= 1;
            }
          };
        }
      }

      // If they DO have exceptions, we either add this as one, or remove it (and consequently all exceptions if this is the last one)
      else {

        // Loop through all filters of this type and severity to set them to off
        for (var key in this.state.tbody) {

          var filter = this.state.tbody[key];

          // We only deal with it if the severity and the type match what we are changing
          if (filter.data.severity == level && filter.data.type == type) {

            // IF WE ARE ADDING to the exceptions
            if (!on) {

              // Add this video and filter to the exceptions (if it doesn't yet exist)
              if (currentUser.filterExceptions[videoId].indexOf(parseInt(filter.data.id)) === -1) {
                currentUser.filterExceptions[videoId].push(parseInt(filter.data.id));
                changedExceptions[key] = false;
                changedNumber -= 1;
              }
            }

            // IF WE ARE REMOVING from the exceptions
            else {

              // Remove it (if it exists)
              var indexOfTheOne = currentUser.filterExceptions[videoId].indexOf(parseInt(filter.data.id));
              if (indexOfTheOne !== -1) {
                currentUser.filterExceptions[videoId].splice(indexOfTheOne, 1);
                changedExceptions[key] = true;
                changedNumber += 1;
              }

              // If there are none left, delete the filterException completely
              if (currentUser.filterExceptions[videoId].length === 0) {
                delete currentUser.filterExceptions[videoId]
              }
            }
          }
        };
      }

      // Update the data in Firestore
      var userRef = this.props.firebase.db.collection('users').doc(currentUser.uid);
      userRef.update({
        filterExceptions: currentUser.filterExceptions,
      });

      // Update the tbody so that locally we see it as an exception
      var tbodyState = this.state.tbody;

      // Loop through every filter that was changed and update it
      for (var key in changedExceptions) {
        tbodyState[key].data.included = changedExceptions[key];
      };

      // Update state
      this.setState({
        tbodyState,
        filtersEnabled: (changedExceptions.length ? this.state.filtersEnabled + changedNumber : this.state.filtersEnabled),
      });
    }

    // Error
    else {
      console.log('ERROR! Invalid inputs.');
    }
  }

  // Takes a set of offers and makes them into a string
  showOffers2 = (id, offers) => {

    let offerStrings = [];

    for (var i = 0; i < offers.length; i++) {

      if (offers[i].available && !offers[i].draft) {

        var image = null;
        if (offers[i].name === 'hulu') {
          image = hulu;
        } else if (offers[i].name === 'peacock') {
          image = peacock;
        } else if (offers[i].name === 'vudu') {
          image = vudu;
        } else if (offers[i].name === 'moviesanywhere') {
          image = moviesanywhere;
        } else if (offers[i].name === 'youtube') {
          image = youtube;
        } else if (offers[i].name === 'amazon') {
          image = amazon;
        } else if (offers[i].name === 'netflix') {
          image = netflix;
        } else if (offers[i].name === 'disney') {
          image = disney;
        }

        var subscription = '';
        if (offers[i].subscription) {
          if (offers[i].subscription === 'sub') {
            subscription = ' free/subscription';
          } else if (offers[i].subscription === 'pay') {
            subscription = ' rent/purchase';
          }
        }

        offerStrings.push(<div key={"key-div-" + offers[i].name + "-" + id}><img src={image} width="24" style={{marginBottom: '3px', marginRight: '2px'}} alt={offers[i].name} title={offers[i].name} /> {subscription}</div>);
      }
    }

    return offerStrings;
  }

  // Takes a set of offers and makes them into a string
  showOffers = (id, offers, filteredSources = [], onlyFiltered = false) => {

    /*console.log(`
      SHOWING OFFERS:
        id=${id}
        sources=${filteredSources.join(',')}
        only=${onlyFiltered}
    `, offers);*/

    let offerStrings = [];
    for (var i = 0; i < offers.length; i++) {

      //console.log('offer: ', offers[i]);

      var image = null;
      if (offers[i].platform === 'hulu') {
        image = hulu;
      } else if (offers[i].platform === 'peacock') {
        image = peacock;
      } else if (offers[i].platform === 'vudu') {
        image = vudu;
      } else if (offers[i].platform === 'moviesanywhere') {
        image = moviesanywhere;
      } else if (offers[i].platform === 'youtube') {
        image = youtube;
      } else if (offers[i].platform === 'amazon') {
        image = amazon;
      } else if (offers[i].platform === 'netflix') {
        image = netflix;
      } else if (offers[i].platform === 'disney') {
        image = disney;
      }

      var type = 'N/A';
      if (offers[i].type === 'buy') {
        type = 'Purchase for $' + offers[i].price;
      } else if (offers[i].type === 'rent') {
        type = 'Rent for $' + offers[i].price;
      } else if (offers[i].type === 'flatrate') {
        type = 'FREE w/ subscription';
      }

      // If we only want the filtered ones, only add this if it is filtered
      if ((onlyFiltered && filteredSources.indexOf(offers[i].platform) !== -1) || !onlyFiltered) {
        offerStrings.push(<div key={"key-div-" + offers[i].platform + "-" + id + "-" + offers[i].type + "-" + offers[i].presentation}><img src={image} width="24" style={{marginBottom: '3px', marginRight: '2px'}} alt={offers[i].platform} title={offers[i].platform} /> {type} ({offers[i].presentation})</div>);
      }
    }
    return offerStrings;
  }

  // On mount, grab all of the filters
  componentDidMount() {

    // Wait for a login event to set things
    this.listener = this.props.firebase.onAuthUserListener(

      // If we have a logged in user
      currentUser => {

        // Set the state
        this.setState({
          currentUser: currentUser,
        });

        // First, grab the filter document
        var videoId = this.props.match.params.id;

        // Does this user have an exception for this video?
        if (currentUser.filterExceptions) {

          // Get the exceptions
          var videoExceptions = _.get(currentUser.filterExceptions, videoId);
          //console.log('Initial load exceptions: ', videoExceptions);
        }

        // Grab a subscription to this filter (so that if it changes, it auto-updates)
        this.unsubscribe = this.props.firebase.db.collection('filters').doc(videoId).onSnapshot(doc => {

          // Start with an empty table of results
          var tbody = [];

          // Get the data
          var filterData = doc.data();

          var sortedFilters = filterData.filters.sort(function(a, b) {
            return parseFloat(a.start) - parseFloat(b.start);
          });

          var filtersEnabled = 0;
          var filtersTotal = 0;

          // Loop through each filter/video that we found
          sortedFilters.forEach(filter => {

            var include = !(videoExceptions && videoExceptions.indexOf(parseInt(filter.id)) !== -1);
            if (include) {
              filtersEnabled++;
            }
            filtersTotal++;

            // Add to the table
            tbody.push({
              className: "",
              data: {
                included: include,
                type: filter.type,
                severity: filter.severity,
                time: filter.start,
                end: filter.end,
                description: filter.description,
                id: filter.id,
                action: filter.action,
              },
            });
          });

          this.setState({
            tbody: tbody,
            documentTitle: filterData.title,
            documentDisclaimer: filterData.disclaimer,
            documentData: filterData,
            documentOffers: (!_.isEmpty(filterData.offers) ? filterData.offers : []),
            documentVideo: (!_.isEmpty(filterData.vidInfo) ? filterData.vidInfo : {
              poster: '',
              title: filterData.title,
              plot: '',
              year: '',
              production: '',
              runtime: '',
              rated: 'n/a',
              actors: [],
              genre: [],
              ratings: [],
            }),
            filterSources: filterData.sources,
            filterId: doc.id,
            filtersEnabled: filtersEnabled,
            filtersTotal: filtersTotal,
          });
        });
      }
    );
  }

  componentWillUnmount() {
    this.unsubscribe && this.unsubscribe();
    this.listener();
  }

  // Format time friendly
  formatTimeFriendly(val) {

    var newVal = val;

    // Cut off any excessive leading zeros
    newVal = newVal.replace(/^00:/, '');

    // Take off the decimals, unless it's under a second
    if (!newVal.match(/^00:00\./)) {
      newVal = newVal.replace(/...$/, '');
    }

    return newVal;
  }

  // Convert float to time
  floatToTime(float) {

    // Hours
    var hours = Math.floor(float/3600.00);
    float -= hours*3600.00;
    var minutes = Math.floor(float/60.00);
    float -= minutes*60.00;

    // Make sure we always have 2 decimals
    float = float.toFixed(2);
    var split = float.split('.');
    var seconds = split[0];
    var micros = split[1];

    // Return a combined value
    return `${hours.toString().length === 1 ? '0' + hours.toString() : hours.toString()}:${minutes.toString().length === 1 ? '0' + minutes.toString() : minutes.toString()}:${seconds.length === 1 ? '0' + seconds + '.' + micros : seconds + '.' + micros}`;
  }

  // Creates ratings
  createRatings = () => {
    let ratings = []
    if (this.state.documentVideo.ratings && Object.keys(this.state.documentVideo.ratings).length > 0) {
      Object.values(this.state.documentVideo.ratings).map(function(thing) {
        ratings.push(<span key={'rating-span-' + thing.source}><strong>{thing.source}</strong> {thing.value}<br /></span>);
      });
    }
    return ratings;
  }

  // Takes multiple sources and creates links to the videos from them
  createLinks = () => {

    //console.log('Offers: ', this.state.documentOffers);
    //console.log('Sources: ', this.state.filterSources);

    let filterActions = []
    for (var i = 0; i < this.state.filterSources.length; i++) {
      var thisSource = this.state.filterSources[i];
      var image = null;
      if (thisSource.name === 'hulu') {
        image = hulu;
      } else if (thisSource.name === 'peacock') {
        image = peacock;
      } else if (thisSource.name === 'vudu') {
        image = vudu;
      } else if (thisSource.name === 'moviesanywhere') {
        image = moviesanywhere;
      } else if (thisSource.name === 'youtube') {
        image = youtube;
      } else if (thisSource.name === 'amazon') {
        image = amazon;
      } else if (thisSource.name === 'netflix') {
        image = netflix;
      } else if (thisSource.name === 'disney') {
        image = disney;
      }
      if (image !== null) {

        //var filterSourceMatches = _.find(this.state.documentOffers, function(o) {
        //  return thisSource.name === o.platform;
        //});
        //console.log('WHAAAAA', filterSourceMatches);

        //if (filterSourceMatches !== undefined || this.state.documentOffers.length === 0) {
        if (thisSource.available && !thisSource.draft) {
          filterActions.push(<Button color="primary" onClick={() => this.createPlayLink(thisSource.name, this.state.filterId)}>Watch on {thisSource.name}</Button>);
        }

        //filterActions.push(<a target="_blank" key={this.state.filterId + thisSource} rel="noopener noreferrer">Watch on {thisSource}  /></a>);
        //filterActions.push(<a target="_blank" key={this.state.filterId + thisSource} rel="noopener noreferrer"><img src={image} width="24" alt={thisSource} title="Click to watch!" key={this.state.filterId + thisSource} onClick={() => this.createPlayLink(thisSource, this.state.filterId)} /></a>);
      }
    }
    return filterActions;
  }

  // Creates a play link
  createPlayLink = (source, id) => {

    // We can't get the filters to load faster than about 2 or 3 seconds into the video
    // So, since YouTube gives us an option to start the video later, we check if there
    // are filters within the first 3.5 seconds of the video, and if there are, we get
    // the first gap long enough after those filters and start the video then.

    var additionalOptions = '';
    if (source === 'youtube') {

      // Get the filters in sorted order by start time (descending)
      var sortedFilters = _.orderBy(this.state.tbody, function(o) {
        return o.data.time;
      }, ['desc']);

      // Loop through to find the filter that starts closest before 3.5 seconds and the one that provides a 3.5 second gap
      var skipAfterFilter = {};
      var gapAfterFilter = {};
      var oldEndTime = 999999999.0;

      _.forEach(sortedFilters, function(val) {
        var endGap = oldEndTime - val.data.end;
        oldEndTime = val.data.end;

        // If we have a gap greater than 3.5 seconds, save it
        if (endGap > 3.5) {
          gapAfterFilter = val.data;
        }

        // If we are at a filter that starts before 3.5 seconds, we save it and end the loop
        if (val.data.time < 3.5) {
          skipAfterFilter = val.data;
          return false;
        }
      });

      // If we have a gap filter, then we use it, otherwise a skip filter if we have one
      var usedFilter = {};

      // But we only do it if there is at least one skip filter
      if (!_.isEmpty(skipAfterFilter)) {
        if (!_.isEmpty(gapAfterFilter)) {
          usedFilter = gapAfterFilter;
        } else {
          usedFilter = skipAfterFilter;
        }
      }

      if(!_.isEmpty(usedFilter)) {
        additionalOptions = '&start=' + Math.ceil(usedFilter.end);
      }
    }

    // Get our reference point
    var filterRef = this.props.firebase.db.collection('filters').doc(id);

    // Get all sources for this filter set
    this.props.firebase.db.collection('sources').where('filters', '==', filterRef).get().then(function(snapshot) {
      snapshot.forEach(function(doc) {

        // TODO: Here we can set up the filters, etc. for the speciifc user and authenticate them, etc.

        var sourceData = doc.data();

        // Only if the source matches
        if (sourceData.name === source) {
          var theLink = links[source] + sourceData.url + additionalOptions;
          //if (sourceData.name === 'amazon') {
          //  theLink += '?autoplay=1&t=0';
          //}
          window.open(theLink, '_blank');
        }
      });
    });
  }

  // Render everything
  render() {

    // The markup to include below
    var markup;

    // If we don't have data yet
    if (this.state.documentVideo.poster === null) {
      markup = (
        <div>
          <Spinner coolor="primary" />
        </div>
      );
    }

    // If there is no poster at all (i.e. it's a video or a movie/series without a poster)
    else if (this.state.documentVideo.poster == '') {
      markup = (
        <Media>
          <Media body>
            <Media heading>
              {this.state.documentVideo.title}
            </Media>
            <p>
              <strong>Watch now with {this.state.filtersEnabled + '/' + this.state.filtersTotal} filters: </strong>
              {this.showOffers2(this.state.filterId, this.state.filterSources)}
              {this.createLinks()}
            </p>
          </Media>
        </Media>
      );
    }

    // If we have poster data
    else {
      markup = (
        <Media>
          <Media left>
            <Media object src={this.state.documentVideo.poster} alt="Video Poster" />
          </Media>
          <Media body className="pl-3">
            <Media heading className="mt-1">
              {(this.state.documentData.series ? this.state.documentData.series + ' (' + this.state.documentVideo.year + ') Season ' + this.state.documentData.season + ' Episode ' + this.state.documentData.episode + ' (' + this.state.documentData.title + ')' : this.state.documentVideo.title + ' (' + this.state.documentVideo.year + ')')}
            </Media>
            <p><strong>{(this.state.documentVideo.production ? this.state.documentVideo.production : '') + ' (' +  this.state.documentVideo.runtime + ', Rated: ' + this.state.documentVideo.rated + ')'}</strong></p>
            {this.state.documentVideo.genre && Object.keys(this.state.documentVideo.genre).length > 0 ? <p><em>{Object.values(this.state.documentVideo.genre).join(', ')}</em></p> : <span />}
            <p>{this.state.documentVideo.plot}</p>
            {this.state.documentVideo.actors && Object.keys(this.state.documentVideo.actors).length > 0 ? <p><strong>Starring:</strong> {Object.values(this.state.documentVideo.actors).join(', ')}</p> : <span />}
            <p>{this.createRatings()}</p>
            <p>
              <strong>Watch {(this.state.documentData.series ? ' season ' + this.state.documentData.season + ' episode ' + this.state.documentData.episode + ' (' + this.state.documentData.title + ') ' : '')} now with {this.state.filtersEnabled + '/' + this.state.filtersTotal} filters: </strong>
              {this.showOffers2(this.state.filterId, this.state.filterSources)}
              {this.createLinks()}
              {
                this.state.documentDisclaimer
                ?
                  <div>
                    <strong>*** Parental Warning *** </strong> {this.state.documentDisclaimer}
                  </div>
                :
                  null
              }
            </p>
          </Media>
        </Media>
      );
    }

    // Whether we have filters or not
    var filters;
    var exceptFilterGroup;
    if (this.state.filtersTotal === null) {
      filters = (
        <span />
      );
    } else if (this.state.filtersTotal > 0) {

      // This provides a way for people to quickly adjust filters
      exceptFilterGroup = (
        <div>
          <h6>Quickly adjust multiple filters...</h6>
          <Table responsive>
            <thead className="text-primary">
              <tr>
                <th>Suggestive</th>
                <th>Language</th>
                <th>Violence</th>
                <th>Disturbing</th>
                <th>Narcotics</th>
                <th>Miscellaneous</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="customSwitch1" checked={this.state.suggestive1} onClick={(e) => this.exceptFilterGroupPre('suggestive', 1, e)} />
                    <label class="custom-control-label" for="customSwitch1" style={{paddingTop: '4px'}}>Lv. 1</label>
                  </div>
                </td>
                <td>
                  <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="customSwitch2" checked={this.state.language1} onClick={(e) => this.exceptFilterGroupPre('language', 1, e)} />
                    <label class="custom-control-label" for="customSwitch2" style={{paddingTop: '4px'}}>Lv. 1</label>
                  </div>
                </td>
                <td>
                  <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="customSwitch3" checked={this.state.violence1} onClick={(e) => this.exceptFilterGroupPre('violence', 1, e)} />
                    <label class="custom-control-label" for="customSwitch3" style={{paddingTop: '4px'}}>Lv. 1</label>
                  </div>
                </td>
                <td>
                  <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="customSwitch4" checked={this.state.disturbing1} onClick={(e) => this.exceptFilterGroupPre('disturbing', 1, e)} />
                    <label class="custom-control-label" for="customSwitch4" style={{paddingTop: '4px'}}>Lv. 1</label>
                  </div>
                </td>
                <td>
                  <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="customSwitch5" checked={this.state.narcotics1} onClick={(e) => this.exceptFilterGroupPre('narcotics', 1, e)} />
                    <label class="custom-control-label" for="customSwitch5" style={{paddingTop: '4px'}}>Lv. 1</label>
                  </div>
                </td>
                <td>
                  <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="customSwitch6" checked={this.state.miscellaneous1} onClick={(e) => this.exceptFilterGroupPre('miscellaneous', 1, e)} />
                    <label class="custom-control-label" for="customSwitch6" style={{paddingTop: '4px'}}>Lv. 1</label>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="customSwitch7" checked={this.state.suggestive2} onClick={(e) => this.exceptFilterGroupPre('suggestive', 2, e)} />
                    <label class="custom-control-label" for="customSwitch7" style={{paddingTop: '4px'}}>Lv. 2</label>
                  </div>
                </td>
                <td>
                  <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="customSwitch8" checked={this.state.language2} onClick={(e) => this.exceptFilterGroupPre('language', 2, e)} />
                    <label class="custom-control-label" for="customSwitch8" style={{paddingTop: '4px'}}>Lv. 2</label>
                  </div>
                </td>
                <td>
                  <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="customSwitch9" checked={this.state.violence2} onClick={(e) => this.exceptFilterGroupPre('violence', 2, e)} />
                    <label class="custom-control-label" for="customSwitch9" style={{paddingTop: '4px'}}>Lv. 2</label>
                  </div>
                </td>
                <td>
                  <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="customSwitch10" checked={this.state.disturbing2} onClick={(e) => this.exceptFilterGroupPre('disturbing', 2, e)} />
                    <label class="custom-control-label" for="customSwitch10" style={{paddingTop: '4px'}}>Lv. 2</label>
                  </div>
                </td>
                <td>
                  <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="customSwitch11" checked={this.state.narcotics2} onClick={(e) => this.exceptFilterGroupPre('narcotics', 2, e)} />
                    <label class="custom-control-label" for="customSwitch11" style={{paddingTop: '4px'}}>Lv. 2</label>
                  </div>
                </td>
                <td>
                  <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="customSwitch12" checked={this.state.miscellaneous2} onClick={(e) => this.exceptFilterGroupPre('miscellaneous', 2, e)} />
                    <label class="custom-control-label" for="customSwitch12" style={{paddingTop: '4px'}}>Lv. 2</label>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="customSwitch13" checked={this.state.suggestive3} onClick={(e) => this.exceptFilterGroupPre('suggestive', 3, e)} />
                    <label class="custom-control-label" for="customSwitch13" style={{paddingTop: '4px'}}>Lv. 3</label>
                  </div>
                </td>
                <td>
                  <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="customSwitch14" checked={this.state.language3} onClick={(e) => this.exceptFilterGroupPre('language', 3, e)} />
                    <label class="custom-control-label" for="customSwitch14" style={{paddingTop: '4px'}}>Lv. 3</label>
                  </div>
                </td>
                <td>
                  <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="customSwitch15" checked={this.state.violence3} onClick={(e) => this.exceptFilterGroupPre('violence', 3, e)} />
                    <label class="custom-control-label" for="customSwitch15" style={{paddingTop: '4px'}}>Lv. 3</label>
                  </div>
                </td>
                <td>
                  <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="customSwitch16" checked={this.state.disturbing3} onClick={(e) => this.exceptFilterGroupPre('disturbing', 3, e)} />
                    <label class="custom-control-label" for="customSwitch16" style={{paddingTop: '4px'}}>Lv. 3</label>
                  </div>
                </td>
                <td>
                  <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="customSwitch17" checked={this.state.narcotics3} onClick={(e) => this.exceptFilterGroupPre('narcotics', 3, e)} />
                    <label class="custom-control-label" for="customSwitch17" style={{paddingTop: '4px'}}>Lv. 3</label>
                  </div>
                </td>
                <td>
                  <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="customSwitch18" checked={this.state.miscellaneous3} onClick={(e) => this.exceptFilterGroupPre('miscellaneous', 3, e)} />
                    <label class="custom-control-label" for="customSwitch18" style={{paddingTop: '4px'}}>Lv. 3</label>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="customSwitch19" checked={this.state.suggestive4} onClick={(e) => this.exceptFilterGroupPre('suggestive', 4, e)} />
                    <label class="custom-control-label" for="customSwitch19" style={{paddingTop: '4px'}}>Lv. 4</label>
                  </div>
                </td>
                <td>
                  <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="customSwitch20" checked={this.state.language4} onClick={(e) => this.exceptFilterGroupPre('language', 4, e)} />
                    <label class="custom-control-label" for="customSwitch20" style={{paddingTop: '4px'}}>Lv. 4</label>
                  </div>
                </td>
                <td>
                  <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="customSwitch21" checked={this.state.violence4} onClick={(e) => this.exceptFilterGroupPre('violence', 4, e)} />
                    <label class="custom-control-label" for="customSwitch21" style={{paddingTop: '4px'}}>Lv. 4</label>
                  </div>
                </td>
                <td>
                  <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="customSwitch22" checked={this.state.disturbing4} onClick={(e) => this.exceptFilterGroupPre('disturbing', 4, e)} />
                    <label class="custom-control-label" for="customSwitch22" style={{paddingTop: '4px'}}>Lv. 4</label>
                  </div>
                </td>
                <td>
                  <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="customSwitch23" checked={this.state.narcotics4} onClick={(e) => this.exceptFilterGroupPre('narcotics', 4, e)} />
                    <label class="custom-control-label" for="customSwitch23" style={{paddingTop: '4px'}}>Lv. 4</label>
                  </div>
                </td>
                <td>
                  <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="customSwitch24" checked={this.state.miscellaneous4} onClick={(e) => this.exceptFilterGroupPre('miscellaneous', 4, e)} />
                    <label class="custom-control-label" for="customSwitch24" style={{paddingTop: '4px'}}>Lv. 4</label>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="customSwitch25" checked={this.state.suggestive5} onClick={(e) => this.exceptFilterGroupPre('suggestive', 5, e)} />
                    <label class="custom-control-label" for="customSwitch25" style={{paddingTop: '4px'}}>Lv. 5</label>
                  </div>
                </td>
                <td>
                  <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="customSwitch26" checked={this.state.language5} onClick={(e) => this.exceptFilterGroupPre('language', 5, e)} />
                    <label class="custom-control-label" for="customSwitch26" style={{paddingTop: '4px'}}>Lv. 5</label>
                  </div>
                </td>
                <td>
                  <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="customSwitch27" checked={this.state.violence5} onClick={(e) => this.exceptFilterGroupPre('violence', 5, e)} />
                    <label class="custom-control-label" for="customSwitch27" style={{paddingTop: '4px'}}>Lv. 5</label>
                  </div>
                </td>
                <td>
                  <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="customSwitch28" checked={this.state.disturbing5} onClick={(e) => this.exceptFilterGroupPre('disturbing', 5, e)} />
                    <label class="custom-control-label" for="customSwitch28" style={{paddingTop: '4px'}}>Lv. 5</label>
                  </div>
                </td>
                <td>
                  <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="customSwitch29" checked={this.state.narcotics5} onClick={(e) => this.exceptFilterGroupPre('narcotics', 5, e)} />
                    <label class="custom-control-label" for="customSwitch29" style={{paddingTop: '4px'}}>Lv. 5</label>
                  </div>
                </td>
                <td>
                  <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="customSwitch30" checked={this.state.miscellaneous5} onClick={(e) => this.exceptFilterGroupPre('miscellaneous', 5, e)} />
                    <label class="custom-control-label" for="customSwitch30" style={{paddingTop: '4px'}}>Lv. 5</label>
                  </div>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      );

      // This shows and allows adjustments to individual filters
      filters = (
        <div>
          <h6>...or select/de-select individual filters here.</h6>
          <Table responsive>
            <thead className="text-primary">
              <tr>
                {thead.map((prop, key) => {
                  if (key !== thead.length - 1) {
                    return <th key={key}>{prop}</th>;
                  } else {
                    return null;
                  }
                })}
              </tr>
            </thead>
            <tbody>
              {this.state.tbody.map((prop, key) => {
                return (
                  <tr key={key}>
                    <td>
                      <input type="checkbox" cursor="default" onChange={(e) => this.exceptFilter(prop.data, e)} checked={prop.data.included} />
                    </td>
                    <td>{prop.data.type}</td>
                    <td style={{textAlign: 'center'}}>{prop.data.severity}</td>
                    <td style={{fontFamily: 'Consolas, monaco, monospaced', textAlign: 'right', paddingRight: '10px'}}>{this.formatTimeFriendly(this.floatToTime(prop.data.time))}</td>
                    <td style={{fontFamily: 'Consolas, monaco, monospaced', textAlign: 'right', paddingRight: '10px'}}>{(prop.data.end - prop.data.time).toFixed(2) + 's'}</td>
                    <td>{prop.data.action}</td>
                    <td>{prop.data.description}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      );
    } else {
      filters = (
        <div><strong>No filters available</strong></div>
      );
    }

    return (
      <div className="content">
        <Row>
          <Col xs={12}>
            <Card>
              <CardHeader>
                {markup}
              </CardHeader>
              <CardBody>
                {exceptFilterGroup}
                {filters}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

const condition = authUser => !!authUser;

export default compose(
  withFirebase,
  withEmailVerification,
  withAuthorization(condition),
)(RegularTables);
