import React, { Component } from "react";
import { Row, Col, Input, Button } from "reactstrap";
import { compose } from 'recompose';

import {
  withAuthorization,
  withEmailVerification,
} from 'old-components/Session';
import { withFirebase } from 'old-components/Firebase';

class TraverseTree extends Component {

  constructor(props) {
    super(props);
    this.state = {
      pass: '',
    };
  }

  // On mount, grab all of the filters
  componentDidMount() {

    // Wait for a login event to set things
    this.listener = this.props.firebase.onAuthUserListener(

      // If we have a logged in user
      currentUser => {

        // Set the state
        this.setState({
          pass: '',
        });
      }
    );
  }

  componentWillUnmount() {
    this.listener();
  }

  // When they submit
  traverseTreeCheck = event => {

    // Make sure they got the pass correct
    if (this.state.pass === 'traversity') {









// ========== THIS GOES THROUGH ALL FILTERS AND ADJUSTS CERTAIN DATA LIKE TYPE, ACTION AND SEVERITY ========== //


      /*// Update all filters so tha their severity is one higher than currently set
      this.props.firebase.db.collection('filters').get().then(function(snapshot) {

        //var i = 0;

        // Loop through each filter document
        snapshot.forEach(function(doc) {

          // Make sure it is existing (should always be)
          if (doc.exists) {

            // Get all of the data
            var filterRef = doc.ref;
            var filterData = doc.data();
            var filters = filterData.filters;
            var newFilters = [];

            console.log('===== Updating: ' + filterData.title + ' ' + (filterData.series ? ' (' + filterData.series + ')' : '') + ' =====');
            //console.log('Filters before', filters);

            // Loop through the filters
            filters.forEach(function (item, index) {
              var thisFilter = item;

              // If the action needs to be updated
              if (thisFilter.action === 'block') {
                thisFilter.action = 'blur';
                //console.log('    Action changed from block to blur...');
              }

              // If the type needs to be updated
              if (thisFilter.type === 'sex') {
                thisFilter.type = 'suggestive';
                //console.log('    Type changed from sex to suggestive...');
              } else if (thisFilter.type === 'drugs') {
                thisFilter.type = 'narcotics';
                //console.log('    Type changed from drugs to narcotics...');
              } else if (thisFilter.type === 'credits') {
                thisFilter.type = 'miscellaneous';
                //console.log('    Type changed from credits to miscellaneous...');
              }

              // Increment the severity (only if this is not a miscellaneous filter)
              if (thisFilter.type !== 'miscellaneous') {
                if (thisFilter.severity === 1) {
                  thisFilter.severity = 2;
                } else if (thisFilter.severity === 2) {
                  thisFilter.severity = 3;
                } else if (thisFilter.severity === 3) {
                  thisFilter.severity = 4;
                }
              }

              // Add the new filter to the empty array
              newFilters.push(thisFilter);
            });

            //console.log('Filters after', newFilters);

            // Now update the database
            filterRef.update({
              filters: newFilters,
            }).then(function () {
              console.log('===== Success: ' + filterData.title + ' ' + (filterData.series ? ' (' + filterData.series + ')' : '') + ' =====');
            }).catch(function (error) {
              console.log('===== Error: ' + filterData.title + ' ' + (filterData.series ? ' (' + filterData.series + ')' : '') + ' =====', error);
            });

            //i++;
          }

          // Basically like a "break;"
          //if (i > 10) {
          //  throw "cancel";
          //}
        });
      });*/

// ========== END ========== //








    }
  };

  // When they change the password
  onChange = event => {
    this.setState({
      pass: event.target.value,
    });
  };

  render() {
    return (
      <div className="content">
        Enter the password to traverse this tree:
        <Input type="text" name="password" placeholder="Pass" value={this.state.pass} onChange={this.onChange} className="mb-3" />
        <Button color="primary" type="submit" onClick={this.traverseTreeCheck}>Do It</Button>
      </div>
    );
  }
}

const condition = authUser =>
  authUser && authUser.permission === 'admin';

export default compose(
  withFirebase,
  withEmailVerification,
  withAuthorization(condition),
)(TraverseTree);
