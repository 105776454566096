import React from "react";
import { compose } from 'recompose';
import _ from 'lodash';
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Table,
  Row,
  Col,
  Modal, ModalHeader, ModalBody, ModalFooter,
  Input
} from "reactstrap";
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';

import 'react-vertical-timeline-component/style.min.css';
import "bootstrap/dist/css/bootstrap.css";
import "assets/scss/paper-dashboard.scss";
import "assets/demo/demo.css";

import Button from "components/CustomButton/CustomButton.jsx";
import logo from "assets/newlogo.png";

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faIgloo, faFilter, faWineGlass, faWineGlassAlt } from '@fortawesome/free-solid-svg-icons';
import { faBell } from '@fortawesome/free-regular-svg-icons';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons';







import Slider, { Rail, Handles, Tracks, Ticks } from 'react-compound-slider';
import { SliderRail, Handle, Track, Tick } from './components';









import { withAuthorization, withEmailVerification } from 'old-components/Session';
import { withFirebase } from 'old-components/Firebase';

const thead = ["Type", "Severity", "Description", "ID", "Start", "End", "Action", "Actions", "ID"];
const tbody = [
  {
    className: "",
    data: ["type", "severity", "description", "id", "start", "end", "action", "actions", "id"]
  },
];

class RegularTables extends React.Component {

  /*onUpdate = update => {
    console.log('UPDATE!!!!!', update);
    this.setState({ update })
  }*/

  onChangeSliderValues2(something) {
    console.log('Something2: ', something);
  }
  onChangeSliderValues = sliderValues => {
    console.log('Slider values are changing: ', sliderValues);
  }

  constructor(props) {
    super(props);

    this.state = {
      documentTitle: '',
      tbody: [],
      filterId: '',
      filterType: '',
      filterSeverity: null,
      filterDescription: '',
      filterStart: '',
      filterEnd: '',
      filterAction: '',
      videoId: '',
      modalType: '',
      adjustAllFiltersTime: '',
      sliderValues: [7.9, 8.1, 8.2, 8.4].slice(),
    };

    this.closeModal = this.closeModal.bind(this);
    this.preToggleModal = this.preToggleModal.bind(this);
    this.deleteFilter = this.deleteFilter.bind(this);
    this.editFilter = this.editFilter.bind(this);
    this.adjustAllFilters = this.adjustAllFilters.bind(this);
    this.handleAdjustAllFiltersChange = this.handleAdjustAllFiltersChange.bind(this);

    this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
    this.handleTypeChange = this.handleTypeChange.bind(this);
    this.handleSeverityChange = this.handleSeverityChange.bind(this);
    this.handleStartChange = this.handleStartChange.bind(this);
    this.handleEndChange = this.handleEndChange.bind(this);
    this.handleActionChange = this.handleActionChange.bind(this);
  }

  // Description changed
  handleDescriptionChange(event) {
    this.setState({
      filterDescription: event.target.value,
    });
  }
  // Type changed
  handleTypeChange(event) {
    this.setState({
      filterType: event.target.value,
    });
  }
  // Severity changed
  handleSeverityChange(event) {
    this.setState({
      filterSeverity: parseInt(event.target.value),
    });
  }
  // Start changed
  handleStartChange(event) {
    this.setState({
      filterStart: event.target.value,
    });
  }
  // End changed
  handleEndChange(event) {
    this.setState({
      filterEnd: event.target.value,
    });
  }
  // Action changed
  handleActionChange(event) {
    this.setState({
      filterAction: event.target.value,
    });
  }

  // Show our modal
  preToggleModal(type, data, key) {

    // Set our modal data
    this.setState({
      filterType: data.type,
      filterSeverity: data.severity,
      filterDescription: data.description,
      filterStart: data.start,
      filterEnd: data.end,
      filterAction: data.action,
      filterId: data.id,
      videoId: data.id,
      modalType: type,
    });
  }

  // Hide our modal
  closeModal() {
    this.setState(prevState => ({
      filterType: '',
      filterSeverity: null,
      filterDescription: '',
      filterStart: '',
      filterEnd: '',
      filterAction: '',
      filterId: '',
      videoId: '',
      modalType: '',
    }));
  };

  // Deletes a filter
  // To do so, we need to get all filters and then take one of them out in the code and then re-send the full list to the server
  deleteFilter(event) {

    // Make sure no other action is taken
    event.preventDefault();

    console.log('Deleting (2b) filter ' + this.state.filterId + ' from video ' + this.props.match.params.id);

    // Make sure we have input
    if (this.state.filterId !== '') {

      // Get our reference point
      var videoId = this.props.match.params.id;
      var filterRef = this.props.firebase.db.collection('filters').doc(videoId);

      // Change tbody
      var tbodyState = this.state.tbody;

      // Get the one
      var item = _.findIndex(tbodyState, function(obj) {
        return obj.data.id === this.state.filterId;
      }.bind(this));

      console.log('Deleting this one (id=' + this.state.filterId + ', index=' + item + '): ', tbodyState[item]);

      // Remove it
      tbodyState.splice(item, 1);

      // Update state
      this.setState({
        tbodyState,
      });

      // Loop to restructure the data
      var savedArray = [];
      for (var i = 0; i < tbodyState.length; i++) {
        savedArray.push(tbodyState[i].data);
      }

      // Update the server
      filterRef.update({
        filters: savedArray,
      });

      this.closeModal();
    }

    // Error
    else {
      console.log('ERROR! Invalid inputs.');
    }
  }

  // Edits a filter
  editFilter(event) {

    // Make sure no other action is taken
    event.preventDefault();

    // Make sure we have input
    if (this.state.filterId !== '' && this.state.filterSeverity !== null && this.state.filterType !== '' && this.state.filterDescription !== '' && this.state.filterStart !== '' && this.state.filterEnd !== '' && this.state.filterAction !== '') {

      // Get our reference point
      var videoId = this.props.match.params.id;
      var filterRef = this.props.firebase.db.collection('filters').doc(videoId);

      // Change tbody
      var tbodyState = this.state.tbody;

      // Get the one
      var item = _.findIndex(tbodyState, function(obj) {
        return obj.data.id === this.state.filterId;
      }.bind(this));

      //console.log('Changing this one (id=' + this.state.filterId + ', index=' + item + '): ', tbodyState[item]);

      // Change that one filter
      tbodyState[item].data = {
        id: this.state.filterId,
        type: this.state.filterType,
        severity: this.state.filterSeverity ? this.state.filterSeverity : 3,
        description: this.state.filterDescription,
        end: this.state.filterEnd,
        start: this.state.filterStart,
        action: this.state.filterAction,
      };

      // Update state
      this.setState({
        tbodyState,
      });

      // Loop to restructure the data
      var savedArray = [];
      for (var i = 0; i < tbodyState.length; i++) {
        savedArray.push(tbodyState[i].data);
      }

      // Update the server
      filterRef.update({
        filters: savedArray,
      });

      this.closeModal();
    }

    // Error
    else {
      console.log('ERROR! Invalid inputs.');
    }
  }

  // adjustAllFilters Changed
  handleAdjustAllFiltersChange(event) {
    var newValue = event.target.value;
    this.setState({
      adjustAllFiltersTime: newValue,
    });
  }
  // Allows a user to adjust all filters slightly (mostly used when a swear pre-set captions file was off by a little bit)
  adjustAllFilters(event) {
    event.preventDefault();
    var floatValue = parseFloat(this.state.adjustAllFiltersTime);
    if (!isNaN(floatValue) && floatValue != 0) {
      var nonFloatRounded = floatValue.toFixed(2);
      var floatValueRounded = parseFloat(nonFloatRounded);

      // Get our reference point
      var videoId = this.props.match.params.id;
      var filterRef = this.props.firebase.db.collection('filters').doc(videoId);

      // Change tbody
      var tbodyState = this.state.tbody;

      // Loop through each filter
      for (var i = 0; i < tbodyState.length; i++) {
        var item = tbodyState[i];
        var oldStart = parseFloat(item.data.start);
        var oldEnd = parseFloat(item.data.end);
        var newStart = parseFloat((oldStart + floatValueRounded).toFixed(2));
        var newEnd = parseFloat((oldEnd + floatValueRounded).toFixed(2));

        //console.log('Changing oldStart (' + oldStart + ') to newStart (' + newStart + ' for filter', item);

        tbodyState[i].data.start = newStart;
        tbodyState[i].data.end = newEnd;
      }

      // Update state
      this.setState({
        tbodyState,
      });

      // Loop to restructure the data
      var savedArray = [];
      for (var i = 0; i < tbodyState.length; i++) {
        savedArray.push(tbodyState[i].data);
      }

      // Update the server
      filterRef.update({
        filters: savedArray,
      });
    }
  }

  // On mount, grab all of the filters
  componentDidMount() {

    library.add(faIgloo);
    library.add(faWineGlass);
    library.add(faWineGlassAlt);
    library.add(faFilter);
    library.add(faFacebookF);
    library.add(faBell);

    // First, grab the filter document
    var videoId = this.props.match.params.id;

    // Grab a subscription to this filter (so that if it changes, it auto-updates)
    this.unsubscribe = this.props.firebase.db.collection('filters').doc(videoId).onSnapshot(doc => {

      // Start with an empty table of results
      var tbody = [];

      // Get the data
      var filterData = doc.data();

      var sortedFilters = filterData.filters.sort(function(a, b) {
        return parseFloat(a.start) - parseFloat(b.start);
      });

      // Loop through each filter/video that we found
      sortedFilters.forEach(filter => {

        // Add to the table
        tbody.push({
          className: "",
          data: {
            type: filter.type,
            severity: filter.severity === undefined ? null : filter.severity,
            description: filter.description,
            start: filter.start,
            end: filter.end,
            action: filter.action,
            id: filter.id,
          },
        });

        this.setState({
          documentTitle: filterData.title,
        });
      });

      // Update the state
      this.setState({
        tbody,
      });

    });
  }

  componentWillUnmount() {
    this.unsubscribe && this.unsubscribe();
  }

  // Convert float to time
  floatToTime(float) {

    // Hours
    var hours = Math.floor(float/3600.00);
    float -= hours*3600.00;
    var minutes = Math.floor(float/60.00);
    float -= minutes*60.00;

    // Make sure we always have 2 decimals
    float = float.toFixed(2);
    var split = float.split('.');
    var seconds = split[0];
    var micros = split[1];

    // Return a combined value
    return `${hours.toString().length === 1 ? '0' + hours.toString() : hours.toString()}:${minutes.toString().length === 1 ? '0' + minutes.toString() : minutes.toString()}:${seconds.length === 1 ? '0' + seconds + '.' + micros : seconds + '.' + micros}`;
  }

  // Creates edit actions for filters and sources
  createActions = (data, key) => {

    return (
      <div>
        <i className='fa fa-trash-alt' onClick={() => this.preToggleModal('filterdel', data, key)} title='Delete this filter' />
        &nbsp; &nbsp;
        <i className='fa fa-edit' onClick={() => this.preToggleModal('filteredit', data, key)} title="Edit this filter" />
      </div>
    );
  }

  // Creates a severity dropdown list based on which filterType is selectd
  createSeverity = () => {

    // 'sex' filter severities
    if (this.state.filterType === 'sex') {
      return (
        <Input type="select" name="severity" value={this.state.filterSeverity} onChange={this.handleSeverityChange}>
          <option vaue="">Select Severity</option>
          <option value="1">1: Minor/romantic (non-passionate heterosexual kissing, mild innuendo)</option>
          <option value="2">2: Suggestive (prolonged kissing, homosexual kissing, implied sex, strong innuendo, backside nudity, lingerie, cartoon nudity)</option>
          <option value="3">3: Explicit (passionate kissing, sex, frontal nudity)</option>
        </Input>
      );
    }

    // 'language' filter severities
    else if (this.state.filterType === 'language') {
      return (
        <Input type="select" name="severity" value={this.state.filterSeverity} onChange={this.handleSeverityChange}>
          <option vaue="">Select Severity</option>
          <option value="1">1: Mild ("shut up", "idiot", "stupid", "sucks", "gosh")</option>
          <option value="2">2: Moderate (allowed on cable TV, "h", "d", "b", "a", religious profanities, british swears)</option>
          <option value="3">3: Explicit ("f" word, "s" word, allowed in movies but not TV)</option>
        </Input>
      );
    }

    // 'violence' filter severities
    else if (this.state.filterType === 'violence') {
      return (
        <Input type="select" name="severity" value={this.state.filterSeverity} onChange={this.handleSeverityChange}>
          <option vaue="">Select Severity</option>
          <option value="1">1: Mild (slaps, punches, pushing, bullying)</option>
          <option value="2">2: Moderate (punches with blood, cartoon shooting, sci-fi weapons, gunshots without blood, implied/non-graphic stabbing)</option>
          <option value="3">3: Graphic (bloody stabbing, bloody shootings, bloody executions)</option>
        </Input>
      );
    }

    // 'disturbing' filter severities
    else if (this.state.filterType === 'disturbing') {
      return (
        <Input type="select" name="severity" value={this.state.filterSeverity} onChange={this.handleSeverityChange}>
          <option vaue="">Select Severity</option>
          <option value="1">1: Mild (vomit, minor blood, surgeries)</option>
          <option value="2">2: Moderate (bloody wounds, compound fractures, coughing up blood)</option>
          <option value="3">3: Graphic (major bloody wounds, severe child abuse, multiple dead bloody/decaying bodies)</option>
        </Input>
      );
    }

    // 'drugs' filter severities
    else if (this.state.filterType === 'drugs') {
      return (
        <Input type="select" name="severity" value={this.state.filterSeverity} onChange={this.handleSeverityChange}>
          <option vaue="">Select Severity</option>
          <option value="1">1: Mild (adult smoking/drinking)</option>
          <option value="2">2: Moderate (teenaged smoking/drinking, illegal drugs)</option>
          <option value="3">3: Severe (hard drug usage)</option>
        </Input>
      );
    }

    // 'credits' filter severities
    else if (this.state.filterType === 'credits') {
      return (
        <Input type="select" name="severity" value={this.state.filterSeverity} onChange={this.handleSeverityChange}>
          <option vaue="">Select Severity</option>
          <option value="1">1: Regular credits</option>
        </Input>
      );
    }

    // For anything else, we don't currently show any options
    else {
      return null;
    }
  }



  // Render everything
  render() {

    // If we have filters!
    if (this.state.tbody && this.state.tbody.length > 0) {

      const sliderStyle = {
        position: 'relative',
        height: '800px',
        marginLeft: '45%',
      };

      // Get our domain (0, latest filter) (TODO: Make it so we can tell how long a full video is and set that as our ending domain)
      const domain = [0, parseFloat(this.state.tbody[this.state.tbody.length - 1].data.end) + 4.0];

      // Get our values
      var values = [];
      for (var i = 0; i < this.state.tbody.length; i++) {
        console.log('adding to values start...', this.state.tbody[i].data.start);
        console.log('adding to values end...', this.state.tbody[i].data.end);
        values.push(this.state.tbody[i].data.start);
        values.push(this.state.tbody[i].data.end);
      }

      console.log('Domain:', domain);
      console.log('Values:', values);

      // Render our slider/list
      return (
        <div className="content">
          <Slider
            vertical
            mode={1}
            step={0.01}
            domain={domain}
            rootStyle={sliderStyle}
            onChange={this.onChangeSliderValues}
            values={values}
          >
            <Rail>
              {({ getRailProps }) => <SliderRail getRailProps={getRailProps} />}
            </Rail>
            <Handles>
              {({ handles, getHandleProps }) => (
                <div className="slider-handles">
                  {handles.map(handle => (
                    <Handle
                      key={handle.id}
                      handle={handle}
                      domain={domain}
                      getHandleProps={getHandleProps}
                    />
                  ))}
                </div>
              )}
            </Handles>
            <Tracks left={false} right={false}>
              {({ tracks, getTrackProps }) => (
                <div className="slider-tracks">
                  {tracks.map(({ id, source, target }) => (
                    <Track
                      key={id}
                      source={source}
                      target={target}
                      getTrackProps={getTrackProps}
                    />
                  ))}
                </div>
              )}
            </Tracks>
            <Ticks count={5}>
              {({ ticks }) => (
                <div className="slider-ticks">
                  {ticks.map(tick => (
                    <Tick key={tick.id} tick={tick} />
                  ))}
                </div>
              )}
            </Ticks>
          </Slider>
        </div>
      );
    }

    // No filters!
    else {
      return (
        <div className="content">
          No filters!
        </div>
      );
    }
  }
}

const condition = authUser => !!authUser;

export default compose(
  withFirebase,
  withEmailVerification,
  withAuthorization(condition),
)(RegularTables);
