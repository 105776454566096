import React from "react";
import { Row, Col } from "reactstrap";

class Privacy extends React.Component {
  render() {
    return (
      <div className="content">
        <Row>
          <Col>

            <h1>Frequently Asked Questions</h1>

            <p className="h6"><span className="text-info">I'VE HEARD OF SO MANY OTHER SERVICES LIKE THIS GETTING SHUT DOWN. IS FINALLYFILTERED LEGAL?</span></p>
            <p>
              Yes! FinallyFiltered is 100% legal. The problem with other services is that they either modified the media directly, or streamed the modified versions of the media from their servers, in violation of copyright laws. FinallyFiltered streams the full, unmodified video to your computer and then locally skips the parts you don't want to see.
            </p>

            <p className="h6"><span className="text-info">WHY DID YOU CREATE FINALLYFILTERED?</span></p>
            <p>
              We created FinallyFiltered because we are passionate about keeping media clean! Over the years, more and more swearing, sensual content and graphic violence have entered our homes. For our family, it got to the point where there were very few movies that we could watch without seeing or hearing something uncomfortable that neither we, nor our kids wanted to hear. After finally having enough, we set out on a mission to provide clean media for everyone, and to do it in a fully legal way. FinallyFiltered is the result. Please support our mission by giving FinallyFiltered a try, and telling your friends!
            </p>

            <p className="h6"><span className="text-info">DO I REQUIRE ANY SPECIAL SOFTWARE TO USE FINALLYFILTERED?</span></p>
            <p>
              No! Other than a tiny browser extension, there is no software to install.
            </p>

            <p className="h6"><span className="text-info">WHICH BROWSERS AND OPERATING SYSTEMS CAN I USE TO WATCH?</span></p>
            <p>
              Currently, you must use the Google Chrome browser on a desktop or laptop computer to watch filtered videos. This can be downloaded free on Windows and Mac. We plan to add additional browsers, including mobile devices, later on.
            </p>

            <p className="h6"><span className="text-info">WHICH VIDEO PLATFORMS WORK WITH FINALLYFILTERED</span></p>
            <p>
              Currently, you can watch filtered videos on Netflix, Amazon, Disney+, Hulu, Peacock and YouTube. We are adding more platforms soon.
            </p>

            <p className="h6"><span className="text-info">DOES FINALLYFILTERED ONLY FILTER MOVIES?</span></p>
            <p>
              No! While movies are our primary target, we are also very interested in cleaning up TV series and regular online videos. Our mission is to make everything safer to view online!
            </p>

            <p className="h6"><span className="text-info">HOW MANY FILTERS DO YOU HAVE?</span></p>
            <p>
              We have filters for hundreds of movies, TV episodes and other online videos with more being created every day.
            </p>

            <p className="h6"><span className="text-info">HOW MUCH DOES FINALLYFILTERED COST?</span></p>
            <p>
              For just $3/month you will get unlimited access to all of our filters. Our primary focus is not to make money, but to provide cleaner entertainment, so we plan to continue to keep our prices as low as possible.
            </p>

            <p className="h6"><span className="text-info">WHAT DOES FINALLYFILTERED FILTER OUT?</span></p>
            <p>
              We have 5 main categories of filters: sensuality, language, violence, disturbing and drugs. When creating our filters, we try to create a filter for anything that the most sensitive of viewer would find objectionable so that you never see or hear anything that you don't want. That said, you have full control to turn certain filters on or off at any time.
            </p>

            <p className="h6"><span className="text-info">CAN I SELECT WHICH FILTERS I WANT TO ENABLE?</span></p>
            <p>
              Yes! Before you watch a video, you will have the option of seeing a list of filters that we have created and selecting or de-selecting the parts you want or don't want to see. Then, you just hit "Play" and watch your own customized, cleaned up version of your movie or video. So, if you feel like we've filtered too much, you can easily set the software to show you more.
            </p>

            <p className="h6"><span className="text-info">WHO CREATES THE FILTERS?</span></p>
            <p>
              Viewers just like you! We have volunteer and paid filter technicians who are creating new filters every day so that you can enjoy clean videos. You may be asking -- what about those technicians; don't they have to see the bad parts to create the filters? In some cases, yes. However, we never have anyone filter anything that they wouldn't be comfortable watching in the first place.
            </p>
            <p>
              If you would like to become a filter technician, please contact us today!
            </p>

            <p className="h6"><span className="text-info">I WAS WATCHING A FILTERED VIDEO AND STILL SAW SOMETHING OBJECTIONABLE!</span></p>
            <p>
              We're terribly sorry for that! All of our filters go through a two-step verification process before they are published, however, sometimes things can still sneak through. If you saw or heard something you didn't want to see or hear, it could be because of one of the following reasons:
            </p>
            <p>
              1) You are using an unsupported browser. As mentioned above, our filters only work inside of Google Chrome on a desktop, laptop or notebook computer.
            </p>
            <p>
              2) You are not logged in or not subscribed to a paid plan. Please make sure you are logged in to the software and that your account and payment details are all up-to-date.
            </p>
            <p>
              3) The software glitched. It's rare, but it can happen, especially with the complicated way that we are handling the filtering. Also, sometimes a streaming provider will change something ever so slightly that throws off our timing. If you notice more than one glitch, please let us know right away.
            </p>
            <p>
              4) We had a filter for that part, but you unchecked it. If this happens, please go back and make sure that you have enabled ALL of the filters that we have created.
            </p>
            <p>
              5) We didn't feel that the part needed a filter. If you feel like something should have been skipped, please email us at support@finallyfiltered.com and we will review it.
            </p>
            <p>
              6) We simply missed it. For that, we are terribly sorry. If this is the case, again, please email us at support [at] finallyfiltered [dot] com and let us know what we missed so that we can review it.
            </p>

            <p className="h6"><span className="text-info">DO I NEED AN ACCOUNT ON NETFLIX, HULU, ETC?</span></p>
            <p>
              Yes. You need a separate, paid or free account on Netflix, Hulu, Disney+, Amazon, Peacock or YouTube to watch filtered videos. Again, we do not change their service at all, we just take their stream and clean it up as you watch.
            </p>

            <p className="h6"><span className="text-info">DO I HAVE TO LINK UP MY NETFLIX, AMAZON, ETC. ACCOUNT?</span></p>
            <p>
              Nope. As long as you are signed in to their services in the browser and we've got a filter for the video, you'll see the filtered version.
            </p>

            <p className="h6"><span className="text-info">CAN I WATCH PURCHASED OR RENTED STREAMING VIDEOS?</span></p>
            <p>
              Yes! Absolutely. You can either stream directly (via a subscription) or you can purchase or rent any video on any of our supported platforms and watch it filtered.
            </p>

          </Col>
        </Row>
      </div>
    );
  }
}

export default Privacy;
