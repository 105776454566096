import Home from 'views/Home/Home.jsx';
import Login from 'views/Login/Login.jsx';
import Signup from 'views/Signup/Signup.jsx';
import Forgot from 'views/Forgot/Forgot.jsx';
import FAQ from 'views/FAQ/FAQ.jsx';
import Privacy from 'views/Privacy/Privacy.jsx';
import Terms from 'views/Terms/Terms.jsx';

var openRoutes = [
  {
    path: "/login",
    name: "Log In",
    icon: "nc-icon nc-tv-2",
    component: Login
  },
  {
    path: "/signup",
    name: "Sign Up",
    icon: "nc-icon nc-tv-2",
    component: Signup
  },
  {
    path: "/forgot",
    name: "Password Reset",
    icon: "nc-icon nc-tv-2",
    component: Forgot
  },
  {
    path: "/faq",
    name: "Frequently Asked Questions",
    icon: "nc-icon nc-zoom-split",
    component: FAQ
  },
  {
    path: "/privacy",
    name: "Privacy Policy",
    icon: "nc-icon nc-tv-2",
    component: Privacy
  },
  {
    path: "/terms",
    name: "Terms of Use",
    icon: "nc-icon nc-tv-2",
    component: Terms
  },
  {
    path: "/",
    name: "FinallyFiltered",
    icon: "nc-icon nc-tv-2",
    component: Home
  },
];
export default openRoutes;
