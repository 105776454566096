import React from "react";
import { Row, Col, Button } from "reactstrap";
import logo from "assets/images/tall-logo.png";
import kids from "assets/images/kids.jpg";
import filters from "assets/images/filters.jpg";
import hero from "assets/images/hero-image.jpg";

import { AuthUserContext } from 'old-components/Session';

import amazon from "assets/images/amazon-full.png";
import disney from "assets/images/disney-full.png";
import hulu from "assets/images/hulu-full.png";
import netflix from "assets/images/netflix-full.png";
import peacock from "assets/images/peacock-full.png";
import vudu from "assets/images/vudu-full.png";
import moviesanywhere from "assets/images/moviesanywhere-full.png";
import youtube from "assets/images/youtube-full.png";

const WhichButtons = () => (
  <div className='justfy-content-center align-items-center text-center'>
    <AuthUserContext.Consumer>
    {authUser =>
      authUser ? (
        <div>
          <Button color="primary" size="lg" href="/watch" className="mt-4" style={{ padding: '25px 40px 25px 40px' }}>Watch Now!</Button>
        </div>
      ) : (
        <div>
          <Button color="primary" size="lg" className="mt-4" style={{ padding: '25px 40px 25px 40px' }} onClick={() => window.location.assign('/signup')}>Sign Up Now</Button>
          <p className='smaller'>(14 day FREE trial available for new customers; no credit card required!)</p>
        </div>
      )
    }
    </AuthUserContext.Consumer>
  </div>
);

class Home extends React.Component {

  render() {
    return (
      <div className="content">
        <Row style={{ margin: '-30px', marginTop: '-40px' }}>
          <img src={hero} style={{ boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)', width: '100%' }} />
        </Row>
        <br /><br />
        <WhichButtons />
        <Row>
          <Col className='h4 mt-2 d-flex justify-content-center' style={{ textAlign: 'center' }}>
            <p className='mt-4'>FinallyFiltered cleans up shows &nbsp;<span className="text-info">the way you want</span>&nbsp; on the following platforms:</p>
          </Col>
        </Row>
        <Row className='mt-1 mb-4 justify-content-center'>
          <Col xs={12} sm={6} md={4} lg={3} className='d-flex justify-content-center align-items-center text-center p-4'>
            <img src={netflix} alt="Netflix" />
          </Col>
          <Col xs={12} sm={6} md={4} lg={3} className='d-flex justify-content-center align-items-center text-center p-4'>
            <img src={disney} alt="Disney" />
          </Col>
          <Col xs={12} sm={6} md={4} lg={3} className='d-flex justify-content-center align-items-center text-center p-4'>
            <img src={hulu} alt="Hulu" />
          </Col>
          <Col xs={12} sm={6} md={4} lg={3} className='d-flex justify-content-center align-items-center text-center p-4'>
            <img src={amazon} alt="Amazon" />
          </Col>
          <Col xs={12} sm={6} md={4} lg={3} className='d-flex justify-content-center align-items-center text-center p-4'>
            <img src={peacock} alt="Peacock" />
          </Col>
          <Col xs={12} sm={6} md={4} lg={3} className='d-flex justify-content-center align-items-center text-center p-4'>
            <img src={youtube} alt="YouTube" />
          </Col>
          <Col xs={12} sm={6} md={4} lg={3} className='d-flex justify-content-center align-items-center text-center p-4'>
            <img src={vudu} alt="Vudu" />
          </Col>
          <Col xs={12} sm={6} md={4} lg={3} className='d-flex justify-content-center align-items-center text-center p-4'>
            <img src={moviesanywhere} alt="Movies Anywhere" />
          </Col>
        </Row>
        <Row>
          <Col className='justify-content-center align-items-center text-center'>
            <p className='h6'><span className="text-info h5">Finally!</span><br /><br />&bull; Movies, TV and online videos that are safe for families.</p>
            <p className='h6'>&bull; You control what you and your kids see and hear.</p>
            <p className='h6'>&bull; Watch clean versions of movies and shows in your home.</p>
            <p className='h6'>&bull; A completely legal filtering service that won't get shut down by lawsuits.</p>

            <br />
            <hr style={{ color: 'red', backgroundColor: '#faa500', width: '40%' }} />

            <p className='h4 mt-4'>
              <br />
              <img src={logo} alt="react-logo" width="200" className='logo-img-3' />
            </p>

            <p className='h5 mt-5'>
            FinallyFiltered is a new, innovative (and completely legal) way to watch movies, TV shows and
            videos on Netflix, Disney+, Hulu, Amazon, Peacock, YouTube, Vudu and Movies Anywhere from the comfort of your own home with all of the swearing, suggestive content, and violence filtered out.
            Our innovative technology allows you to pick and choose which filters you want to enable for each video, and then watch it without worry.
            </p>

            <p className='h6 mt-4'>
              To learn more, <a href='/faq' style={{ textDecoration: 'underline', color: '#ffa500' }}>visit our FAQ</a>
            </p>

            <br />
            <hr style={{ color: 'red', backgroundColor: '#faa500', width: '40%' }} />
            <br />

          </Col>
        </Row>
      </div>
    );
  }
}

export default Home;
