/*global chrome*/

import React from "react";
import { compose } from 'recompose';
import _ from 'lodash';
import {
  CardGroup, CardImg, Card, CardHeader, CardText, CardBody, CardTitle, CardSubtitle, CardFooter,
  Row, Col,
  Alert, Table, Input, Label
} from "reactstrap";
import BrowserDetection from 'react-browser-detection';
import { Link } from 'react-router-dom';

import CardAuthor from "components/CardElements/CardAuthor.jsx";
import FormInputs from "components/FormInputs/FormInputs.jsx";
import Button from "components/CustomButton/CustomButton.jsx";

import { withAuthorization, withEmailVerification } from 'old-components/Session';
import { withFirebase } from 'old-components/Firebase';

import hulu from "assets/images/hulu.png";
import peacock from "assets/images/peacock.png";
import vudu from "assets/images/vudu.png";
import moviesanywhere from "assets/images/moviesanywhere.png";
import amazon from "assets/images/amazon.png";
import youtube from "assets/images/youtube.png";
import netflix from "assets/images/netflix.png";
import disney from "assets/images/disney.png";

const justwatch = new(require('justwatch-api'));

const browserHandler = {
  chrome: () => '',
  default: () =>
    <Alert
      color='danger'
      className='alert-with-icon'
    >
      <span data-notify="icon" className="nc-icon nc-alert-circle-i"></span>
      <span data-notify="message">FinallyFiltered currently only supports the Chrome browser on a desktop computer. You can still use this browser to edit your profile and billing, but you will not be able to watch any videos.</span>
    </Alert>,
};

/*function getOffersList(offers) {

  var offersList = [];

  for (var i = 0; i < offers.length; i++) {

    var tmpOffer = offers[i];
    var offersItem = {
      lastprocessed: null,
      platform: null,
      type: null,
      added: null,
      changed: null,
      price: null,
      presentation: null,
      seasons: null,
    };

    var currentDate = new Date();
    var currentYear = currentDate.getFullYear().toString();
    var currentMonth = (currentDate.getMonth() + 1).toString();
    if (currentMonth.length === 1) { currentMonth = '0' + currentMonth; }
    var currentDay = currentDate.getDate().toString();
    if (currentDay.length === 1) { currentDay = '0' + currentDay; }
    var currentDateString = currentYear + '-' + currentMonth + '-' + currentDay;

    // netflix
      // 8 = netflix = nfx
      // 175 = netflixkids = nfk
    if (tmpOffer.provider_id === 8 || tmpOffer.provider_id === 175) {
      offersItem.lastprocessed = currentDateString;
      offersItem.platform = 'netflix';
      offersItem.type = tmpOffer.monetization_type;
      offersItem.added = tmpOffer.date_created;
      if (tmpOffer.retail_price) {
        offersItem.price = tmpOffer.retail_price;
      }
      if (tmpOffer.last_change_date) {
        offersItem.changed = tmpOffer.last_change_date;
      }
      if (tmpOffer.presentation_type) {
        offersItem.presentation = tmpOffer.presentation_type;
      }
      if (tmpOffer.new_element_count) {
        offersItem.seasons = tmpOffer.new_element_count;
      }
    }

    // amazon
      // 9 = amazonprime = amp
      // 10 = amazon = amz
    if (tmpOffer.provider_id === 9 || tmpOffer.provider_id === 10) {
      offersItem.lastprocessed = currentDateString;
      offersItem.platform = 'amazon';
      offersItem.type = tmpOffer.monetization_type;
      offersItem.added = tmpOffer.date_created;
      if (tmpOffer.retail_price) {
        offersItem.price = tmpOffer.retail_price;
      }
      if (tmpOffer.last_change_date) {
        offersItem.changed = tmpOffer.last_change_date;
      }
      if (tmpOffer.presentation_type) {
        offersItem.presentation = tmpOffer.presentation_type;
      }
      if (tmpOffer.new_element_count) {
        offersItem.seasons = tmpOffer.new_element_count;
      }
    }

    // hulu
      // 15 = hulu = hlu
    if (tmpOffer.provider_id === 15) {
      offersItem.lastprocessed = currentDateString;
      offersItem.platform = 'hulu';
      offersItem.type = tmpOffer.monetization_type;
      offersItem.added = tmpOffer.date_created;
      if (tmpOffer.retail_price) {
        offersItem.price = tmpOffer.retail_price;
      }
      if (tmpOffer.last_change_date) {
        offersItem.changed = tmpOffer.last_change_date;
      }
      if (tmpOffer.presentation_type) {
        offersItem.presentation = tmpOffer.presentation_type;
      }
      if (tmpOffer.new_element_count) {
        offersItem.seasons = tmpOffer.new_element_count;
      }
    }

    // disney
      // 337 = disneyplus = dnp
    if (tmpOffer.provider_id === 337) {
      offersItem.lastprocessed = currentDateString;
      offersItem.platform = 'disney';
      offersItem.type = tmpOffer.monetization_type;
      offersItem.added = tmpOffer.date_created;
      if (tmpOffer.retail_price) {
        offersItem.price = tmpOffer.retail_price;
      }
      if (tmpOffer.last_change_date) {
        offersItem.changed = tmpOffer.last_change_date;
      }
      if (tmpOffer.presentation_type) {
        offersItem.presentation = tmpOffer.presentation_type;
      }
      if (tmpOffer.new_element_count) {
        offersItem.seasons = tmpOffer.new_element_count;
      }
    }

    // youtube
      // 192 = youtube = yot
      // 188 = youtubered = ytr
      // 235 = youtubefree = yfr
    if (tmpOffer.provider_id === 192 || tmpOffer.provider_id === 188 || tmpOffer.provider_id === 235) {
      offersItem.lastprocessed = currentDateString;
      offersItem.platform = 'youtube';
      offersItem.type = tmpOffer.monetization_type;
      offersItem.added = tmpOffer.date_created;
      if (tmpOffer.retail_price) {
        offersItem.price = tmpOffer.retail_price;
      }
      if (tmpOffer.last_change_date) {
        offersItem.changed = tmpOffer.last_change_date;
      }
      if (tmpOffer.presentation_type) {
        offersItem.presentation = tmpOffer.presentation_type;
      }
      if (tmpOffer.new_element_count) {
        offersItem.seasons = tmpOffer.new_element_count;
      }
    }

    // Add it to our offer list
    if (offersItem.platform && offersItem.type && offersItem.added) {
      offersList.push(offersItem);
    }
  }

  return offersList;
}*/

class MovieSearch extends React.Component {

  createWatchSetLink = (id, series, extra) => {

    // If it is a series, we have to check which one is selected in the dropdown
    if (series) {

      var selectFieldString = 'episode-select-' + id;
      var selectField = document.getElementById(selectFieldString);

      if (selectField) {
        if (selectField.value) {
          return '/watchset/' + selectField.value;
        } else {
          return '#';
        }
      } else {
        return '#';
      }
    }

    // Otherwise, we just open it
    else {
      return '/watchset/' + id;
    }
  }

  constructor(props) {
    super(props);

    this.state = {
      hasExtension: false,
      allFilters: [],
      seriesSelected: null,
      episodeSelected: null,
      filterSubscription: false,
      filterSearch: '',
    };

    this.handleEpisodeSelect = this.handleEpisodeSelect.bind(this);
    this.toggleExtension = this.toggleExtension.bind(this);
    this.filterSubscription = this.filterSubscription.bind(this);
    this.filterSearch = this.filterSearch.bind(this);
  }

  // This checks the reply that came back from the extension and sets the state to true if it finds the extension
  toggleExtension(reply) {
    if (reply && reply.message && reply.message === 'yesisuream!') {
      this.setState({
        hasExtension: true,
      });
    } else {
      this.setState({
        hasExtension: false,
      });
    }
  }

  // Handle the filters
  filterSubscription(event) {
    this.setState({
      filterSubscription: event.target.checked,
    });
  }
  filterSearch(event) {
    this.setState({
      filterSearch: event.target.value,
    });
  }

  // Shows which sources have the video
  showOffers2 = (id, offers) => {

    let offerStrings = [];

    for (var i = 0; i < offers.length; i++) {

      if (offers[i].available && !offers[i].draft) {

        var image = null;
        if (offers[i].name === 'hulu') {
          image = hulu;
        } else if (offers[i].name === 'peacock') {
          image = peacock;
        } else if (offers[i].name === 'vudu') {
          image = vudu;
        } else if (offers[i].name === 'moviesanywhere') {
          image = moviesanywhere;
        } else if (offers[i].name === 'youtube') {
          image = youtube;
        } else if (offers[i].name === 'amazon') {
          image = amazon;
        } else if (offers[i].name === 'netflix') {
          image = netflix;
        } else if (offers[i].name === 'disney') {
          image = disney;
        }

        var subscription = '';
        if (offers[i].subscription) {
          if (offers[i].subscription === 'sub') {
            subscription = ' free/subscription';
          } else if (offers[i].subscription === 'pay') {
            subscription = ' rent/purchase';
          }
        }

        offerStrings.push(<div key={"key-div-" + offers[i].name + "-" + id}><img src={image} width="24" style={{marginBottom: '3px', marginRight: '2px'}} alt={offers[i].name} title={offers[i].name} /> {subscription}</div>);
      }
    }

    return offerStrings;
  }

  // Takes a set of offers and makes them into a string
  /*showOffers = (id, offers, filteredSources = [], onlyFiltered = false) => {

    let offerStrings = [];
    if (offers && offers.length) {
      for (var i = 0; i < offers.length; i++) {

        //console.log('offer: ', offers[i]);

        var image = null;
        if (offers[i].platform === 'hulu') {
          image = hulu;
        } else if (offers[i].platform === 'youtube') {
          image = youtube;
        } else if (offers[i].platform === 'amazon') {
          image = amazon;
        } else if (offers[i].platform === 'netflix') {
          image = netflix;
        } else if (offers[i].platform === 'disney') {
          image = disney;
        }

        var type = 'N/A';
        if (offers[i].type === 'buy') {
          type = 'Purchase for $' + offers[i].price;
        } else if (offers[i].type === 'rent') {
          type = 'Rent for $' + offers[i].price;
        } else if (offers[i].type === 'flatrate') {
          type = 'FREE w/ subscription';
        }

        // If we only want the filtered ones, only add this if it is filtered
        if ((onlyFiltered && filteredSources.indexOf(offers[i].platform) !== -1) || !onlyFiltered) {
          offerStrings.push(<div key={"key-div-" + offers[i].platform + "-" + id + "-" + offers[i].type + "-" + offers[i].presentation}><img src={image} width="16" alt={offers[i].platform} title={offers[i].platform} /> {type} ({offers[i].presentation})</div>);
        }
      }
    }
    return offerStrings;
  }*/

  componentDidMount() {

    // Start looking at Firebase filters (will also auto-update as filters change!)
    this.unsubscribe = this.props.firebase.filters().where('published', '==', true).orderBy('title', 'asc').onSnapshot(snapshot => {

      // Start with an empty list of filters
      var allFilters = [];

      // Get some dates to compare
      var now = new Date();
      now.setDate(now.getDate() - 7);
      var nowMonth = now.getMonth() + 1;
      if (nowMonth.toString().length === 1) {
        nowMonth = '0' + nowMonth;
      }
      var nowDay = now.getDate();
      if (nowDay.toString().length == 1) {
        nowDay = '0' + nowDay;
      }
      var nowDate = now.getFullYear() + '-' + nowMonth + '-' + nowDay;

      // Loop through each filter/video that we found
      snapshot.forEach(doc => {

        // Get the data
        var data = doc.data();
        data.id = doc.id;

        var filterRef = this.props.firebase.db.collection('filters').doc(data.id);








// THIS IS THE OLD STUFF WHERE WE HAD AUTOMATIC ACCESS TO ALL OF THE OFFERS //

        // If there are NO offers and this is YouTube, add the video
//        if (!data.offers && data.sources.length === 1 && data.sources.indexOf('youtube') !== -1) {
//          allFilters.push(data);
//        }

        // If there are no offers, or it has been more than 7 days since we refreshed them
//        else if (!data.offers || (data.offers[0] && nowDate > data.offers[0].lastprocessed)) {

          //console.log('NO OFFERS! "', data);

          // Try to get offers here
          /*justwatch.search({
            query: (data.series ? data.series : data.title),
          })

          // We are just going to grab the first result and hope it's a good one
          .then(function(result) {
            if (result.items && result.items[0] && result.items[0].id) {
              return justwatch.getTitle((!data.series ? 'movie' : 'show'), result.items[0].id);
            } else {
              return Promise.reject('No results from JustWatch search.');
            }
          })

          // Now we will make sure we have an imdbid match and then save to the database
          .then(function(result) {

            var tstImdbId = null;
            if (result.external_ids && result.external_ids.length > 0) {
              for (var i = 0; i < result.external_ids.length; i++) {
                if (result.external_ids[i].provider && result.external_ids[i].provider === 'imdb' && result.external_ids[i].external_id) {
                  tstImdbId = result.external_ids[i].external_id;
                  break;
                }
              }
            }

            if (tstImdbId !== null) {

              // Here is where we peel out the offers section and parse it for our own use
              var offers = result.offers;
              delete result.offers;

              //console.log('OFFERS2:', offers);

              var offersList = getOffersList(offers);

              console.log('GOT NEW OFFERS LIST:', offersList);

              // Now save to the database
              return filterRef.update({
                vidInfo2: result,
                offers: offersList,
              });
            }
          })

          // Add to our list
          .then(function() {
            allFilters.push(data);
          })

          // If there were errors
          .catch(function(error) {
            console.log('ERROR: ', error);
            data.validity = false;
            allFilters.push(data);
          });*/
//        }

        // If there are offers, do more processing
//        else {

          //console.log('OFFERS! "' + data.title + ' (sources=' + data.sources.join(', ') + ')": ', data.offers);

          // If we have filtered this video for one of its active sources, add it
//          var filterSourceMatches = _.find(data.offers, function(o) {
//            return data.sources.indexOf(o.platform) !== -1;
//          });
//          if (filterSourceMatches !== undefined) {
//            allFilters.push(data);
//          }

          // Otherwise, it's pretty much in an invalid state, so we'll list it at the bottom
//          else {
//            data.validity = false;
//            allFilters.push(data);
//          }
//        }


        // THIS IS THE NEW STUFF WHERE WE BASE IT ON MANUAL DATA //

        var activeOnes = _.find(data.sources, { available: true, draft: false });
        if (activeOnes) {
          allFilters.push(data);
        }

      });

      // Update the state
      this.setState({
        allFilters: allFilters,
      });

    });

    // Send a message to the extension to see if it exists

    // Try the live one, and if it's not there, try the local one
    try {
      chrome.runtime.sendMessage('peheiohonoimeohgjknaaeoaflocficn', { message: "areyououtthere?" },
        function(response) {
          if (response === undefined) {
            chrome.runtime.sendMessage('cgglcmlnminfemdoofahammhokmlbmjg', { message: "areyououtthere?" },
              function(response2) {
                this.toggleExtension(response2);
              }.bind(this)
            );
          } else {
            this.toggleExtension(response);
          }
        }.bind(this)
      );
    } catch (e) {
      this.toggleExtension(null);
    }
  }

  componentWillUnmount() {
    this.unsubscribe && this.unsubscribe();
  }

  // They selected an episode
  handleEpisodeSelect(event) {

    console.log('Selected from ' + event.target.getAttribute('data-hi') + ': ', event.target.value);

    // Set the state
    this.setState({
      seriesSelected: event.target.getAttribute('data-hi'),
      episodeSelected: event.target.value,
    });
  }

  render() {

    let hasExtension;
    if (!this.state.hasExtension) {
    hasExtension =
      <Alert
        color='danger'
        className='alert-with-icon'
      >
        <span data-notify="icon" className="nc-icon nc-alert-circle-i"></span>
        <span data-notify="message">It does not appear that you have the FinallyFiltered Chrome extension installed. You will need to have that installed in order to watch any filtered videos. <a href="https://chrome.google.com/webstore/detail/peheiohonoimeohgjknaaeoaflocficn" target="_blank">Click here</a> to install it.</span>
      </Alert>;
    }

    // Build an array of representing all series with their episodes so that we can create links to individual episodes
    var allSeries = [];
    this.state.allFilters.forEach(doc => {

      // If this item relates to a series
      //if (doc.vidInfo && doc.vidInfo.type && doc.vidInfo.type === 'series' && doc.validity !== false) {
      if (doc.vidInfo && doc.vidInfo.type && doc.vidInfo.type === 'series') {

        // See if we've had this one before
        var seriesObject = _.find(allSeries, function(o) {
          return o.id === doc.vidInfo.imdbid;
        });

        // Get an episode
        var episode = {
          id: doc.id,
          season: parseInt(doc.season),
          episode: parseInt(doc.episode),
          name: doc.title,
        };

        // Get the sources
        var sources = doc.sources;

        // If we haven't added this series yet, add it now
        if (!seriesObject) {
          allSeries.push({
            id: doc.vidInfo.imdbid,
            vidInfo: doc.vidInfo,
            title: doc.series,
            episodes: [ episode ],
            sources: sources,
          });
        }

        // Otherwise, we update it with the latest vidInfo
        else {
          seriesObject.vidInfo = doc.vidInfo;
          seriesObject.episodes.push(episode);
          seriesObject.sources = _.uniq(seriesObject.sources.concat(sources));
        }
      }
    });

    //console.log('Series: ', allSeries);
    //console.log('Filters: ', this.state.allFilters);

    var re = null;
    if (this.state.filterSearch !== '') {
      var filterSearchReplace = this.state.filterSearch.replace(/[^A-Za-z -]/gi, '');
      re = new RegExp(filterSearchReplace, 'gi');
    }

    return (
      <div className="content">

        <BrowserDetection>
          { browserHandler }
        </BrowserDetection>
        {hasExtension}

        <h6>Search Options: </h6>
        <Input type="checkbox" name="filterSubscription" id="filterSubscription" style={{ marginLeft: '10px' }} onChange={this.filterSubscription} checked={this.state.filterSubscription} />
        <Label style={{ marginLeft: '30px' }}>Show ALL videos (including those that need to be rented or purchased)</Label>
        <Input type="text" name="filterSearch" id="filterSearch" style={{ marginLeft: '10px' }} value={this.state.filterSearch} onChange={this.filterSearch} placeholder="Search by movie/show name" />

        <h4>Movies</h4>
        <CardGroup>
          {this.state.allFilters.map((prop, key) => {

            // If this is one that has movie info
            //if (prop.vidInfo && prop.vidInfo.type === 'movie' && prop.validity !== false) {
            if (
              prop.vidInfo && prop.vidInfo.type === 'movie' &&
              (this.state.filterSubscription || _.find(prop.sources, { subscription: 'sub' })) &&
              (this.state.filterSearch === '' || prop.title.match(re))
            ) {
              return (
                <Col lg={3} md={4} sm={6} xs={12} key={"key-col-" + prop.id}>
                  <Card key={"key-card-" + prop.id}>
                    <CardImg top width="100%" src={prop.vidInfo.poster} alt={"Movie Poster for " + prop.vidInfo.title} />
                    <CardBody>
                      <CardTitle>{prop.vidInfo.title + ' (' + prop.vidInfo.year + ')'}</CardTitle>
                      <CardSubtitle>{prop.vidInfo.production + ' (' +  prop.vidInfo.runtime + ' - ' + prop.vidInfo.rated + ')'}</CardSubtitle>
                      <CardText>
                        {prop.vidInfo.plot}
                        <br />
                        <br />
                        {this.showOffers2(prop.id, prop.sources)}
                      </CardText>
                      <Link to={this.createWatchSetLink(prop.id, false)}>Open</Link>
                    </CardBody>
                  </Card>
                </Col>
              );
            }

            // If this is one that doesn't have movie info
            else {
              return (
                <span key={"key-empty-" + prop.id} />
              );
            }
          })}
        </CardGroup>

        <h4>Series</h4>
        <CardGroup>
          {allSeries.map((prop, key) => {

            if (
              (this.state.filterSubscription || _.find(prop.sources, { subscription: 'sub' })) &&
              (this.state.filterSearch === '' || prop.title.match(re))
            ) {

              // Sort the episodes in the series
              var seriesEpisodes = _.sortBy(prop.episodes, ['season', 'episode']);

              var allItemsAdded = [];

              return (
                <Col lg={3} md={4} sm={6} xs={12} key={"key-col-" + prop.id}>
                  <Card key={"key-card-" + prop.id}>
                    <CardImg top width="100%" src={prop.vidInfo.poster} alt={"Movie Poster for " + prop.vidInfo.title} />
                    <CardBody>
                      <CardTitle>{prop.vidInfo.title + ' (' + prop.vidInfo.year + ')'}</CardTitle>
                      <CardSubtitle>{(prop.vidInfo.production ? prop.vidInfo.production : '') + ' (' +  prop.vidInfo.runtime + ' - ' + prop.vidInfo.rated + ')'}</CardSubtitle>
                      <CardText>
                        {prop.vidInfo.plot}
                        <br /><br />
                        {prop.sources.map((prop2, key2) => {
                          var image = null;
                          if (prop2.name === 'hulu') {
                            image = hulu;
                          } else if (prop2.name === 'peacock') {
                            image = peacock;
                          } else if (prop2.name === 'vudu') {
                            image = vudu;
                          } else if (prop2.name === 'moviesanywhere') {
                            image = moviesanywhere;
                          } else if (prop2.name === 'youtube') {
                            image = youtube;
                          } else if (prop2.name === 'amazon') {
                            image = amazon;
                          } else if (prop2.name === 'netflix') {
                            image = netflix;
                          } else if (prop2.name === 'disney') {
                            image = disney;
                          }
                          if (image !== null) {

                            var subscription = '';
                            if (prop2.subscription) {
                              if (prop2.subscription === 'sub') {
                                subscription = ' free/subscription';
                              } else if (prop2.subscription === 'pay') {
                                subscription = ' rent/purchase';
                              }
                            }

                            var itemToAdd = (
                              <div>
                                <img src={image} width="24" style={{marginBottom: '3px', marginRight: '2px'}} alt={prop2.name} title={"Available filtered on " + prop2.name} key={"key-img-" + prop.id} /> {subscription}
                              </div>
                            );
                            var itemToAddShort = prop2.name + prop2.subscription;

                            if (allItemsAdded.indexOf(itemToAddShort) === -1) {
                              allItemsAdded.push(itemToAddShort);
                              return itemToAdd;
                            } else {
                              return null;
                            }
                          } else {
                            return (
                              <span />
                            );
                          }
                        })}
                      </CardText>
                      <Input type="select" name="episode-select" data-hi={prop.id} id={'episode-select-' + prop.id} onChange={this.handleEpisodeSelect}>
                          <option value="">Select an Episode</option>
                          {seriesEpisodes.map((prop2, key2) => {
                            return (
                              <option value={prop2.id} key={'episode-select-' + key2}>{'S' + prop2.season + ' Episode ' + prop2.episode + ' (' + prop2.name + ')'}</option>
                            );
                          })}
                      </Input>
                      {
                        this.state.episodeSelected ? <Link to={this.createWatchSetLink(prop.id, true, prop)}>Open</Link>
                        : <br />
                      }
                      {
                        !this.state.episodeSelected ?
                        <div color='info'>* Select an episode to view.</div>
                        :
                        <br />
                      }
                    </CardBody>
                  </Card>
                </Col>
              );
            }
          })}
        </CardGroup>

        <h4>Other Videos</h4>
        <Card>
          <CardBody>
            <Table responsive>
              <thead>
                <tr>
                  <th>Title</th>
                  <th>Sources</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {this.state.allFilters.map((prop, key) => {

                  // If this is not a movie
                  //if ((!prop.vidInfo || _.isEmpty(prop.vidInfo)) && prop.validity !== false) {
                  if (
                    (!prop.vidInfo || _.isEmpty(prop.vidInfo))
                    &&
                    (this.state.filterSubscription || _.find(prop.sources, { subscription: 'sub' }))
                    &&
                    (this.state.filterSearch === '' || prop.title.match(re))
                  ) {

                    //console.log('OTHER VIDEOS: ' + prop.title, prop);

                    return (
                      <tr key={key}>
                        <td>
                          {prop.title}
                        </td>
                        <td>
                          {prop.sources.map((prop2, key2) => {
                            var image = null;
                            if (prop2.name === 'hulu') {
                              image = hulu;
                            } else if (prop2.name === 'peacock') {
                              image = peacock;
                            } else if (prop2.name === 'vudu') {
                              image = vudu;
                            } else if (prop2.name === 'moviesanywhere') {
                              image = moviesanywhere;
                            } else if (prop2.name === 'youtube') {
                              image = youtube;
                            } else if (prop2.name === 'amazon') {
                              image = amazon;
                            } else if (prop2.name === 'netflix') {
                              image = netflix;
                            } else if (prop2.name === 'disney') {
                              image = disney;
                            }
                            if (image !== null) {
                              return (
                                <img src={image} width="24" style={{marginBottom: '3px', marginRight: '2px'}} alt={prop2.name} title={"Available filtered on " + prop2.name} key={"key-img-" + prop2.name + "-" + prop.id} />
                              );
                            } else {
                              return (
                                <span />
                              );
                            }
                          })}
                        </td>
                        <td>
                          <Link to={this.createWatchSetLink(prop.id, false)}>Open</Link>
                        </td>
                      </tr>
                    );
                  }

                  // If this is a movie, it's already listed above
                  else {
                    return (
                      <tr key={"key-empty-" + prop.id}></tr>
                    );
                  }
                })}
              </tbody>
            </Table>
          </CardBody>
        </Card>
      </div>
    );
  }
}

const condition = authUser => !!authUser;

export default compose(
  withFirebase,
  withEmailVerification,
  withAuthorization(condition),
)(MovieSearch);
