import React from "react";
import PerfectScrollbar from "perfect-scrollbar";
import { Route, Switch, Redirect } from "react-router-dom";
import { compose } from 'recompose';
import { withAuthorization, withEmailVerification } from 'old-components/Session';
import { withFirebase } from 'old-components/Firebase';
import { AuthUserContext } from 'old-components/Session';
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";

import controlRoutes from "constants/controlRoutes.jsx";

var ps;

class ControlLayout extends React.Component {

  constructor(props){
    super(props);

    this.state = {
      backgroundColor: "black",
      activeColor: "info",
    }
  }

  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.refs.mainPanel);
      document.body.classList.toggle("perfect-scrollbar-on");
    }
  }

  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
      document.body.classList.toggle("perfect-scrollbar-on");
    }
  }

  componentDidUpdate(e) {
    if (e.history.action === "PUSH") {
      this.refs.mainPanel.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
    }
  }

  handleActiveClick = (color) => {
    this.setState({ activeColor: color });
  }

  handleBgClick = (color) => {
    this.setState({ backgroundColor: color });
  }

  render() {
    return (
      <div className="wrapper">

        <AuthUserContext.Consumer>
          {authUser => (
            <Sidebar
              {...this.props}
              routes={controlRoutes}
              bgColor={this.state.backgroundColor}
              activeColor={this.state.activeColor}
              authUser={authUser}
            />
          )}
        </AuthUserContext.Consumer>

        <div className="main-panel" ref="mainPanel">
          <AuthUserContext.Consumer>
            {authUser => (
              <Header
                {...this.props}
                authUser={authUser}
              />
            )}
          </AuthUserContext.Consumer>
          <AuthUserContext.Consumer>
            {authUser => (
              <Switch>
                {controlRoutes.map((prop, key) => {

                  if (prop.pro) {
                    return null;
                  }

                  if (prop.redirect) {
                    return <Redirect from={prop.path} to={prop.pathTo} key={key} />;
                  }

                  // Make sure this user has permission to access this control page
                  if (prop.permission && prop.permission.indexOf(authUser.permission) === -1) return null;

                  return (
                    <Route
                      path={prop.path}
                      component={prop.component}
                      key={key}
                    />
                  );
                })}
              </Switch>
            )}
          </AuthUserContext.Consumer>
          <AuthUserContext.Consumer>
            {authUser => (
              <Footer
                fluid
                authUser={authUser}
              />
            )}
          </AuthUserContext.Consumer>
        </div>
      </div>
    );
  }
}

const condition = authUser => !!authUser;

export default compose(
  withFirebase,
  withEmailVerification,
  withAuthorization(condition),
)(ControlLayout);
