import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import ControlLayout from 'layouts/ControlLayout/ControlLayout.jsx';
import HomeLayout from 'layouts/HomeLayout/HomeLayout.jsx';
import { withAuthentication } from '../Session';

// This is the main application. It establishes all of the routes inside of a router so that certain URLs take visitors to certain pages.

const App = () => (
  <Router>
    <div>
      <Route exact path="/" component={HomeLayout} />
      <Route path="/signup" component={HomeLayout} />
      <Route path="/login" component={HomeLayout} />
      <Route path="/forgot" component={HomeLayout} />
      <Route path="/faq" component={HomeLayout} />
      <Route path="/privacy" component={HomeLayout} />
      <Route path="/terms" component={HomeLayout} />
      <Route path='/watch' component={ControlLayout} />
      <Route path='/profile' component={ControlLayout} />
      <Route path='/filter' component={ControlLayout} />
      <Route path='/traverse' component={ControlLayout} />
      <Route path='/users' component={ControlLayout} />
      <Route path='/filterset/:id' component={ControlLayout} />
      <Route path='/filternew/:id' component={ControlLayout} />
      <Route path='/filterset2/:id' component={ControlLayout} />
      <Route path='/watchset/:id' component={ControlLayout} />
    </div>
  </Router>
);

export default withAuthentication(App);
