import React from 'react';
import { Row, Col, Button } from "reactstrap";
import AuthUserContext from './context';
import { withFirebase } from '../Firebase';

const needsEmailVerification = authUser =>
  authUser &&
  !authUser.emailVerified &&
  authUser.providerData
    .map(provider => provider.providerId)
    .includes('password');

const withEmailVerification = Component => {
  class WithEmailVerification extends React.Component {
    constructor(props) {
      super(props);

      this.state = { isSent: false };
    }

    onSendEmailVerification = () => {
      this.props.firebase
        .doSendEmailVerification()
        .then(() => this.setState({ isSent: true }));
    };

    onSendRefreshPage = () => {
      window.location.href = window.location.href;
    };

    render() {
      return (
        <AuthUserContext.Consumer>
          {authUser =>
            needsEmailVerification(authUser) ? (
              <Row>
                <Col xs="12" sm={{ size: 10, offset: 1 }} md={{ size: 6, offset: 3}} lg={{ size: 6, offset: 3 }}>
                  <div className='justfy-content-center align-items-center text-center'>
                    <br /><br /><br /><br /><br /><br />
                    <br /><br /><br /><br /><br /><br />
                    {this.state.isSent ? (
                      <p>
                        Email confirmation sent: Check your email (including your spam folder) for a confirmation email. Click the link in that email and then come back here and refresh this page.
                      </p>
                    ) : (
                      <div>
                        <p>
                          We have sent you an email to verify your email address.
                        </p>
                        <p>
                          Check your email (including your spam folder) for a confirmation email and click the link to activate your account.
                        </p>
                        <p>
                          After confirming your account, come back here and reload the window to access FinallyFiltered!
                        </p>
                        <p>
                          If after a few minutes you still haven't received an email, you can send another one by hitting the button below.
                        </p>
                      </div>
                    )}

                    <Button
                      color="primary"
                      type="button"
                      onClick={this.onSendRefreshPage}
                    >
                      Reload the Window Now
                    </Button>

                    <Button
                      color="secondary"
                      type="button"
                      onClick={this.onSendEmailVerification}
                      disabled={this.state.isSent}
                    >
                      Send another confirmation email
                    </Button>

                  </div>
                </Col>
              </Row>
            ) : (
              <Component {...this.props} />
            )
          }
        </AuthUserContext.Consumer>
      );
    }
  }

  return withFirebase(WithEmailVerification);
};

export default withEmailVerification;
