import React, { Component } from 'react';

import {
  Input, Row, Col, Button, Alert
} from "reactstrap";

import { withFirebase } from '../Firebase';

class UserDetailsForm extends Component {

  constructor(props) {
    super(props);

    this.state = {
      name: '',
      email: '',
      permission: '',
    };

    this.onDismiss = this.onDismiss.bind(this);
  }

  // On mount, grab all of the filters
  componentDidMount() {

    // Wait for a login event to set things
    this.listener = this.props.firebase.onAuthUserListener(

      // If we have a logged in user
      currentUser => {

        // Set the state
        this.setState({
          name: currentUser.name,
          email: currentUser.email,
          permission: currentUser.permission,
        });
      }
    );
  }

  componentWillUnmount() {
    this.listener();
  }

  // When they dismiss the error message
  onDismiss() {
    this.setState({ error: null });
  }

  // When they actually hit the submit button to update their name
  onSubmit = event => {

    // Save the name
    const name = this.state.name;
    const nameSplit = name.split(/\s+/);
    //const nameShort = nameSplit[0] + ' ' + nameSplit[1].charAt(0) + '.';
    const nameShort =  name;

    // Send the new name to the server
    var userRef = this.props.firebase.db.collection('users').doc(this.props.firebase.auth.currentUser.uid);
    userRef.update({
      name: name,
    }).then(() => {

      // The name was updated; now we need to update all videos that are using this person's name
      this.props.firebase.db.collection('filters').where('createdBy', '==', userRef).get().then(function(snapshot) {
        snapshot.forEach(function(doc) {
          this.props.firebase.db.collection('filters').doc(doc.id).update({
            createdByString: nameShort,
          });
        }.bind(this));
      }.bind(this));
      this.props.firebase.db.collection('filters').where('reservedBy', '==', userRef).get().then(function(snapshot) {
        snapshot.forEach(function(doc) {
          this.props.firebase.db.collection('filters').doc(doc.id).update({
            reservedByString: nameShort,
          });
        }.bind(this));
      }.bind(this));

    }).catch(error => {
      this.setState({ error });
    });

    // Prevent default
    event.preventDefault();
  };

  // When they change their name
  onChange = event => {
    this.setState({
      name: event.target.value
    });
  };

  render() {

    const name = this.state.name;
    const error = this.state.error;
    const isInvalid = name === '';

    return (
      <div>
        <h5>User Details</h5>
        <p>
          <strong>Email:</strong> {this.state.email}
          {', '}
          <strong>Permission:</strong> {this.state.permission}
        </p>
        <form onSubmit={this.onSubmit}>
          <Row>
            <Col xs="12" sm="12" md="4" lg="4">
              <Input type="text" name="name" placeholder="Your full name" value={name} onChange={this.onChange} className="mb-3" />
            </Col>
            <Col xs="12" sm="12" md="4" lg="4">
              <Button color="primary" disabled={isInvalid} type="submit">Change Display Name</Button>
            </Col>
          </Row>

          {error && <Alert color="danger" toggle={this.onDismiss}>{error.message}</Alert>}
        </form>
      </div>
    );
  }
}

export default withFirebase(UserDetailsForm);
