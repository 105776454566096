import React, { Component } from 'react';
import { Row, Col, Button, Input } from "reactstrap";
import { Link, withRouter, Redirect } from 'react-router-dom';
import { compose } from 'recompose';
import { AuthUserContext } from 'old-components/Session';
import { withFirebase } from 'old-components/Firebase';
import { LoginLink } from 'views/Login/Login.jsx';

function SignUpPage(props) {
  return <div className='justfy-content-center align-items-center text-center'>
    <br /><br /><br /><br /><br /><br />
    <br /><br /><br /><br /><br /><br />
    <AuthUserContext.Consumer>
    {authUser =>
      authUser ? (
        <Redirect from='/signup' to='/watch' key='hey' />
      ) : (
        <div>
          <p className='h6'>Create your FinallyFiltered account!</p>
          <p>No credit card required to sign up! 14 day free trial available to new users!</p>
          <hr style={{ color: 'red', backgroundColor: 'rgb(83,186,214)', width: '25%' }} />
          <br />
        </div>
      )
    }
    </AuthUserContext.Consumer>
    <SignUpForm />
    <LoginLink />
    <br /><br /><br /><br /><br /><br />
    <br /><br /><br /><br /><br /><br />
  </div>
}

const INITIAL_STATE = {
  name: '',
  email: '',
  passwordOne: '',
  passwordTwo: '',
  isAdmin: false,
  error: null,
};

const ERROR_CODE_ACCOUNT_EXISTS = 'auth/email-already-in-use';

const ERROR_MSG_ACCOUNT_EXISTS = `
  An account with this E-Mail address already exists.
  Try to login with this account instead. If you think the
  account is already used from one of the social logins, try
  to sign in with one of them. Afterward, associate your accounts
  on your personal account page.
`;

class SignUpFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {

    const { name, email, passwordOne, isAdmin } = this.state;

    this.props.firebase
      .doCreateUserWithEmailAndPassword(email, passwordOne)
      .then(authUser => {
        // Create a user in your Firebase realtime database
        return this.props.firebase.user(authUser.user.uid).set(
          {
            name: name,
            email: email,
            permission: 'viewer',
          },
          { merge: true },
        );
      })
      .then(() => {
        return this.props.firebase.doSendEmailVerification();
      })
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        this.props.history.push('/watch');
      })
      .catch(error => {
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS;
        }

        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onChangeCheckbox = event => {
    this.setState({ [event.target.name]: event.target.checked });
  };

  render() {
    const {
      name,
      email,
      passwordOne,
      passwordTwo,
      isAdmin,
      error,
    } = this.state;

    const isInvalid =
      passwordOne !== passwordTwo ||
      passwordOne === '' ||
      email === '' ||
      name === '';

    if (this.props.authUser) {
      return <Redirect from='/signup' to='/watch' key='hey' />;
    } else {
      return (
        <Row>
          <Col xs="12" sm={{ size: 6, offset: 3 }} md={{ size: 4, offset: 4}} lg={{ size: 4, offset: 4 }}>
            <form onSubmit={this.onSubmit}>
              <Input
                className="mb-2"
                name="name"
                value={name}
                onChange={this.onChange}
                type="text"
                placeholder="Full Name"
              />
              <Input
                className="mb-2"
                name="email"
                value={email}
                onChange={this.onChange}
                type="text"
                placeholder="Email Address"
              />
              <Input
                className="mb-2"
                name="passwordOne"
                value={passwordOne}
                onChange={this.onChange}
                type="password"
                placeholder="Password"
              />
              <Input
                className="mb-2"
                name="passwordTwo"
                value={passwordTwo}
                onChange={this.onChange}
                type="password"
                placeholder="Confirm Password"
              />
              <Button color="primary" disabled={isInvalid} type="submit">
                Sign Up
              </Button>
              {error && <p>{error.message}</p>}
            </form>
          </Col>
        </Row>
      );
    }
  }
}

const SignUpLink = () => (
  <p>
    Don't have an account? <Link to={'/signup'}>Sign Up</Link>
  </p>
);

const SignUpForm = compose(
  withRouter,
  withFirebase,
)(SignUpFormBase);

export default compose(
  withFirebase,
)(SignUpPage);

export { SignUpForm, SignUpLink };
