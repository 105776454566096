/*global chrome*/

import React from "react";
import { compose } from 'recompose';
import _ from 'lodash';
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Table,
  Row,
  Col,
  Modal, ModalHeader, ModalBody, ModalFooter,
  Input, Alert, Label, FormGroup
} from "reactstrap";
import "bootstrap/dist/css/bootstrap.css";
import "assets/scss/paper-dashboard.scss";
import "assets/demo/demo.css";

import jsonDefinitions from "../../definitions.json";

import Button from "components/CustomButton/CustomButton.jsx";

import { withAuthorization, withEmailVerification } from 'old-components/Session';
import { withFirebase } from 'old-components/Firebase';

import hulu from "assets/images/hulu.png";
import peacock from "assets/images/peacock.png";
import vudu from "assets/images/vudu.png";
import moviesanywhere from "assets/images/moviesanywhere.png";
import amazon from "assets/images/amazon.png";
import youtube from "assets/images/youtube.png";
import netflix from "assets/images/netflix.png";
import disney from "assets/images/disney.png";

const links = {
  youtube: 'https://www.youtube.com/watch?v=',
  netflix: 'https://www.netflix.com/watch/',
  amazon: 'https://www.amazon.com/gp/video/detail/',
  hulu: 'https://www.hulu.com/watch/',
  peacock: 'https://www.peacocktv.com/watch/playback/vod/',
  vudu: 'https://www.vudu.com/content/movies/play/',
  moviesanywhere: 'https://moviesanywhere.com/movie/',
  disney: 'https://www.disneyplus.com/video/',
}

const thead = ["Type", "Sev.", "Description", "ID", "Start", "End", "Action", "Actions", "ID"];
const tbody = [
  {
    className: "",
    data: ["type", "severity", "description", "id", "start", "end", "action", "actions", "id"]
  },
];

const hidden = {
  display: 'none',
}

class RegularTables extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      documentTitle: '',
      tbody: [],
      filterSources: [],
      allReports: [],
      disclaimer: '',
      report: '',
      filterId: '',
      filterType: '',
      filterSeverity: null,
      filterDescription: '',
      filterStart: '',
      filterEnd: '',
      filterAction: '',
      filterDisabled: '',
      videoId: '',
      modalType: '',
      adjustAllFiltersTime: '',
      adjustAllFiltersTime2: '',
      bumpAmount: 0.0,
      extension: null,
      tabId: null,
      tabUrl: null,
      tabUrlFull: null,
      tabUrls: null,
      toggleFilterDefinitionsBox: false,
      currentUser: {
        permission: null,
      },
      published: 'a',
      hasAccess: false,
    };

    this.closeModal = this.closeModal.bind(this);
    this.preToggleModal = this.preToggleModal.bind(this);
    this.preToggleReportModal = this.preToggleReportModal.bind(this);
    this.deleteFilter = this.deleteFilter.bind(this);
    this.deleteReport = this.deleteReport.bind(this);
    this.editFilter = this.editFilter.bind(this);
    this.adjustAllFilters = this.adjustAllFilters.bind(this);
    this.handleAdjustAllFiltersChange = this.handleAdjustAllFiltersChange.bind(this);
    this.adjustAllFilters2 = this.adjustAllFilters2.bind(this);
    this.handleAdjustAllFiltersChange2 = this.handleAdjustAllFiltersChange2.bind(this);
    this.preBumpFilter = this.preBumpFilter.bind(this);
    this.preBumpFilterBoth = this.preBumpFilterBoth.bind(this);
    this.enableDisableFilter = this.enableDisableFilter.bind(this);
    this.preModifyDisclaimer = this.preModifyDisclaimer.bind(this);
    this.modifyDisclaimer = this.modifyDisclaimer.bind(this);

    this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
    this.handleTypeChange = this.handleTypeChange.bind(this);
    this.handleSeverityChange = this.handleSeverityChange.bind(this);
    this.preSetDescription = this.preSetDescription.bind(this);
    this.handleStartChange = this.handleStartChange.bind(this);
    this.handleEndChange = this.handleEndChange.bind(this);
    this.handleActionChange = this.handleActionChange.bind(this);

    this.toggleFilterDefinitionsBox = this.toggleFilterDefinitionsBox.bind(this);
  }

  // Toggling the filter definitions box
  toggleFilterDefinitionsBox(event) {
    this.setState({
      toggleFilterDefinitionsBox: event.target.checked,
    });
  }

  // Description changed
  handleDescriptionChange(event) {
    this.setState({
      filterDescription: event.target.value,
    });
  }
  // Type changed
  handleTypeChange(event) {
    this.setState({
      filterType: event.target.value,
    });
  }
  // Severity changed
  handleSeverityChange(event) {
    this.setState({
      filterSeverity: parseInt(event.target.value),
    });
  }
  // Pre-set the description
  preSetDescription(event) {
    this.setState({
      filterDescription: event.target.value,
    });
  }
  // Start changed
  handleStartChange(event) {
    this.setState({
      filterStart: this.timeToFloat(event.target.value),
    });
  }
  // End changed
  handleEndChange(event) {
    this.setState({
      filterEnd: this.timeToFloat(event.target.value),
    });
  }
  // Action changed
  handleActionChange(event) {
    this.setState({
      filterAction: event.target.value,
    });
  }

  // Modifies the disclaimer
  preModifyDisclaimer(event) {
    this.setState({
      disclaimer: event.target.value,
    });
  }
  modifyDisclaimer(event) {

    // Make sure we have access
    if (this.state.hasAccess) {

      // Get our reference point
      var videoId = this.props.match.params.id;
      var filterRef = this.props.firebase.db.collection('filters').doc(videoId);

      console.log('Setting to "' + this.state.disclaimer + '"');

      // Update the server
      filterRef.update({
        disclaimer: this.state.disclaimer,
      });
    } else {
      console.log('ERROR! Invalid access.');
    }
  }

  // Temporarily enables or disables a filter
  enableDisableFilter(id) {

    // Make sure we have access
    if (this.state.hasAccess) {

      // Get our reference point
      var videoId = this.props.match.params.id;
      var filterRef = this.props.firebase.db.collection('filters').doc(videoId);

      // Get our tbody (because we are going to modify it)
      var tbodyState = this.state.tbody;

      // Get the one
      var item = _.findIndex(tbodyState, function(obj) {
        return obj.data.id === id;
      });

      var newItem = tbodyState[item].data;

      // Change that one filter to be disabled/enabled
      if (newItem.disabled) {
        delete newItem.disabled;
      } else {
        newItem.disabled = true;
      }

      // Save this back into the main array
      tbodyState[item].data = newItem;

      // Update state
      this.setState({
        tbodyState,
      });

      // Loop to restructure the data
      var savedArray = [];
      for (var i = 0; i < tbodyState.length; i++) {
        savedArray.push(tbodyState[i].data);
      }

      // Update the server
      filterRef.update({
        filters: savedArray,
      })
      .then(function() {

        // Send this one updated filter to the extension
        if (this.state.extension) {
          try {

            console.log(`Telling the background script that a filter was enabled/disabled`);

            chrome.runtime.sendMessage(this.state.extension, { message: "getupdatedfilter", filteredit: tbodyState[item].data },
              function(response) {

                console.log(`##### DB DEBUG ##### We got a response`, response);

                // If the background has an active connection to the playing video and everything was updated
                if (response && response.message && response.message === "gotupdatedfilters") {
                  console.log('Filter was updated in the client!');
                }

                // If there is no connection between the background and the playing video
                else if (response && response.message && response.message === "noconnectiontofrontend") {
                  alert('WARNING! We do not seem to have a connection to the playing video, so we cannot update the filters real-time. Please refresh your video to restore the connection to the backend.');
                }

                // If there was some other unknown problem
                else {
                  alert('ERROR! There was some other problem sending updates to the playing video. You probably should reload your video so that real-time updates resume.');
                }
              }
            );
          } catch(e) {
            console.log('E2: ', e);
          }
        } else {
          console.log(`ERROR! We don't have an active connection to an extension to let the background know that a filter was ENABLED/DISABLED`);
        }
      }.bind(this));
    } else {
      console.log('ERROR! Invalid access.');
    }
  }

  // Bumps our filter
  preBumpFilter(id, start, back, stime, etime) {
    this.setState({
      bumpAmount: this.state.bumpAmount + 0.1,
    }, function() {
      this.bumpFilter(id, start, back, stime, etime, false);
    });
  }
  preBumpFilterBoth(id, backwards, stime, etime) {
    this.setState({
      bumpAmount: 1.0,
    }, function() {
      this.bumpFilter(id, true, backwards, stime, etime, true);
    });
  }
  bumpFilter = _.debounce((id, start, back, stime, etime, both) => {

    // Make sure we have access
    if (this.state.hasAccess) {

      // Make sure we have input
      if (this.props.match.params.id !== '' && stime > 0.0 && etime > 0.0 && this.state.bumpAmount > 0.0 && id) {

        // Get our reference point
        var videoId = this.props.match.params.id;
        var filterRef = this.props.firebase.db.collection('filters').doc(videoId);
        var bumpAmount = parseFloat(this.state.bumpAmount.toFixed(2));

        console.log('Bumping video ' + this.props.match.params.id + ' filter ' + id + ' ' + (start ? 'start time' : 'end time') + ' ' + bumpAmount + ' ' + (back ? 'backwards' : 'forwards') + ' (current stime=' + stime + ', current etime=' + etime + ').');

        // Change tbody
        var tbodyState = this.state.tbody;

        // Get the one
        var item = _.findIndex(tbodyState, function(obj) {
          return obj.data.id === id;
        });

        // Change that one filter
        if (both) {
          if (back) {
            tbodyState[item].data.start = parseFloat((parseFloat(stime) - bumpAmount).toFixed(2));
            tbodyState[item].data.end = parseFloat((parseFloat(etime) - bumpAmount).toFixed(2));
          } else {
            tbodyState[item].data.start = parseFloat((parseFloat(stime) + bumpAmount).toFixed(2));
            tbodyState[item].data.end = parseFloat((parseFloat(etime) + bumpAmount).toFixed(2));
          }
        } else {
          if (start) {
            if (back) {
              tbodyState[item].data.start = parseFloat((parseFloat(stime) - bumpAmount).toFixed(2));
            } else {
              tbodyState[item].data.start = parseFloat((parseFloat(stime) + bumpAmount).toFixed(2));
            }
          } else {
            if (back) {
              tbodyState[item].data.end = parseFloat((parseFloat(etime) - bumpAmount).toFixed(2));
            } else {
              tbodyState[item].data.end = parseFloat((parseFloat(etime) + bumpAmount).toFixed(2));
            }
          }
        }

        // Update state
        this.setState({
          tbodyState,
        });

        // Loop to restructure the data
        var savedArray = [];
        for (var i = 0; i < tbodyState.length; i++) {
          savedArray.push(tbodyState[i].data);
        }

        // Update the server
        filterRef.update({
          filters: savedArray,
        })
        .then(function() {

          // Send this one updated filter to the extension
          if (this.state.extension) {
            try {

              console.log(`Telling the background script that a filter was bumped`);

              chrome.runtime.sendMessage(this.state.extension, { message: "getupdatedfilter", filteredit: tbodyState[item].data },
                function(response) {

                  console.log(`##### DB DEBUG ##### We got a response`, response);

                  // If the background has an active connection to the playing video and everything was updated
                  if (response && response.message && response.message === "gotupdatedfilters") {
                    console.log('Filter was updated in the client!');
                  }

                  // If there is no connection between the background and the playing video
                  else if (response && response.message && response.message === "noconnectiontofrontend") {
                    alert('WARNING! We do not seem to have a connection to the playing video, so we cannot update the filters real-time. Please refresh your video to restore the connection to the backend.');
                  }

                  // If there was some other unknown problem
                  else {
                    alert('ERROR! There was some other problem sending updates to the playing video. You probably should reload your video so that real-time updates resume.');
                  }
                }
              );
            } catch(e) {
              console.log('E2: ', e);
            }
          } else {
            console.log(`ERROR! We don't have an active connection to an extension to let the background know that a filter was BUMPED`);
          }
        }.bind(this));
      }

      // Error
      else {
        console.log('ERROR! Invalid inputs.');
      }
    } else {
      console.log('ERROR! Invalid access.');
    }

    this.setState({
      bumpAmount: 0.0,
    });
  }, 750);

  // Show our modal
  preToggleModal(type, data, key) {

    // Set our modal data
    this.setState({
      filterType: data.type,
      filterSeverity: data.severity,
      filterDescription: data.description,
      filterStart: data.start,
      filterEnd: data.end,
      filterAction: data.action,
      filterDisabled: data.disabled,
      filterId: data.id,
      videoId: data.id,
      modalType: type,
    });
  }

  // Show our report modal
  preToggleReportModal(type, data, key) {

    // Set our modal data
    this.setState({
      report: data,
      modalType: type,
    });
  }

  // Hide our modal
  closeModal() {
    this.setState(prevState => ({
      filterType: '',
      filterSeverity: null,
      filterDescription: '',
      filterStart: '',
      filterEnd: '',
      filterAction: '',
      filterDisabled: '',
      report: '',
      filterId: '',
      videoId: '',
      modalType: '',
    }));
  };

  // Deletes a report
  deleteReport(event) {

    // Make sure no other action is taken
    event.preventDefault();

    // Make sure we have access
    if (this.state.hasAccess) {

      // Make sure we have input
      if (this.state.report && this.state.report.time) {

        // Get our reference point
        var videoId = this.props.match.params.id;
        var filterRef = this.props.firebase.db.collection('filters').doc(videoId);
        var issueRef = this.props.firebase.db.collection('issues').doc(videoId);

        // Change tbody
        var allReports = [];

        // Loop through them
        _.forEach(this.state.allReports, function(value, key) {

          // Add it to our array that still exists
          if (!_.isEqual(this.state.report, value)) {
            allReports.push(value);
          }

        }.bind(this));

        // Update our state
        this.setState({
          allReports,
        });

        // If there are no more issues
        if (allReports.length === 0) {
          issueRef.delete();
        }

        // Otherwise
        else {
          issueRef.set({
            filters: filterRef,
            reports: allReports,
          });
        }

        // Close the modal
        this.closeModal();
      } else {
        console.log('ERROR! Invalid report.');
      }
    } else {
      console.log('ERROR! Invalid access.');
    }
  }

  // Deletes a filter
  // To do so, we need to get all filters and then take one of them out in the code and then re-send the full list to the server
  deleteFilter(event) {

    // Make sure no other action is taken
    event.preventDefault();

    //console.log('#####BEAVIS#####Deleting (2) filter ' + this.state.filterId + ' from video ' + this.props.match.params.id);

    // Make sure we have access
    if (this.state.hasAccess) {

      // Make sure we have input
      if (this.state.filterId !== '') {

        // Get our reference point
        var videoId = this.props.match.params.id;
        var filterRef = this.props.firebase.db.collection('filters').doc(videoId);

        // Change tbody
        var tbodyState = this.state.tbody;

        // Get the one
        var item = _.findIndex(tbodyState, function(obj) {
          return obj.data.id === this.state.filterId;
        }.bind(this));

        //console.log('#####BEAVIS#####Deleting this one (id=' + this.state.filterId + ', index=' + item + '): ', tbodyState[item]);

        // Remove it
        tbodyState.splice(item, 1);

        // Update state
        this.setState({
          tbodyState,
        });

        // Loop to restructure the data
        var savedArray = [];
        for (var i = 0; i < tbodyState.length; i++) {
          savedArray.push(tbodyState[i].data);
        }

        // Update the server
        filterRef.update({
          filters: savedArray,
        });

        this.closeModal();
      }

      // Error
      else {
        console.log('ERROR! Invalid inputs.');
      }
    } else {
      console.log('ERROR! Invalid access.');
    }
  }

  // Edits a filter
  editFilter(event) {

    // Make sure no other action is taken
    event.preventDefault();

    // Make sure we have access
    if (this.state.hasAccess) {

      // Make sure we have input
      if (this.state.filterId !== '' && this.state.filterSeverity !== null && this.state.filterType !== '' && this.state.filterDescription !== '' && this.state.filterStart !== '' && this.state.filterEnd !== '' && this.state.filterAction !== '') {

        // Get our reference point
        var videoId = this.props.match.params.id;
        var filterRef = this.props.firebase.db.collection('filters').doc(videoId);

        // Change tbody
        var tbodyState = this.state.tbody;

        // Get the one
        var item = _.findIndex(tbodyState, function(obj) {
          return obj.data.id === this.state.filterId;
        }.bind(this));

        //console.log('#####BEAVIS#####Changing this one (id=' + this.state.filterId + ', index=' + item + '): ', tbodyState[item]);

        // Change that one filter
        tbodyState[item].data = {
          id: this.state.filterId,
          type: this.state.filterType,
          severity: this.state.filterSeverity ? this.state.filterSeverity : 3,
          description: this.state.filterDescription,
          end: this.state.filterEnd,
          start: this.state.filterStart,
          action: this.state.filterAction,
          disabled: this.state.filterDisabled,
        };

        // Update state
        this.setState({
          tbodyState,
        });

        // Loop to restructure the data
        var savedArray = [];
        for (var i = 0; i < tbodyState.length; i++) {
          savedArray.push(tbodyState[i].data);
        }

        // Update the server
        filterRef.update({
          filters: savedArray,
        })
        .then(function() {

          console.log('A filter was manually updated!');

          // Send this one updated filter to the extension (just in case the DB doesn't automatically detect an update)
          if (this.state.extension) {
            try {

              console.log(`Telling the background script that a filter was manually edited`);

              chrome.runtime.sendMessage(this.state.extension, { message: "getupdatedfilter", filteredit: tbodyState[item].data },
                function(response) {

                  console.log(`##### DB DEBUG ##### We got a response after manual edit`, response);

                  // If the background has an active connection to the playing video and everything was updated
                  if (response && response.message && response.message === "gotupdatedfilters") {
                    console.log('Filter was updated in the client!');
                  }

                  // If there is no connection between the background and the playing video
                  else if (response && response.message && response.message === "noconnectiontofrontend") {
                    alert('WARNING! You edited a filter manually, but we do not seem to have a connection to the playing video, so we cannot update the filters real-time. Please refresh your video to restore the connection to the backend.');
                  }

                  // If there was some other unknown problem
                  else {
                    alert('ERROR! You edited a filter manually, but there was some other problem sending updates to the playing video. You probably should reload your video so that real-time updates resume.');
                  }
                }
              );
            } catch(e) {
              console.log('E2: ', e);
            }
          } else {
            console.log(`ERROR! We don't have an active connection to an extension to let the background know that a filter was MANUALLY EDITED`);
          }
        }.bind(this));

        this.closeModal();
      }

      // Error
      else {
        console.log('ERROR! Invalid inputs.');
      }
    } else {
      console.log('ERROR! Invalid access.');
    }
  }

  // adjustAllFilters Changed
  handleAdjustAllFiltersChange(event) {
    var newValue = event.target.value;
    this.setState({
      adjustAllFiltersTime: newValue,
    });
  }
  // Allows a user to adjust all filters slightly (mostly used when a swear pre-set captions file was off by a little bit)
  adjustAllFilters(event) {

    event.preventDefault();

    var floatValue = parseFloat(this.state.adjustAllFiltersTime);

    // Make sure we have access
    if (this.state.hasAccess) {

      if (!isNaN(floatValue) && floatValue != 0) {
        var nonFloatRounded = floatValue.toFixed(2);
        var floatValueRounded = parseFloat(nonFloatRounded);

        // Get our reference point
        var videoId = this.props.match.params.id;
        var filterRef = this.props.firebase.db.collection('filters').doc(videoId);

        // Change tbody
        var tbodyState = this.state.tbody;

        // Loop through each filter
        for (var i = 0; i < tbodyState.length; i++) {
          var item = tbodyState[i];
          var oldStart = parseFloat(item.data.start);
          var oldEnd = parseFloat(item.data.end);
          var newStart = parseFloat((oldStart + floatValueRounded).toFixed(2));
          var newEnd = parseFloat((oldEnd + floatValueRounded).toFixed(2));

          //console.log('Changing oldStart (' + oldStart + ') to newStart (' + newStart + ' for filter', item);

          tbodyState[i].data.start = newStart;
          tbodyState[i].data.end = newEnd;
        }

        // Update state
        this.setState({
          tbodyState,
        });

        // Loop to restructure the data
        var savedArray = [];
        for (var i = 0; i < tbodyState.length; i++) {
          savedArray.push(tbodyState[i].data);
        }

        // Update the server
        filterRef.update({
          filters: savedArray,
        });
      }
    } else {
      console.log('ERROR! Invalid access.');
    }
  }

  // adjustAllFilters2 Changed
  handleAdjustAllFiltersChange2(event) {
    var newValue = event.target.value;

    console.log('Setting progressive filter time to ' + newValue, event);

    this.setState({
      adjustAllFiltersTime2: newValue,
    });
  }
  // Allows a user to adjust all filters slightly (mostly used when a swear pre-set captions file was off by a little bit)
  adjustAllFilters2(event) {
    event.preventDefault();

    // Get the amount of time the adjustment will be
    // Note: The filters will adjust different lengths depending on how far into the movie they are
    var floatValue = parseFloat(this.state.adjustAllFiltersTime2);

    // Make sure we have access
    if (this.state.hasAccess) {

      if (!isNaN(floatValue) && floatValue != 0) {

        var nonFloatRounded = floatValue.toFixed(2);
        var floatValueRounded = parseFloat(nonFloatRounded);

        // Get our reference point
        var videoId = this.props.match.params.id;
        var filterRef = this.props.firebase.db.collection('filters').doc(videoId);

        // Change tbody
        var tbodyState = this.state.tbody;

        // Try to get how much each filter will adjust
        var lastItemInMinutes = Math.ceil((tbodyState[tbodyState.length - 1]).data.end/60);
        var perMinuteAdjustment = floatValue/lastItemInMinutes;

        console.log('Total adjustment is: ' + floatValue);
        console.log('Minutes from start to last filter is: ' + lastItemInMinutes);
        console.log('Per minute adjustment is: ' + perMinuteAdjustment);

        //
        //console.log('There are ' + lastItemInMinutes + ' minutes to traverse; Total adjustment is ' + floatValue + ' which is ' + perMinuteAdjustment + ' per minute.');

        // Loop through each filter
        for (var i = 0; i < tbodyState.length; i++) {

          // The filter item
          var item = tbodyState[i];

          // The old start and end time of the filter
          var oldStart = parseFloat(item.data.start);
          var oldEnd = parseFloat(item.data.end);

          // How many minutes into the production are we (rounded up)?
          var minutesIn = Math.ceil(item.data.end/60);
          var adjustmentTime = parseFloat((perMinuteAdjustment * minutesIn).toFixed(2));
          if (floatValueRounded > 0 && adjustmentTime < 0.01) {
            adjustmentTime = 0.01;
          } else if (floatValueRounded < 0 && adjustmentTime > -0.01) {
            adjustmentTime = -0.01;
          }

          console.log('Filter at ' + minutesIn, adjustmentTime);

          // Now set the new start and end time of the filter
          var newStart = parseFloat((oldStart + adjustmentTime).toFixed(2));
          var newEnd = parseFloat((oldEnd + adjustmentTime).toFixed(2));

          //console.log('Changing oldStart (' + oldStart + ') to newStart (' + newStart + ' for filter', item);

          // Save it to our state object (so it actually takes effect)
          tbodyState[i].data.start = newStart;
          tbodyState[i].data.end = newEnd;
        }

        // Update state
        this.setState({
          tbodyState,
        });

        // Loop to restructure the data
        var savedArray = [];
        for (var i = 0; i < tbodyState.length; i++) {
          savedArray.push(tbodyState[i].data);
        }

        // Update the server
        filterRef.update({
          filters: savedArray,
        });
      }
    } else {
      console.log('ERROR! Invalid access.');
    }
  }

  // On mount, grab all of the filters
  componentDidMount() {

    // Get our current user (the database one, not the firebase one)
    var userRef = this.props.firebase.db.collection('users').doc(this.props.firebase.auth.currentUser.uid);
    userRef.get().then((user) => {
      if (user.exists) {
        var currentUser = user.data();
        this.setState({
          currentUser,
        });
      }
    });

    // First, grab the filter document
    var videoId = this.props.match.params.id;

    // Every 5 seconds, we want to run some code to see if we are still connected to a video or not.
    this.interval = setInterval(() => {
      if (this.state.extension) {
        chrome.runtime.sendMessage(this.state.extension, { message: "vidconnection" },
          function(response) {
            if (response && response.tabId) {
              if (response.tabId != this.state.tabId) {
                this.setState({
                  tabId: response.tabId,
                  tabUrl: response.tabUrl,
                  tabUrlFull: response.tabUrlFull,
                });
              }
            } else {
              if (chrome.runtime && chrome.runtime.lastError && chrome.runtime.lastError.message) {
                console.log('Erorr checking connection: ', chrome.runtime.lastError.message);
              }
              this.setState({
                tabId: '',
                tabUrl: '',
                tabUrlFull: '',
              });
            }
          }.bind(this)
        );
      }
    }, 5000);

    // Get the URLs for this filter (given the sources)
    var filterRef = this.props.firebase.db.collection('filters').doc(videoId);
    this.props.firebase.db.collection('sources').where('filters', '==', filterRef).get().then(function(snapshot) {
      snapshot.forEach(function(doc) {
        var tabUrlsTmpData = doc.data();
        var tabUrlsTmp = []
        tabUrlsTmp.push(tabUrlsTmpData.url);
        this.setState({
          tabUrls: tabUrlsTmp,
        });
      }.bind(this));
    }.bind(this));

    // See if there are any issue reports
    this.props.firebase.db.collection('issues').where('filters', '==', filterRef).get().then(function(snapshot) {
      var allReports = [];
      snapshot.forEach(function(doc) {
        if (doc.data().reports) {
          for (var i = 0; i < doc.data().reports.length; i++) {
            allReports.push(doc.data().reports[i]);
          }
        }
      }.bind(this));
      this.setState({
        allReports,
      });
    }.bind(this));

    // Grab a subscription to this filter (so that if it changes, it auto-updates)
    this.unsubscribe = this.props.firebase.db.collection('filters').doc(videoId).onSnapshot(doc => {

      // Start with an empty table of results
      var tbody = [];

      // Get the data
      var filterData = doc.data();

      // Sort out permissions
      var hasAccess = false;

      // If the site is in verification status or published you must be an admin or supervisor
      if ((filterData.published === true || filterData.published === false) && (this.state.currentUser.permission === 'admin' || this.state.currentUser.permission === 'supervisor')) {
        hasAccess = true;
      }

      // If the site is still in the works
      else if (filterData.published === null) {
        hasAccess = true;
      }

      this.setState({
        published: filterData.published,
        hasAccess: hasAccess,
        filterSources: filterData.sources,
      });

      var sortedFilters = filterData.filters.sort(function(a, b) {
        return parseFloat(a.start) - parseFloat(b.start);
      });

      // Loop through each filter/video that we found
      sortedFilters.forEach(filter => {

        // Add to the table
        //console.log('#####BEAVIS##### (1) Sending with ID: ' + filter.id + '.....');
        tbody.push({
          className: "",
          data: {
            type: filter.type,
            severity: filter.severity === undefined ? null : filter.severity,
            description: filter.description,
            start: filter.start,
            end: filter.end,
            action: filter.action,
            disabled: filter.disabled === undefined ? null : filter.disabled,
            id: filter.id,
          },
        });

        this.setState({
          documentTitle: filterData.title,
        });
      });

      // Update the state
      console.log('Disclaimer: ', filterData.disclaimer);
      this.setState({
        tbody: tbody,
        disclaimer: filterData.disclaimer,
      });

    });

    try {
      // Check if the LIVE extension exists
      chrome.runtime.sendMessage('peheiohonoimeohgjknaaeoaflocficn', { message: "areyououtthere?" },
        function(response) {
          if (response && response.message === 'yesisuream!') {
            console.log('[FinallyFiltered] The LIVE extension IS connected!');
            this.setState({
              extension: 'peheiohonoimeohgjknaaeoaflocficn',
            });
          } else if (chrome.runtime.lastError) {
            console.log('[FinallyFiltered] The LIVE extension IS NOT connected!');
          }
        }.bind(this)
      );

      // Check if the TEST extension exists
      chrome.runtime.sendMessage('cgglcmlnminfemdoofahammhokmlbmjg', { message: "areyououtthere?" },
        function(response) {
          if (response && response.message === 'yesisuream!') {
            console.log('[FinallyFiltered] The TEST extension IS connected!');
            this.setState({
              extension: 'cgglcmlnminfemdoofahammhokmlbmjg',
            });
          } else if (chrome.runtime.lastError) {
            console.log('[FinallyFiltered] The TEST extension IS NOT connected!');
          }
        }.bind(this)
      );
    } catch(e) {
      console.log('E: ', e);
    }
  }

  componentWillUnmount() {
    this.unsubscribe && this.unsubscribe();
    clearInterval(this.interval);
  }

  // Convert float to time
  floatToTime(float) {

    // Hours
    var hours = Math.floor(float/3600.00);
    float -= hours*3600.00;
    var minutes = Math.floor(float/60.00);
    float -= minutes*60.00;

    // Make sure we always have 2 decimals
    float = float.toFixed(2);
    var split = float.split('.');
    var seconds = split[0];
    var micros = split[1];

    // Return a combined value
    return `${hours.toString().length === 1 ? '0' + hours.toString() : hours.toString()}:${minutes.toString().length === 1 ? '0' + minutes.toString() : minutes.toString()}:${seconds.length === 1 ? '0' + seconds + '.' + micros : seconds + '.' + micros}`;
  }

  // Convert time to float
  timeToFloat(time) {
    var splitString = time.split(':');
    var totalTime = parseInt(splitString[0])*3600 + parseInt(splitString[1])*60 + parseFloat(splitString[2]);
    return totalTime.toFixed(2);
  }

  // Takes multiple sources and creates links to the videos from them
  createLinks = () => {

    //console.log('Offers: ', this.state.documentOffers);
    //console.log('Sources: ', this.state.filterSources);

    let filterActions = []
    for (var i = 0; i < this.state.filterSources.length; i++) {
      var thisSource = this.state.filterSources[i];
      var image = null;
      if (thisSource.name === 'hulu') {
        image = hulu;
      } else if (thisSource.name === 'peacock') {
        image = peacock;
      } else if (thisSource.name === 'vudu') {
        image = vudu;
      } else if (thisSource.name === 'moviesanywhere') {
        image = moviesanywhere;
      } else if (thisSource.name === 'youtube') {
        image = youtube;
      } else if (thisSource.name === 'amazon') {
        image = amazon;
      } else if (thisSource.name === 'netflix') {
        image = netflix;
      } else if (thisSource.name === 'disney') {
        image = disney;
      }
      if (image !== null) {
        filterActions.push(<Button color="primary" onClick={() => this.createPlayLink(thisSource.name, this.state.filterId)}>Watch on {thisSource.name}</Button>);
      }
    }
    return filterActions;
  }

  // Creates a play link
  createPlayLink = (source, id) => {
    var filterRef = this.props.firebase.db.collection('filters').doc(this.props.match.params.id);
    this.props.firebase.db.collection('sources').where('filters', '==', filterRef).get().then(function(snapshot) {
      snapshot.forEach(function(doc) {
        var sourceData = doc.data();
        if (sourceData.name === source) {
          var theLink = links[source] + sourceData.url;
          window.open(theLink, '_blank');
        }
      });
    });
  }

  // Creates edit actions for filters and sources
  createActions = (data, key) => {

    return (
      <div>
        <i className='fa fa-trash-alt' style={{fontSize: '17px'}} onClick={() => this.preToggleModal('filterdel', data, key)} title='Delete this filter' />
        &nbsp; &nbsp;
        <i className='fa fa-edit' style={{fontSize: '17px'}} onClick={() => this.preToggleModal('filteredit', data, key)} title="Edit this filter" />
        &nbsp; &nbsp;
        <i className='fa fa-long-arrow-alt-left' style={{fontSize: '25px', cursor: 'pointer'}} onClick={() => this.preBumpFilterBoth(data.id, true, data.start, data.end)} title="Move entire filter back 1 second" />
        &nbsp; &nbsp;
        <i className='fa fa-long-arrow-alt-right' style={{fontSize: '25px', cursor: 'pointer'}} onClick={() => this.preBumpFilterBoth(data.id, false, data.start, data.end)} title="Move entire filter ahead 1 second" />
        &nbsp; &nbsp;
        <i className={data.disabled ? 'fa fa-toggle-off' : 'fa fa-toggle-on'} style={data.disabled ? {fontSize: '25px', cursor: 'pointer', color: 'red'} : {fontSize: '25px', cursor: 'pointer', color: 'green'}} onClick={() => this.enableDisableFilter(data.id)} title="Temporarily disable the filter" />
      </div>
    );
  }

  // Creates report actions
  createReportActions = (data, key) => {

    return (
      <div>
        <i className='fa fa-trash-alt' style={{fontSize: '17px'}} onClick={() => this.preToggleReportModal('reportdel', data, key)} title='Mark this as resolved' />
      </div>
    );
  }

  // Creates a severity dropdown list based on which filterType is selectd
  createSeverity = () => {

    // 'sex' filter severities
    if (this.state.filterType === 'suggestive') {
      return (
        <Input type="select" name="severity" value={this.state.filterSeverity} onChange={this.handleSeverityChange}>
          <option vaue="">Select Severity</option>
          <option value="1">1 (very mild)</option>
          <option value="2">2 (mild)</option>
          <option value="3">3 (moderate)</option>
          <option value="4">4 (major)</option>
          <option value="5">5 (explicit)</option>
        </Input>
      );
    }

    // 'language' filter severities
    else if (this.state.filterType === 'language') {
      return (
        <div>
          <Input type="select" name="severity" value={this.state.filterSeverity} onChange={this.handleSeverityChange}>
            <option vaue="">Select Severity</option>
            <option value="1">1 (very mild)</option>
            <option value="2">2 (mild)</option>
            <option value="3">3 (moderate)</option>
            <option value="4">4 (major)</option>
            <option value="5">5 (explicit)</option>
          </Input>
        </div>
      );
    }

    // 'violence' filter severities
    else if (this.state.filterType === 'violence') {
      return (
        <Input type="select" name="severity" value={this.state.filterSeverity} onChange={this.handleSeverityChange}>
          <option vaue="">Select Severity</option>
          <option value="1">1 (very mild)</option>
          <option value="2">2 (mild)</option>
          <option value="3">3 (moderate)</option>
          <option value="4">4 (major)</option>
          <option value="5">5 (explicit)</option>
        </Input>
      );
    }

    // 'disturbing' filter severities
    else if (this.state.filterType === 'disturbing') {
      return (
        <Input type="select" name="severity" value={this.state.filterSeverity} onChange={this.handleSeverityChange}>
          <option vaue="">Select Severity</option>
          <option value="1">1 (very mild)</option>
          <option value="2">2 (mild)</option>
          <option value="3">3 (moderate)</option>
          <option value="4">4 (major)</option>
          <option value="5">5 (explicit)</option>
        </Input>
      );
    }

    // 'drugs' filter severities
    else if (this.state.filterType === 'narcotics') {
      return (
        <Input type="select" name="severity" value={this.state.filterSeverity} onChange={this.handleSeverityChange}>
          <option vaue="">Select Severity</option>
          <option value="1">1 (very mild)</option>
          <option value="2">2 (mild)</option>
          <option value="3">3 (moderate)</option>
          <option value="4">4 (major)</option>
          <option value="5">5 (explicit)</option>
        </Input>
      );
    }

    // 'credits' filter severities
    else if (this.state.filterType === 'miscellaneous') {
      return (
        <Input type="select" name="severity" value={this.state.filterSeverity} onChange={this.handleSeverityChange}>
          <option vaue="">Select Severity</option>
          <option value="1">1 (very mild)</option>
          <option value="2">2 (mild)</option>
          <option value="3">3 (moderate)</option>
          <option value="4">4 (major)</option>
          <option value="5">5 (explicit)</option>
        </Input>
      );
    }

    // For anything else, we don't currently show any options
    else {
      return null;
    }
  }

  // Render everything
  render() {

    return (
      <div className="content">
        <Row>
          <Col xs={12}>
            <Card>
              <CardHeader>
                {this.createLinks()}
                <CardTitle tag="h4">{this.state.documentTitle}</CardTitle>
              </CardHeader>
              <CardBody>
                {
                  (this.state.tabId && this.state.tabUrl && this.state.tabUrls.indexOf(this.state.tabUrl) !== -1)
                  ?
                    <Alert color='primary' className='alert-with-icon'>
                      We have an active connection with your video tab ({this.state.tabUrlFull}) so any changes you make here should update real-time in the video!
                    </Alert>
                  :
                    (
                      this.state.tabId === null
                      ?
                        <Alert color='warning' className='alert-with-icon'>
                          We are establishing a connection to your active video...
                        </Alert>
                      :
                        <Alert color='danger' className='alert-with-icon'>
                          We do not have an active connection with the video tab. Please do 3 things to restore this connection: 1) Restart the browser extension, 2) Refresh this window, then 3) Refresh the video itself.
                        </Alert>
                    )
                }
                {
                  this.state.extension
                  ?
                    null
                  :
                  <Alert color='danger' className='alert-with-icon'>
                    This edit interface is NOT connected to your extension, so edits made here will NOT automatically populate to the playing video real-time. Please refresh your video followed by this screen to reconnect things.
                  </Alert>
                }

                {this.state.allReports.length > 0
                  ?
                    <div>
                      <h4>Reports</h4>
                      <Table responsive>
                        <thead className="text-primary">
                          <tr>
                            <th>Email</th>
                            <th>Time</th>
                            <th>Description</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.allReports.map((prop, key) => {
                            return (
                              <tr key={key}>
                                <td>{prop.email}</td>
                                <td>{this.floatToTime(prop.time)}</td>
                                <td>{prop.description}</td>
                                <td>
                                  {this.state.hasAccess ? this.createReportActions(prop, key) : null}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </div>
                  :
                    null
                }

                <h4>Filters</h4>
                <Table responsive>
                  <thead className="text-primary">
                    <tr>
                      {thead.map((prop, key) => {
                        if (key !== thead.length - 1) {
                          return <th key={key}>{prop}</th>;
                        } else {
                          return null;
                        }
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.tbody.map((prop, key) => {
                      var thisDescription = prop.data.description;
                      if (thisDescription.match(/[0-9][0-9][0-9][0-9]/)) {
                        var thisTypeDefinitions = _.find(jsonDefinitions['filterDefinitions'], { 'type': prop.data.type.charAt(0).toUpperCase() + prop.data.type.slice(1) });
                        var thisSeverityItems = _.find(thisTypeDefinitions.items, { 'level': prop.data.severity });
                        var thisActualItem = _.find(thisSeverityItems.items, { 'id': parseInt(thisDescription) });
                        if (thisActualItem) {
                          thisDescription = thisActualItem.viewer;
                        } else {
                          thisDescription = prop.data.description;
                        }
                      }
                      return (
                        <tr key={key}>
                          <td>{prop.data.type}</td>
                          <td>{prop.data.severity ? prop.data.severity : 'n/a'}</td>
                          <td>{thisDescription}</td>
                          <td>{prop.data.id}</td>
                          <td style={{fontFamily: 'Consolas, monaco, monospaced', textAlign: 'center'}}>
                            {this.floatToTime(prop.data.start)}
                            <br />
                            <i className='fa fa-arrow-alt-circle-left' style={this.state.hasAccess ? {fontSize: '17px', cursor: 'pointer'} : {fontSize: '17px', cursor: 'pointer', display: 'none'}} onClick={() => this.preBumpFilter(prop.data.id, true, true, prop.data.start, prop.data.end)} title='Adjust time 0.1 seconds earlier (click multiple times for multiple adjustments)' />
                            &nbsp;&nbsp;&nbsp;
                            <i className='fa fa-arrow-alt-circle-right' style={this.state.hasAccess ? {fontSize: '17px', cursor: 'pointer'} : {fontSize: '17px', cursor: 'pointer', display: 'none'}} onClick={() => this.preBumpFilter(prop.data.id, true, false, prop.data.start, prop.data.end)} title='Adjust time 0.1 seconds later (click multiple times for multiple adjustments)' />
                          </td>
                          <td style={{fontFamily: 'Consolas, monaco, monospaced', textAlign: 'center'}}>
                            {this.floatToTime(prop.data.end)}
                            <br />
                            <i className='fa fa-arrow-alt-circle-left' style={this.state.hasAccess ? {fontSize: '17px', cursor: 'pointer'} : {fontSize: '17px', cursor: 'pointer', display: 'none'}} onClick={() => this.preBumpFilter(prop.data.id, false, true, prop.data.start, prop.data.end)} title='Adjust time 0.1 seconds earlier (click multiple times for multiple adjustments)' />
                            &nbsp;&nbsp;&nbsp;
                            <i className='fa fa-arrow-alt-circle-right' style={this.state.hasAccess ? {fontSize: '17px', cursor: 'pointer'} : {fontSize: '17px', cursor: 'pointer', display: 'none'}} onClick={() => this.preBumpFilter(prop.data.id, false, false, prop.data.start, prop.data.end)} title='Adjust time 0.1 seconds later (click multiple times for multiple adjustments)' />
                          </td>
                          <td>{prop.data.action}</td>
                          <td>
                            {this.state.hasAccess ? this.createActions(prop.data, key) : null}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>

                {
                  this.state.hasAccess
                  ?
                    <div>
                      <h4>Disclaimer</h4>
                      <p>(Optional) A one or two line parental content disclaimer in case there is content that you really couldn't filter out. For example: "Female characters throughout the show are often wearing immodest clothing."</p>
                      <textarea rows='3' cols='75' name="disclaimer" id="disclaimer" onChange={this.preModifyDisclaimer} value={this.state.disclaimer}>{this.state.disclaimer}</textarea>
                      <br />
                      <Button onClick={this.modifyDisclaimer} color="primary">Submit</Button>
                    </div>
                  :
                    <p>{this.state.disclaimer}</p>
                }

                {
                  this.state.hasAccess
                  ?
                    <div>
                      <h4>Special Actions</h4>
                      Adjust all filters <input type="text" className="w-10" name="adjust-all-filters-time" value={this.state.adjustAllFiltersTime} onChange={this.handleAdjustAllFiltersChange} /> seconds <Button onClick={this.adjustAllFilters} color="primary">Go</Button>
                      <br />
                      Adjust all filters progressively <input type="text" className="w-10" name="adjust-all-filters-time-2" value={this.state.adjustAllFiltersTime2} onChange={this.handleAdjustAllFiltersChange2} /> seconds <Button onClick={this.adjustAllFilters2} color="primary">Go</Button>
                    </div>
                  :
                    null
                }

              </CardBody>
            </Card>
          </Col>
        </Row>

        <Input type="checkbox" name="filterDefinitionsBox" id="filterDefinitionsBox" style={{ marginLeft: '10px' }} onChange={this.toggleFilterDefinitionsBox} checked={this.state.toggleFilterDefinitionsBox} />
        <Label style={{ marginLeft: '30px' }}>Show filter definitions and descriptions</Label>
        <div style={this.state.toggleFilterDefinitionsBox ? null : hidden}>
          <h3>Filter Definitions, Descriptions & Guidelines</h3>
          <p>Use this guide to help you know which filter types, severities and actions to take as you filter videos. Here are some general guidelines first, followed by more specifics:</p>
          <ul>
            <li>
              Some shows have mildly inappropriate content (e.g. a lead character wearing a slightly immodest outfit) throughout the entire show, such that it would be infeasible to filter out every instance.
              In cases such as this, where creating individual filters would take way too much time and would ultimately destroy the viewing experience as a whole, please just create a disclaimer for the entire video rather than creating individual filters for each instance.
              This should only be done in the case of very mild instances. All moderate to major items should always have an individual filter.
            </li>
            <li>Keep in mind the intended audience of the video you are filtering. For example, in a kid's show, words like "stupid" or "idiot", etc. should have level 1 filters, however on a show intended for a more mature audience, filters for such words are probably not necessary. When in doubt, create a filter.</li>
          </ul>
          <h4>Filter Actions</h4>
          <h5>Mute</h5>
          <p>Causes the video to be silenced. If the dialog is accompanied by inappropriate actions, when lips can easily be read, or if the muted segment is longer than 2-3 seconds, use a skip filter instead.</p>
          <h5>Skip</h5>
          <p>Causes the video to skip ahead to the end of the filter.</p>
          <h5>Block/Hide</h5>
          <p>This is a special filter that can be used to block content visually, while still allowing the viewer to hear the audio. Because it can be pretty jarring, it should really only be used for SHORT segments where the audible dialog is essential to the plot or the inappropriate scene is part of a song.</p>
          <h4>Filter Types and Levels</h4>
          <h5>Suggestive</h5>
          <p>Note: The vast majority of suggestive filters will be level 2. Level 1 is reserved for extremely innocent cases, while Level 3 is reserved for explicit situations.</p>
          <h6>Level 1</h6>
          <ul>
            <li>Quick kisses (no filter needed for simple kisses on the cheek/forehead or a parent kissing a child).</li>
            <li>Mild objectification of the opposite gender (e.g. ogling, cat-calling, talking about someone's butt, etc.)</li>
            <li>Mild innuendo.</li>
            <li>Non-suggestive immodesty (e.g. a woman wearing a mildly low-cut shirt; a man in a speedo or boxer shorts).</li>
            <li>Same-sex attraction or relationships (e.g. a man referring to himself as gay, or talking about his husband).</li>
            <li>The word "sexy" when used in a non-suggestive way.</li>
          </ul>
          <h6>Level 2</h6>
          <ul>
            <li>Women in very revealing clothing.</li>
            <li>Prolonged kissing or making out.</li>
            <li>Mild kisses between two men or two women.</li>
            <li>Suggestive dialog or major innuendo (direct or slang references to sex).</li>
            <li>Implied sexual relationships.</li>
            <li>Blurred out nudity.</li>
            <li>A woman in her underwear.</li>
            <li>Dialog about prostitution or perversion.</li>
            <li>Mentioning of male or female genitals.</li>
            <li>Non-explicit cartoon nudity.</li>
          </ul>
          <h6>Level 3</h6>
          <ul>
            <li>Very passionate kissing</li>
            <li>Nudity</li>
            <li>Sex (with or without nudity)</li>
          </ul>
          <h5>Language</h5>
          <p>Note: Suggestive swear words when used in a suggestive context should be in the "Suggestive" category rather than "Language".</p>
          <h6>Level 1</h6>
          <ul>
            <li>Shut up</li>
            <li>Friggin', frickin', frieking, etc.</li>
            <li>Oh my gosh</li>
            <li>Oh my (not finished)</li>
            <li>Gah</li>
            <li>Jeez</li>
            <li>Screw (as in 'screw it', not 'I screwed up')</li>
            <li>Crap (in kid shows)</li>
            <li>Idiot (in kid shows)</li>
            <li>Stupid (in kid shows)</li>
            <li>Sucks (in kid shows)</li>
          </ul>
          <h6>Level 2</h6>
          <ul>
            <li>Religious profanities (Oh my G**, G**, L**d)</li>
            <li>bi**h, Son of a b****, bas**rd</li>
            <li>he**, da**, a**, pi**, di**</li>
            <li>per**rt, wh**e, sl*t</li>
            <li>"F" you</li>
          </ul>
          <h6>Level 3</h6>
          <ul>
            <li>F-word, S-word</li>
          </ul>
          <h5>Violence</h5>
          <h6>Level 1</h6>
          <ul>
            <li>Punches</li>
            <li>Kicks</li>
            <li>Physical bullying</li>
            <li>Non-graphic cartoon violence/shooting</li>
          </ul>
          <h6>Level 2</h6>
          <ul>
            <li>Someone getting beat up</li>
            <li>Someone getting hit with an object (pistol-whipping, getting knocked out, etc.)</li>
            <li>Men hitting women</li>
            <li>People getting shot (no blood)</li>
            <li>Stabbing (without blood)</li>
            <li>Non-graphic suicides</li>
            <li>Physical torture</li>
          </ul>
          <h6>Level 3</h6>
          <ul>
            <li>Bloody shootings/stabbings</li>
          </ul>
          <h5>Disturbing</h5>
          <h6>Level 1</h6>
          <ul>
            <li>Non-physical bullying</li>
            <li>Suicide mentioned</li>
            <li>Torture method explained</li>
            <li>Superficial bleeding</li>
            <li>Someone vomiting, or vomit being shown</li>
            <li>Most surgeries</li>
            <li>Non-graphic wounds</li>
            <li>Prejudice</li>
            <li>Dead person shown</li>
            <li>Getting a shot</li>
          </ul>
          <h6>Level 2</h6>
          <ul>
            <li>Bloody wounds</li>
            <li>Compound fractures</li>
            <li>Coughing up blood</li>
            <li>Racism</li>
            <li>Dead person with some physical, non-graphic damage</li>
            <li>Child abuse</li>
          </ul>
          <h6>Level 3</h6>
          <ul>
            <li>Large quantities of dead bodies</li>
            <li>Extremely bloody/graphic wounds</li>
            <li>Rotten/mangled, dead corpse</li>
            <li>Sever child abuse</li>
          </ul>
          <h5>Drugs</h5>
          <p>Again, use your best judgment here based on the intended audience. In a show intended for mature audiences, for example, talk of getting drunk probably doesn't need a filter. Also, in a cop show, mere mention of an illegal drug wouldn't need a filter, however, someone talking about 'snorting lots of crack' would be filtered.</p>
          <h6>Level 1</h6>
          <ul>
            <li>Drinking hard liquor/doing shots</li>
            <li>Excessive dialog about getting wasted/plastered/drunk</li>
          </ul>
          <h6>Level 2</h6>
          <ul>
            <li>Teenaged smoking/drinking</li>
            <li>Mild illegal drug use</li>
          </ul>
          <h6>Level 3</h6>
          <ul>
            <li>Hard drug usage shown</li>
          </ul>
          <h5>Credits</h5>
          <p>Opening credits should always have a filter when they are more than 5-10 seconds long. Just be sure to filter out anything inappropriate from inside the credits, if applicable. We usually don't add filters for closing credits unless they are to skip ahead to hidden credit scenes.</p>
          <h6>Level 1</h6>
          <ul>
            <li>Opening credits</li>
            <li>Repetitive episode recaps</li>
            <li>Closing credits</li>
          </ul>
        </div>

        <Modal isOpen={this.state.modalType === 'filterdel'} toggle={this.closeModal}>
          <ModalHeader toggle={this.closeModal}>Are you sure you want to delete this filter?</ModalHeader>
          <ModalBody>
            This is NOT reversible! This filter will be absent from all future screenings of this video.
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.deleteFilter}>Yes! Delete.</Button>{' '}
            <Button color="secondary" onClick={this.closeModal}>Cancel</Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={this.state.modalType === 'reportdel'} toggle={this.closeModal}>
          <ModalHeader toggle={this.closeModal}>Are you sure you want to mark this report as completed?</ModalHeader>
          <ModalBody>
            Once you have fully solved a report, you can delete it here.
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.deleteReport}>Yes! Delete.</Button>{' '}
            <Button color="secondary" onClick={this.closeModal}>Cancel</Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={this.state.modalType === 'filteredit'} toggle={this.closeModal}>
          <form id="new-source-form" onSubmit={this.editFilter}>
            <ModalHeader toggle={this.closeModal}>Edit Filter</ModalHeader>
            <ModalBody>
              You can edit the details of a filter here.
              <br /><br />
              <Input type="text" disabled={true} name="id" value={this.state.filterId} />
              <br />
              <Row form>
                <Col md={6}>
                  <FormGroup>
                    <Label for="thefilterstart">Filter Start (hh:mm:ss.nn)</Label>
                    <Input type="text" name="start2" id="thefilterstart" placeholder="SS.MM" defaultValue={this.floatToTime(this.state.filterStart)} onChange={this.handleStartChange} />
                    <Input type="text" name="start" id="thefilterstart" placeholder="SS.MM" value={this.state.filterStart} readOnly />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="thefilterend">Filter End (hh:mm:ss.nn)</Label>
                    <Input type="text" name="end2" id="thefilterend" placeholder="SS.MM" defaultValue={this.floatToTime(this.state.filterEnd)} onChange={this.handleEndChange} />
                    <Input type="text" name="end" id="thefilterend" placeholder="SS.MM" value={this.state.filterEnd} readOnly />
                  </FormGroup>
                </Col>
              </Row>
              <br />
              <Input type="text" name="description" placeholder="Describe this filter" value={this.state.filterDescription} onChange={this.handleDescriptionChange} />
              <br />
              <Input type="select" name="type" value={this.state.filterType} onChange={this.handleTypeChange}>
                <option vaue="">Select Type</option>
                <option value="language">Language</option>
                <option value="violence">Violence</option>
                <option value="suggestive">Suggestive</option>
                <option value="disturbing">Disturbing</option>
                <option value="narcotis">Narcotics</option>
                <option value="miscellaneous">Credits</option>
              </Input>
              <br />
              {this.createSeverity()}
              <br />
              <Input type="select" name="action" value={this.state.filterAction} onChange={this.handleActionChange}>
                <option vaue="">Select Action</option>
                <option value="mute">Mute Audio</option>
                <option value="skip">Skip Scene</option>
                <option value="blur">Blur Screen</option>
              </Input>
            </ModalBody>
            <ModalFooter>
              <Button color="primary">Submit Edit</Button>{' '}
              <Button color="secondary" onClick={this.closeModal}>Cancel</Button>
            </ModalFooter>
          </form>
        </Modal>

      </div>
    );
  }
}

const condition = authUser => !!authUser;

export default compose(
  withFirebase,
  withEmailVerification,
  withAuthorization(condition),
)(RegularTables);
