import React, { Component } from 'react';

import {
  Input, Row, Col, Button, Alert
} from "reactstrap";

import { withFirebase } from '../Firebase';

const SIGN_IN_METHODS = [
  {
    id: 'password',
    provider: null,
  },
  {
    id: 'google.com',
    provider: 'googleProvider',
  },
  // {
  //   id: 'facebook.com',
  //   provider: 'facebookProvider',
  // },
  // {
  //   id: 'twitter.com',
  //   provider: 'twitterProvider',
  // },
];

const INITIAL_STATE = {
  passwordOne: '',
  passwordTwo: '',
  error: null,
  activeSignInMethods: [],
};

class PasswordChangeForm extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };

    this.onDismiss = this.onDismiss.bind(this);
  }

  // When they dismiss the error message
  onDismiss() {
    this.setState({ error: null });
  }

  // When they actually hit the submit button to set their new password
  onSubmit = event => {

    const { passwordOne } = this.state;

    // Try to send it to the server
    this.props.firebase.doPasswordUpdate(passwordOne).then(() => {

      // We are good!
      this.setState({ ...INITIAL_STATE });
    }).catch(error => {

      // If there is some error returned from Firebase, display it
      this.setState({ error });
    });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  fetchSignInMethods = () => {
    this.props.firebase.auth
      .fetchSignInMethodsForEmail(this.props.authUser.email)
      .then(activeSignInMethods =>
        this.setState({ activeSignInMethods, error: null }),
      )
      .catch(error => this.setState({ error }));
  };

  componentDidMount() {
    this.fetchSignInMethods();
  }

  render() {

    const { passwordOne, passwordTwo, activeSignInMethods, error } = this.state;

    //console.log('Active Sign In Methods: ', activeSignInMethods);

    const isInvalid =
      passwordOne !== passwordTwo || passwordOne === '';

    return (
      <div>
        {SIGN_IN_METHODS.map(signInMethod => {
          const onlyOneLeft = activeSignInMethods.length === 1;
          const isEnabled = activeSignInMethods.includes(
            signInMethod.id,
          );

          if (signInMethod.id === 'password' && activeSignInMethods.indexOf('password') !== -1) {
            return (
              <div key={'sign-in-method-div-' + signInMethod.id}>
                <h5>Change Password</h5>
                <form onSubmit={this.onSubmit} key={'sign-in-method-' + signInMethod.id}>
                  <Row>
                    <Col xs="12" sm="12" md="4" lg="4">
                      <Input type="password" name="passwordOne" placeholder="New Password" value={passwordOne} onChange={this.onChange} className="mb-3" />
                    </Col>
                    <Col xs="12" sm="12" md="4" lg="4">
                      <Input type="password" name="passwordTwo" placeholder="Confirm New Password" value={passwordTwo} onChange={this.onChange} className="mb-3" />
                    </Col>
                    <Col xs="12" sm="12" md="4" lg="4">
                      <Button color="primary" disabled={isInvalid} type="submit">Reset My Password</Button>
                    </Col>
                  </Row>

                  {error && <Alert color="danger" toggle={this.onDismiss}>{error.message}</Alert>}
                </form>
              </div>
            );
          }
        })}
      </div>
    );
  }
}

export default withFirebase(PasswordChangeForm);
