/*global chrome*/

import React from "react";
import { compose } from 'recompose';
import { debounce, mapValues, find, findIndex, map, isEmpty } from 'lodash';
import {
  Spinner,
  Alert,
  Card, CardImg, CardBody, CardHeader, CardTitle, CardSubtitle, CardText,
  Table,
  Row,
  Col,
  Modal, ModalHeader, ModalBody, ModalFooter,
  Input,
  FormGroup, Label, Form
} from "reactstrap";
import BrowserDetection from 'react-browser-detection';
import "bootstrap/dist/css/bootstrap.css";
import "assets/scss/paper-dashboard.scss";
import "assets/demo/demo.css";
import { Link } from 'react-router-dom';

import Button from "components/CustomButton/CustomButton.jsx";

import { withAuthorization, withEmailVerification } from 'old-components/Session';
import { withFirebase } from 'old-components/Firebase';

import hulu from "assets/images/hulu.png";
import peacock from "assets/images/peacock.png";
import vudu from "assets/images/vudu.png";
import moviesanywhere from "assets/images/moviesanywhere.png";
import amazon from "assets/images/amazon.png";
import youtube from "assets/images/youtube.png";
import netflix from "assets/images/netflix.png";
import disney from "assets/images/disney.png";

const omdb = new (require('omdbapi'))('12d8ee3d');
const justwatch = new(require('justwatch-api'));

const parseSRT = require('parse-srt');

///////// STYLES /////////
const posterStyle = {
  width: '350px',
  marginTop: '10px',
};
const hidden = {
  display: 'none',
}

const browserHandler = {
  chrome: () => '',
  default: () =>
    <Alert
      color='danger'
      className='alert-with-icon'
    >
      <span data-notify="icon" className="nc-icon nc-alert-circle-i"></span>
      <span data-notify="message">FinallyFiltered currently only supports the Chrome browser on a desktop computer. You can still use this browser to edit your profile and billing, but you will not be able to watch any videos.</span>
    </Alert>,
};

const links = {
  youtube: 'https://www.youtube.com/watch?v=',
  netflix: 'https://www.netflix.com/watch/',
  amazon: 'https://www.amazon.com/gp/video/detail/',
  hulu: 'https://www.hulu.com/watch/',
  peacock: 'https://www.peacocktv.com/watch/playback/vod/',
  vudu: 'https://www.vudu.com/content/movies/play/',
  moviesanywhere: 'https://moviesanywhere.com/movie/',
  disney: 'https://www.disneyplus.com/video/',
}

const thead = ["Series", "Season", "Episode", "Title", "# Filters", "Available", "Unavailable", "Outdated", "Draft", "Actions", "ID"];
const tbody = [
  {
    className: "",
    data: ["title", "status", "filters", "watch", "sources", "actions", "id"]
  },
];

var fileContents;
var regex;
var allReports;
var allReportsCount;

function getOffersList(offers) {

  var offersList = [];

  for (var i = 0; i < offers.length; i++) {

    var tmpOffer = offers[i];
    var offersItem = {
      lastprocessed: null,
      platform: null,
      type: null,
      added: null,
      changed: null,
      price: null,
      presentation: null,
      seasons: null,
    };

    var currentDate = new Date();
    var currentYear = currentDate.getFullYear().toString();
    var currentMonth = (currentDate.getMonth() + 1).toString();
    if (currentMonth.length === 1) { currentMonth = '0' + currentMonth; }
    var currentDay = currentDate.getDate().toString();
    if (currentDay.length === 1) { currentDay = '0' + currentDay; }
    var currentDateString = currentYear + '-' + currentMonth + '-' + currentDay;

    // netflix
      // 8 = netflix = nfx
      // 175 = netflixkids = nfk
    if (tmpOffer.provider_id === 8 || tmpOffer.provider_id === 175) {
      offersItem.lastprocessed = currentDateString;
      offersItem.platform = 'netflix';
      offersItem.type = tmpOffer.monetization_type;
      offersItem.added = tmpOffer.date_created;
      if (tmpOffer.retail_price) {
        offersItem.price = tmpOffer.retail_price;
      }
      if (tmpOffer.last_change_date) {
        offersItem.changed = tmpOffer.last_change_date;
      }
      if (tmpOffer.presentation_type) {
        offersItem.presentation = tmpOffer.presentation_type;
      }
      if (tmpOffer.new_element_count) {
        offersItem.seasons = tmpOffer.new_element_count;
      }
    }

    // amazon
      // 9 = amazonprime = amp
      // 10 = amazon = amz
    if (tmpOffer.provider_id === 9 || tmpOffer.provider_id === 10) {
      offersItem.lastprocessed = currentDateString;
      offersItem.platform = 'amazon';
      offersItem.type = tmpOffer.monetization_type;
      offersItem.added = tmpOffer.date_created;
      if (tmpOffer.retail_price) {
        offersItem.price = tmpOffer.retail_price;
      }
      if (tmpOffer.last_change_date) {
        offersItem.changed = tmpOffer.last_change_date;
      }
      if (tmpOffer.presentation_type) {
        offersItem.presentation = tmpOffer.presentation_type;
      }
      if (tmpOffer.new_element_count) {
        offersItem.seasons = tmpOffer.new_element_count;
      }
    }

    // hulu
      // 15 = hulu = hlu
    if (tmpOffer.provider_id === 15) {
      offersItem.lastprocessed = currentDateString;
      offersItem.platform = 'hulu';
      offersItem.type = tmpOffer.monetization_type;
      offersItem.added = tmpOffer.date_created;
      if (tmpOffer.retail_price) {
        offersItem.price = tmpOffer.retail_price;
      }
      if (tmpOffer.last_change_date) {
        offersItem.changed = tmpOffer.last_change_date;
      }
      if (tmpOffer.presentation_type) {
        offersItem.presentation = tmpOffer.presentation_type;
      }
      if (tmpOffer.new_element_count) {
        offersItem.seasons = tmpOffer.new_element_count;
      }
    }

    // disney
      // 337 = disneyplus = dnp
    if (tmpOffer.provider_id === 337) {
      offersItem.lastprocessed = currentDateString;
      offersItem.platform = 'disney';
      offersItem.type = tmpOffer.monetization_type;
      offersItem.added = tmpOffer.date_created;
      if (tmpOffer.retail_price) {
        offersItem.price = tmpOffer.retail_price;
      }
      if (tmpOffer.last_change_date) {
        offersItem.changed = tmpOffer.last_change_date;
      }
      if (tmpOffer.presentation_type) {
        offersItem.presentation = tmpOffer.presentation_type;
      }
      if (tmpOffer.new_element_count) {
        offersItem.seasons = tmpOffer.new_element_count;
      }
    }

    // youtube
      // 192 = youtube = yot
      // 188 = youtubered = ytr
      // 235 = youtubefree = yfr
    if (tmpOffer.provider_id === 192 || tmpOffer.provider_id === 188 || tmpOffer.provider_id === 235) {
      offersItem.lastprocessed = currentDateString;
      offersItem.platform = 'youtube';
      offersItem.type = tmpOffer.monetization_type;
      offersItem.added = tmpOffer.date_created;
      if (tmpOffer.retail_price) {
        offersItem.price = tmpOffer.retail_price;
      }
      if (tmpOffer.last_change_date) {
        offersItem.changed = tmpOffer.last_change_date;
      }
      if (tmpOffer.presentation_type) {
        offersItem.presentation = tmpOffer.presentation_type;
      }
      if (tmpOffer.new_element_count) {
        offersItem.seasons = tmpOffer.new_element_count;
      }
    }

    // Add it to our offer list
    if (offersItem.platform && offersItem.type && offersItem.added) {
      offersList.push(offersItem);
    }
  }

  return offersList;
}

function checkBadWords(fileJson) {

  var allFilters = [];

  // Loop through each item individually
  fileJson.forEach(function(value, index, array) {

    var returnVal = value;

    // For some reason, the regex doesn't like to work twice in a row, so we run two tests and if one passes, it's a match
    var testOne = (
      regex.test(returnVal.text) ||
      returnVal.text.toLowerCase().match(map(badPhrases1, 'phrase').join('|')) ||
      returnVal.text.toLowerCase().match(map(badPhrases2, 'phrase').join('|')) ||
      returnVal.text.toLowerCase().match(map(badPhrases3, 'phrase').join('|'))
    );
    var testTwo = (
      regex.test(returnVal.text) ||
      returnVal.text.toLowerCase().match(map(badPhrases1, 'phrase').join('|')) ||
      returnVal.text.toLowerCase().match(map(badPhrases2, 'phrase').join('|')) ||
      returnVal.text.toLowerCase().match(map(badPhrases3, 'phrase').join('|'))
    );

    // See if this line cusses (also some one-off phrases are tested here)
    if (testOne || testTwo) {

      // Loop through all possible bad words to see which one this really matches
      var replacedString = returnVal.text;
      var badTypes = [];
      var thisSeverity = 0;
      for (var j = 0; j < badWords.length; j++) {

        // Grab all of the swear word matches
        var theMatches1 = replacedString.toLowerCase().match(badWords[j].variations.join('|'));

        // If we got a match (we should) handle it
        if (theMatches1) {
          var re1 = new RegExp(theMatches1[0], 'gi');
          replacedString = replacedString.replace(re1, theMatches1[0][0] + '*'.repeat(theMatches1[0].length - 1));
          var pushBadTypesItem = `${badWords[j].type} (${badWords[j].word[0] + '*'.repeat(badWords[j].word.length - 1)})`;
          if (badWords[j].replacement) {
            pushBadTypesItem = badWords[j].replacement;
          }
          badTypes.push(pushBadTypesItem);
          if (badWords[j].severity > thisSeverity) {
            thisSeverity = badWords[j].severity;
          }
        }
      }
      for (var k = 0; k < badPhrases1.length; k++) {

        var badPhrases1Phrase = badPhrases1[k].phrase;
        var badPhrases1Description = badPhrases1[k].description;

        // Grab all of the bad phrase matches
        var theMatches2 = replacedString.toLowerCase().match(badPhrases1Phrase);

        // If we got a match (we should) handle it
        if (theMatches2) {
          var re2 = new RegExp(theMatches2[0], 'gi');
          replacedString = replacedString.replace(re2, theMatches2[0][0] + '*'.repeat(theMatches2[0].length - 1));
          //badTypes.push(`Inappropriate (${replacedString.replace('<br />', ' ')})`);
          badTypes.push(badPhrases1Description);
          if (1 > thisSeverity) {
            thisSeverity = 1;
          }
        }
      }
      for (var k = 0; k < badPhrases2.length; k++) {

        var badPhrases2Phrase = badPhrases2[k].phrase;
        var badPhrases2Description = badPhrases2[k].description;

        // Grab all of the bad phrase matches
        if (badPhrases2Phrase === '\\[bleep\\]') {
          badPhrases2Phrase = 'bleep';
        }
        var theMatches2 = replacedString.toLowerCase().match(badPhrases2Phrase);

        // If we got a match (we should) handle it
        if (theMatches2) {
          var re2 = new RegExp(theMatches2[0], 'gi');
          if (badPhrases2Phrase !== 'bleep') {
            replacedString = replacedString.replace(re2, theMatches2[0][0] + '*'.repeat(theMatches2[0].length - 1));
          }
          //badTypes.push(`Inappropriate (${replacedString.replace('<br />', ' ')})`);
          badTypes.push(badPhrases2Description);
          if (2 > thisSeverity) {
            thisSeverity = 2;
          }
        }
      }
      for (var k = 0; k < badPhrases3.length; k++) {

        var badPhrases3Phrase = badPhrases3[k].phrase;
        var badPhrases3Description = badPhrases3[k].description;

        // Grab all of the bad phrase matches
        var theMatches2 = replacedString.toLowerCase().match(badPhrases3Phrase);

        // If we got a match (we should) handle it
        if (theMatches2) {
          var re2 = new RegExp(theMatches2[0], 'gi');
          replacedString = replacedString.replace(re2, theMatches2[0][0] + '*'.repeat(theMatches2[0].length - 1));
          //badTypes.push(`Inappropriate (${replacedString.replace('<br />', ' ')})`);
          badTypes.push(badPhrases3Description);
          if (3 > thisSeverity) {
            thisSeverity = 3;
          }
        }
      }
      returnVal.severity = thisSeverity;
      returnVal.description = badTypes.join(', ');
      returnVal.string = replacedString;
      returnVal.action = 'mute';
      returnVal.type = 'language';
      returnVal.start = (returnVal.start).toFixed(2);
      returnVal.end = (returnVal.end).toFixed(2);

      delete returnVal.text;

      allFilters.push(returnVal);
    }
  });

  return allFilters;
}

// Every single bad word we are going to be checking
// You only have to put the regular variation in there -- not the space or hyphenated versions
// For example, put in "damn" and "dammit", but no need to put in "damn it" or "damn-it"
// Also put in any plural versions
var badWords = [
  {
    word: 'hell',
    type: 'Mild obscenity',
    variations: [ 'hell', 'helluva', 'hellofa', 'hellsno', 'hellno' ],
    severity: 3,
    replacement: 'he**',
  },
  {
    word: 'god',
    type: 'Religious profanity',
    variations: [ 'god', 'goddamn', 'goddamnit', 'goddammit' ],
    severity: 3,
    replacement: 'G**',
  },
  {
    word: 'jesus',
    type: 'Religious profanity',
    variations: [ 'jesus', 'jeez' ],
    severity: 3,
    replacement: 'Jes**',
  },
  {
    word: 'christ',
    type: 'Religious profanity',
    variations: [ 'christ' ],
    severity: 3,
    replacement: 'Chr***',
  },
  {
    word: 'lord',
    type: 'Religious profanity',
    variations: [ 'lord' ],
    severity: 3,
    replacement: 'Lo**',
  },
  {
    word: 'damn',
    type: 'Mild obscenity',
    variations: [ 'damn', 'dammit', 'damned', 'damnit' ],
    severity: 3,
    replacement: 'da**',
  },
  {
    word: 'bloody',
    type: 'Mild obscenity',
    variations: [ 'bloody', 'bloodyhell' ],
    severity: 2,
  },
  {
    word: 'fuck',
    type: 'F-word',
    variations: [ 'fuck', 'fucks', 'fucked', 'fucker', 'fuckers', 'fucking', 'fuckin', 'motherfucking', 'motherfuck', 'motherfucker', 'motherfuckers' ],
    severity: 5,
    replacement: 'F-word',
  },
  {
    word: 'frickin',
    type: 'F-word replacement',
    variations: [ 'frickin', 'friggin' ],
    severity: 2,
    replacement: 'F-word replacement',
  },
  {
    word: 'sex',
    type: 'Sexual Reference',
    variations: [ 'sex', 'sexual', 'sexuality', 'sexting', 'intercourse' ],
    severity: 3,
    replacement: 's**',
  },
  {
    word: 'pervert',
    type: 'Sexual Reference',
    variations: [ 'pervert', 'perverts', 'perv', 'pervy' ],
    severity: 2,
    replacement: 'perv***',
  },
  {
    word: 'masturbate',
    type: 'Vulgarity',
    variations: [ 'masturbate', 'masturbates', 'masturbating', 'masturbation' ],
    severity: 4,
  },
  {
    word: 'makeout',
    type: 'Sexual Reference',
    variations: [ 'makeout', 'makeouts', 'frenching' ],
    severity: 3,
    replacement: 'A reference is made to making out',
  },
  {
    word: 'blowjob',
    type: 'Vulgarity',
    variations: [ 'blowjob', 'bj', 'bjs', 'blowjobs' ],
    severity: 5,
  },
  {
    word: 'condom',
    type: 'Sexual Reference',
    variations: [ 'condom', 'condoms' ],
    severity: 3,
  },
  {
    word: 'orgasm',
    type: 'Sexual Reference',
    variations: [ 'orgasm', 'orgasms', 'orgasmic', 'orgies', 'orgy', 'floorgasm' ],
    severity: 4,
  },
  {
    word: 'aroused',
    type: 'Sexual Reference',
    variations: [ 'aroused', 'arousal', 'arousing' ],
    severity: 3,
  },
  {
    word: 'virgin',
    type: 'Sexual Reference',
    variations: [ 'virgin', 'virgins', 'virginity' ],
    severity: 3,
  },
  {
    word: 'horny',
    type: 'Sexual Reference',
    variations: [ 'horny', 'horndog' ],
    severity: 4,
  },
  {
    word: 'aphrodisiac',
    type: 'Sexual Reference',
    variations: [ 'aphrodisiac', 'aphrodisiacs' ],
    severity: 3,
  },
  {
    word: 'shit',
    type: 'Scatological',
    variations: [ 'shit', 'shits', 'shitty', 'shitter', 'shitters', 'shitting', 'shitless', 'shithead', 'shitheads', 'shitface', 'shitfaces', 'shitfaced', 'shithole', 'shitholes', 'shitsplat', 'bullshit', 'bullshitted', 'bullshitting', 'horseshit', 'dipshit', 'shitbag', 'shitbags', 'shitwack', 'chickenshit' ],
    severity: 5,
    replacement: 'S-word',
  },
  {
    word: 'piss',
    type: 'Scatological',
    variations: [ 'piss', 'pissoff', 'pisser', 'pisses', 'pissed', 'pissing', 'pissedoff' ],
    severity: 3,
  },
  {
    word: 'cum',
    type: 'Scatological',
    variations: [ 'cum', 'cumbag', 'cumbags' ],
    severity: 4,
  },
  {
    word: 'jizz',
    type: 'Scatological',
    variations: [ 'jizz', 'jizzing', 'jizzed', 'jizzes' ],
    severity: 4,
  },
  {
    word: 'douche',
    type: 'Scatological',
    variations: [ 'douche', 'douchebag', 'douchebags' ],
    severity: 3,
  },
  {
    word: 'vagina',
    type: 'Scatological',
    variations: [ 'vagina', 'vaginas', 'vaginal' ],
    severity: 3,
  },
  {
    word: 'pussy',
    type: 'Scatological',
    variations: [ 'pussy', 'puss', 'pussies' ],
    severity: 4,
  },
  {
    word: 'twat',
    type: 'Scatological',
    variations: [ 'twat', 'twats' ],
    severity: 5,
  },
  {
    word: 'penis',
    type: 'Scatological',
    variations: [ 'penis', 'penises', 'penishead', 'penisheads', 'dingus' ],
    severity: 3,
  },
  {
    word: 'ass',
    type: 'Anatomical',
    variations: [ 'ass', 'asshole', 'asses', 'assholes', 'asswipe', 'asswipes', 'asshat', 'asshats', 'arse', 'arses', 'arsehole', 'arseholes', 'badass', 'jackass', 'jackassing', 'jackasses', 'a\-hole', 'a\-holes', 'dumbass', 'wiseass' ],
    severity: 4,
    replacement: 'a**',
  },
  {
    word: 'balls',
    type: 'Anatomical',
    variations: [ 'balls', 'ballsack', 'ballsacks', 'bollock', 'bollocks', 'scrotum', 'teste', 'testes', 'testical', 'testicals' ],
    severity: 3,
  },
  {
    word: 'dick',
    type: 'Anatomical',
    variations: [ 'dick', 'dicks', 'dickhead', 'dickheads', 'dickhole', 'dickholes', 'dickweed', 'dickweeds', 'dicking', 'prick' ],
    severity: 4,
  },
  {
    word: 'wanker',
    type: 'Anatomical',
    variations: [ 'wanker', 'wankers', 'wanked', 'wanks' ],
    severity: 3,
  },
  {
    word: 'cock',
    type: 'Anatomical',
    variations: [ 'cock', 'cocksucker', 'cocksuckers', 'cocktease', 'cockteases', 'cockteasing', 'cockblock', 'cockblocked', 'cockblocks', 'cockblocker', 'cockblockers' ],
    severity: 4,
  },
  {
    word: 'tit',
    type: 'Anatomical',
    variations: [ 'tit', 'tits', 'titties', 'titty' ],
    severity: 4,
  },
  {
    word: 'boob',
    type: 'Anatomical',
    variations: [ 'boob', 'boobs', 'boobies', 'nipple', 'nipples' ],
    severity: 3,
  },
  {
    word: 'cunt',
    type: 'Anatomical',
    variations: [ 'cunt', 'cunts' ],
    severity: 4,
  },
  {
    word: 'bitch',
    type: 'Derogatory',
    variations: [ 'bitch', 'bitches', 'bitching', 'bitchin', 'bitched', 'bitchy', 'biatch', 'biatches', 'biotch', 'biotches' ],
    severity: 4,
    replacement: 'bit**',
  },
  {
    word: 'bastard',
    type: 'Derogatory',
    variations: [ 'bastard', 'bastards', 'bastardz' ],
    severity: 4,
  },
  {
    word: 'retard',
    type: 'Derogatory',
    variations: [ 'retard', 'retards', 'retarded' ],
    severity: 3,
  },
  {
    word: 'nigger',
    type: 'Derogatory',
    variations: [ 'nigger', 'niggers', 'niggerz', 'niggas' ],
    severity: 4,
  },
  {
    word: 'beaner',
    type: 'Derogatory',
    variations: [ 'beaner', 'wetback' ],
    severity: 3,
  },
  {
    word: 'fag',
    type: 'Derogatory',
    variations: [ 'fag', 'faggot', 'faggots' ],
    severity: 3,
    replacement: 'A derogatory word is used to describe someone who is gay',
  },
  {
    word: 'gay',
    type: 'Sexual Reference',
    variations: [ 'gay', 'gays' ],
    severity: 2,
    replacement: 'g*y',
  },
  {
    word: 'lesbian',
    type: 'Sexual Reference',
    variations: [ 'lesbian', 'lesbians' ],
    severity: 2,
    replacement: 'les**an',
  },
  {
    word: 'queer',
    type: 'Derogatory',
    variations: [ 'queer', 'queerbate', 'queers', 'queerbates' ],
    severity: 3,
    replacement: 'qu**r',
  },
  {
    word: 'jerkoff',
    type: 'Derogatory',
    variations: [ 'jerkoff', 'jerkoffs', 'jackoff', 'jackoffs' ],
    severity: 3,
  },
  {
    word: 'tosser',
    type: 'Derogatory',
    variations: [ 'tosser', 'tossers' ],
    severity: 3,
  },
  {
    word: 'whore',
    type: 'Derogatory',
    variations: [ 'whore', 'whores', 'whoring', 'whored' ],
    severity: 3,
    replacement: 'A word is used to describe a prostitute',
  },
  {
    word: 'slut',
    type: 'Derogatory',
    variations: [ 'slut', 'sluts', 'slutting', 'slutted', 'slutty', 'prostitue', 'prostitutes', 'prostitution', 'hooker', 'hookers' ],
    severity: 3,
    replacement: 'A word is used to describe a prostitute',
  },
  {
    word: 'ecstasy',
    type: 'Drugs',
    variations: [ 'ecstasy' ],
    severity: 1,
    replacement: 'The drug ecstasy is mentioned',
  },
];

// Severity 1
var badPhrases1 = [
  {
    phrase: 'shut up',
    description: 'Shut up',
  },
  {
    phrase: 'brown nos',
    description: 'Brown nosing is mentioned',
  },
  {
    phrase: 'brown-nos',
    description: 'Brown nosing is mentioned',
  }
];

// Severity 2
var badPhrases2 = [
  {
    phrase: 'screw you',
    description: 'Screw',
  },
  {
    phrase: 'make out',
    description: 'Making out is mentioned',
  },
  {
    phrase: 'made out',
    description: 'Making out is mentioned',
  },
  {
    phrase: 'make-out',
    description: 'Making out is mentioned',
  },
  {
    phrase: 'french kiss',
    description: 'Making out is mentioned',
  },
  {
    phrase: '\\[bleep\\]',
    description: 'A swear word is bleeped out',
  },
];

// Severity 3
var badPhrases3 = [
  {
    phrase: 'sadfdsaf sdafsadfsdafasdf',
    description: 'Just a test',
  },
];

class RegularTables extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      tbody: [],
      hasExtension: false,
      imdbMovies: null,
      imdbMovie: {},
      imdbMovieDetails: {},
      imdbMovieSeriesSeason: '',
      getSwears: false,
      toggleSectionVideosNew: true,
      toggleSectionVideosWorking: true,
      toggleSectionVideosReported: true,
      toggleSectionVideosVerified: false,
      toggleSectionVideosPrime: false,
      imdbMovieSeriesEpisode: '',
      imdbMovieSeriesName: '',
      defaultFilters: [],
      defaultFiltersText: '',
      selectedFilters: [],
      selectedFiltersFull: [],
      filterTitle: '',
      filterTitleReal: '',
      filterSource: '',
      filterId: '',
      modalFilterDel: false,
      modalSourceAdd: false,
      modalSourceDel: false,
      modalPromote: false,
      modalData: null,
      modalDataItem: null,
      modalType: '',
      sourceName: '',
      sourceSubscription: '',
      sourceId: '',
      sourceUrl: '',
      whichSrt: 0,
      currentUser: null,
    };

    this.toggleExtension = this.toggleExtension.bind(this);
    this.handleMovieSelect = this.handleMovieSelect.bind(this);
    this.handleMovieSelectPre = this.handleMovieSelectPre.bind(this);
    this.handleFilterSelect = this.handleFilterSelect.bind(this);
    this.getSourceDetails = this.getSourceDetails.bind(this);
    this.getMovieDetails = this.getMovieDetails.bind(this);
    this.populateMovieTitles = this.populateMovieTitles.bind(this);
    this.getMovieCusses = this.getMovieCusses.bind(this);
    this.populateSeriesDetails = this.populateSeriesDetails.bind(this);
    this.getMovieSubmit = this.getMovieSubmit.bind(this);
    this.toggleSectionVideosNew = this.toggleSectionVideosNew.bind(this);
    this.toggleSectionVideosWorking = this.toggleSectionVideosWorking.bind(this);
    this.toggleSectionVideosReported = this.toggleSectionVideosReported.bind(this);
    this.toggleSectionVideosVerified = this.toggleSectionVideosVerified.bind(this);
    this.toggleSectionVideosPrime = this.toggleSectionVideosPrime.bind(this);
    this.handleSeriesSeasonChange = this.handleSeriesSeasonChange.bind(this);
    this.handleSeriesEpisodeChange = this.handleSeriesEpisodeChange.bind(this);
    this.handleSeriesSwearsChange = this.handleSeriesSwearsChange.bind(this);
    this.handleSeriesNameChange = this.handleSeriesNameChange.bind(this);
    this.handleTitleChange = this.handleTitleChange.bind(this);
    this.handleSourceChange = this.handleSourceChange.bind(this);
    this.handleIdChange = this.handleIdChange.bind(this);
    this.handleSourceSubscriptionChange = this.handleSourceSubscriptionChange.bind(this);
    this.handleSourceNameChange = this.handleSourceNameChange.bind(this);
    this.handleSourceIdChange = this.handleSourceIdChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.preToggleModal = this.preToggleModal.bind(this);
    this.reserveVideo = this.reserveVideo.bind(this);
    this.unreserveVideo = this.unreserveVideo.bind(this);
    this.deleteFilter = this.deleteFilter.bind(this);
    this.promoteFilter = this.promoteFilter.bind(this);
    this.demoteFilter = this.demoteFilter.bind(this);
    this.addSource = this.addSource.bind(this);
    this.deleteSource = this.deleteSource.bind(this);
    this.promoteSource = this.promoteSource.bind(this);
    this.availableSource = this.availableSource.bind(this);
    this.unavailableSource = this.unavailableSource.bind(this);
    this.changeUnmountOnClose = this.changeUnmountOnClose.bind(this);
  }

  // When they click a filter
  handleFilterSelect(event) {

    // Loop through the options
    var allValues = [];
    var allValuesFull = [];
    for (var i = 0; i < event.target.options.length; i++) {

      // If this one is selected
      if (event.target.options[i].selected) {

        // Add to the array
        allValues.push(event.target.options[i].value);

        // Now find the equivalent defaultFilters item to add to our other array
        var filterToAdd = find(this.state.defaultFilters, function(o) {
          // The '==' instead of '===' is explicit here
          return o.id == event.target.options[i].value;
        })
        if (filterToAdd) {

          // Make a copy
          var newFilter = Object.assign({}, filterToAdd);
          delete newFilter.id;
          delete newFilter.string;
          allValuesFull.push(newFilter);
        }
      }
    }

    this.setState({
      selectedFilters: allValues,
      selectedFiltersFull: allValuesFull,
    });
  }

  // If they want a new filter set
  handleMovieSelectPre() {
    // Set the state
    this.setState({
      whichSrt: this.state.whichSrt + 1,
    }, function() {
      this.handleMovieSelect(this.state.whichSrt);
    });
  }

  // When they click a specific movie
  handleMovieSelect(event) {

    // If event is set, we came here on a click of a movie title, otherwise, from an increment
    var imdbMovie;
    var incremented = false;
    if (event && event.target && event.target.value) {
      imdbMovie = event.target.value;
    } else {
      imdbMovie = this.state.imdbMovie;
      incremented = true;
    }

    // Set the state
    this.setState({
      imdbMovie: imdbMovie,
    }, function() {

      var movieId = this.state.imdbMovie;
      var movieDetails;

      // Get movie info
      omdb.get({
        id: movieId,
      }).then(res => {

        movieDetails = res;

        // If we have an IMDB movie ID, we can get all of the movie info and a set of subtitles
        console.log('This is the full movie details: ', res);

        // Special case for handling a series episode
        if (res.type === 'series') {
          return 'series';
        }

        // TODO: We may want to handle other special cases here, or if we discover that we can get subtitles for series or YouTube videos, etc. then we can do that here
        else {

          const OS = require('opensubtitles-api');
          const OpenSubtitles = new OS({
            useragent:'UserAgent',
            ssl: false
          });
          return OpenSubtitles.search({
            imdbid: movieId.replace('tt', ''),
            sublanguageid: 'en',
            //gzip: true,
            //limit: '3',
          });

          //var serviceUrl = `https://subtitle-api.org/videos/${movieId}/subtitles?lang=en`;
          //var serviceUrl = `https://rest.opensubtitles.org/search/imdbid-${movieId.replace('tt', '')}/sublanguageid-eng`;
          //console.log('Calling: ', serviceUrl);
          //return fetch(serviceUrl, {
          //  method: 'GET',
          //  headers: {
          //    'X-User-Agent': 'TemporaryUserAgent',
          //  }
          //});
        }
      })
      .then(res => {

        // Special case for series
        if (res === 'series') {
          return 'series';
        } else {
          console.log('##### ONE #####', res.en);
          return res.en;
        }
      })
      .then(res => {

        // Special case for series
        if (res === 'series') {
          return 'series';
        } else {
          console.log('##### TWO #####', res);

          //console.log('Results:', res.length);

          var foundItem = res;

          // Loop to get the correct SRT file
          //var numItems = 0;
          //var foundItem = null;
          //for (var item of res) {
          //  if (item.SubFormat === 'srt') {
          //    if (numItems === this.state.whichSrt) {
          //      foundItem = item;
          //      break;
          //    }
          //    numItems++;
          //  }
          //}

          // If we didn't get one, then just grab the first one
          //if (foundItem === null) {
          //  for (var item of res) {
          //    if (item.SubFormat === 'srt') {
          //      foundItem = item;
          //      break;
          //    }
          //  }
          //}

          //var replaceLink = foundItem.SubDownloadLink.replace('.gz', '.srt');
          var replaceLink = foundItem.url;
          console.log('Found item with score: ' + foundItem.Score, replaceLink);

          var rp = require('request-promise');

          return rp(replaceLink);

          //var serviceUrl = `https://subtitle-api.org/videos/${movieId}/subtitles/${res.best.id}?preview=true`;
          //return fetch(replaceLink, {
          //  method: 'GET',
          //  headers: {
          //    'X-User-Agent': 'TemporaryUserAgent',
          //  }
          //});
        }
      })
      .then(function(res) {

        // Special case for series
        if (res === 'series') {
          return 'series';
        } else {
          return res;
        }
      })
      .then(res => {

        // Special case for series
        if (res === 'series') {
          this.setState({
            imdbMovieDetails: movieDetails,
            imdbMovieSeriesSeason: '',
            getSwears: false,
            imdbMovieSeriesEpisode: '',
            imdbMovieSeriesName: '',
            filterTitle: movieDetails.title,
            filterTitleReal: null,
            defaultFilters: [],
            defaultFiltersText: '',
            selectedFilters: [],
            selectedFiltersFull: [],
          });
        } else {

          // Put all variations into an array
          var putTogetherAlpha = [];
          var putTogetherAll = [];
          for (var i = 0; i < badWords.length; i++) {
            for (var j = 0; j < badWords[i].variations.length; j++) {
              putTogetherAlpha.push(badWords[i].variations[j]);
              putTogetherAll.push('[^a-z](' + badWords[i].variations[j] + ')[^a-z]');
              putTogetherAll.push('^(' + badWords[i].variations[j] + ')[^a-z]');
              putTogetherAll.push('[^a-z](' + badWords[i].variations[j] + ')$');
            }
          }
          regex = new RegExp(putTogetherAll.join('|'), 'gi');

          //console.log('REG EX: ' + regex + '...');

          // Grab the entire file into a string
          fileContents = res;

          //console.log('Contents', fileContents);

          // Parse it into a json object
          var fileJson = parseSRT(fileContents);

          var allFilters = checkBadWords(fileJson);

          if (incremented) {
            document.getElementById('filterDefaultText').value = fileContents;
          }

          this.setState({
            imdbMovieDetails: movieDetails,
            imdbMovieSeriesSeason: '',
            getSwears: false,
            imdbMovieSeriesEpisode: '',
            imdbMovieSeriesName: '',
            filterTitleReal: movieDetails.title,
            defaultFilters: allFilters,
            defaultFiltersText: fileContents,
          });
        }
      })
      .catch(err => {
        console.log('ERROR: Could not get movie info (1).', err);
        this.setState({
          imdbMovieDetails: {},
          imdbMovieSeriesSeason: '',
          getSwears: false,
          imdbMovieSeriesEpisode: '',
          imdbMovieSeriesName: '',
          defaultFilters: [],
          defaultFiltersText: '',
          selectedFilters: [],
          selectedFiltersFull: [],
        });
      });
    });
  }

  // Gets movie cusses
  getMovieCusses = (obj) => {

    var returnVals = [];
    mapValues(obj, function(o) {
      returnVals.push(<option key={o.id} value={o.id}>{o.string}</option>);
    });

    if (returnVals.length) {
      return (
        <div>
          <FormGroup>
            <Label for="filterSelect">Pre-set Swear Filters (Ctrl/Cmd select all that you want to add)</Label>
            <Input type="select" name="filterDefault" id="filterDefault" multiple size="18" onChange={this.handleFilterSelect} value={this.state.selectedFilters}>
              {returnVals}
            </Input>
            <Label onClick={this.handleMovieSelectPre}>Get next filter file</Label>
          </FormGroup>
          <p>Here is the full transcript in case you need a reference:</p>
          <textarea rows='25' cols='100' name="filterDefaultText" id="filterDefaultText">{this.state.defaultFiltersText}</textarea>
        </div>
      );
    } else if (this.state.defaultFiltersText === '') {
      return (
        <span></span>
      );
    } else {
      return (
        <div>
          <p>There were no swear words detected in this movie! Review the transcript below to be sure.</p>
          <textarea rows='25' cols='100' name="filterDefaultText" id="filterDefaultText">{this.state.defaultFiltersText}</textarea>
        </div>
      );
    }
  }

  // Provides input fields for a series season, episode and episode name
  populateSeriesDetails = () => {

    if (this.state.imdbMovieDetails && this.state.imdbMovieDetails.type === 'series') {
      return (
        <FormGroup>
          <Input type="checkbox" name="getSwears" id="getSwears" style={{ marginLeft: '10px' }} onChange={this.handleSeriesSwearsChange} value={this.state.getSwears} />
          <Label class="form-check-label" for="getSwears" style={{ marginLeft: '30px' }}>Check this box to add pre-set swear filters using a subtitles file</Label>
          <br />
          <Label for="seriesSeason">Season number</Label>
          <Input type="text" name="seriesSeason" id="seriesSeason" onChange={this.handleSeriesSeasonChange} value={this.state.imdbMovieSeriesSeason} />
          <Label for="seriesEpisode">Episode number</Label>
          <Input type="text" name="seriesEpisode" id="seriesEpisode" onChange={this.handleSeriesEpisodeChange} value={this.state.imdbMovieSeriesEpisode} />
          <Label for="seriesName">Episode name</Label>
          <Input type="text" name="seriesName" id="seriesName" onChange={this.handleSeriesNameChange} value={this.state.imdbMovieSeriesName} />
        </FormGroup>
      );
    } else {
      return (
        <span></span>
      );
    }
  }

  // Get the movie submit button
  getMovieSubmit = () => {

    // If we have all the right info
    if (
      this.state.sourceId // Always required
      && this.state.sourceName // Always required
      && (this.state.filterTitle || this.state.filterTitleReal) // Some kind of title must be set
      && (
        // It either needs to not be a series or movie
        !this.state.imdbMovieDetails.type ||
        // OR it is a movie
        this.state.imdbMovieDetails.type === 'movie' ||
        // OR it is a series AND all 3 values are set
        (
          this.state.imdbMovieDetails.type === 'series' &&
          this.state.imdbMovieSeriesSeason &&
          this.state.imdbMovieSeriesEpisode &&
          this.state.imdbMovieSeriesName
        )
      )
    ) {
      return (
        <Row>
          <div className="update ml-auto mr-auto">
            <Button color="primary" round>Submit</Button>
          </div>
        </Row>
      );
    }

    // Otherwise
    else {
      return (
        <Row>
          <div className="update ml-auto mr-auto">
            <Button color="secondary" round disabled>Submit</Button>
          </div>
        </Row>
      );
    }
  }

  // Populates movie titles in the dropdown
  populateMovieTitles = () => {

    var returnVals = [];
    if (this.state.imdbMovies !== null) {
      mapValues(this.state.imdbMovies, function(o) {
        returnVals.push(<option key={o.imdbid} value={o.imdbid}>{o.type.charAt(0).toUpperCase() + o.type.substr(1) + ':  ' + o.title + ' (' + o.year + ')'}</option>);
      });
    }

    // If there are actually movies to list
    if (returnVals.length) {
      return (
        <FormGroup>
          <Label for="filterSelect">IMDB Video (if it is not a movie or a series, don't select anything here, just submit)</Label>
          <Input type="select" name="filterSelect" id="filterSelect" onChange={this.handleMovieSelect} value={this.state.imdbMovie}>
            <option value=''>Select a Movie or Series</option>
            {returnVals}
          </Input>
          {this.getMovieDetails(this.state.imdbMovieDetails)}
        </FormGroup>
      );
    }

    // If a title was typed in but we are still searching (no results YET)
    else if (this.state.filterTitle && this.state.imdbMovies === null) {
      return (
        <span><Spinner coolor="primary" /></span>
      );
    }

    // If a title was typed in but there were no results
    else if (this.state.filterTitle) {
      return (
        <span>No IMDB results</span>
      );
    }

    // If no title was even entered yet
    else {
      return (
        <span></span>
      );
    }
  }

  // Waits until the user stops typing and then does the search
  doMovieTitleSearch = debounce(
    () => {

      // Do a search
      if (this.state.filterTitle === '') {
        this.setState({
          imdbMovies: [],
          imdbMovieDetails: {},
          imdbMovieSeriesSeason: '',
          getSwears: false,
          imdbMovieSeriesEpisode: '',
          imdbMovieSeriesName: '',
          defaultFilters: [],
          defaultFiltersText: '',
          selectedFilters: [],
          selectedFiltersFull: [],
        });
      } else {

        // Special exceptions
        var tmpFilterId = null;
        if (this.state.filterTitle === 'Hamilton') {
          tmpFilterId = 'tt8503618';
        } else if (this.state.filterTitle === '24') {
          tmpFilterId = 'tt0285331';
        } else if (this.state.filterTitle === 'Alone') {
          tmpFilterId = 'tt4803766';
        }
        if (tmpFilterId !== null) {
          omdb.get({
              id: tmpFilterId,
          }).then(res => {

            console.log('Results: ', res);

            var moviesArray = [];
            moviesArray.push(res);

            this.setState({
              imdbMovies: moviesArray,
              imdbMovieDetails: {},
              imdbMovieSeriesSeason: '',
              getSwears: false,
              imdbMovieSeriesEpisode: '',
              imdbMovieSeriesName: '',
              defaultFilters: [],
              defaultFiltersText: '',
              selectedFilters: [],
              selectedFiltersFull: [],
            });
          }).catch(err => {
            console.log('OMDB returned no results!', err);
            this.setState({
              imdbMovies: [],
              imdbMovieDetails: {},
              imdbMovieSeriesSeason: '',
              getSwears: false,
              imdbMovieSeriesEpisode: '',
              imdbMovieSeriesName: '',
              defaultFilters: [],
              defaultFiltersText: '',
              selectedFilters: [],
              selectedFiltersFull: [],
            });
          });
        } else {
          omdb.search({
            search: this.state.filterTitle,
            page: 1,
          }).then(res => {

            this.setState({
              imdbMovies: res,
              imdbMovieDetails: {},
              imdbMovieSeriesSeason: '',
              getSwears: false,
              imdbMovieSeriesEpisode: '',
              imdbMovieSeriesName: '',
              defaultFilters: [],
              defaultFiltersText: '',
              selectedFilters: [],
              selectedFiltersFull: [],
            });
          }).catch(err => {
            console.log('OMDB returned no results!', err);
            this.setState({
              imdbMovies: [],
              imdbMovieDetails: {},
              imdbMovieSeriesSeason: '',
              getSwears: false,
              imdbMovieSeriesEpisode: '',
              imdbMovieSeriesName: '',
              defaultFilters: [],
              defaultFiltersText: '',
              selectedFilters: [],
              selectedFiltersFull: [],
            });
          });
        }
      }
    }, 750
  );

  // Gets details for a source
  getSourceDetails = (sourceName, sourceId, sourceUrl) => {

    // Output
    if (sourceName && sourceId && sourceUrl) {
      return (
        <div>
          &nbsp;
          Source: <em>{sourceName}</em>
          &nbsp;
          ID: <em>{sourceId}</em>
          &nbsp;
          URL: <em>{sourceUrl}</em>
        </div>
      );
    } else if (sourceName === null) {
      return (
        <div style={{ color: 'red' }}>Error: Invalid source. Please try again.</div>
      );
    } else {
      return (
        <span></span>
      );
    }
  }

  // Gets details for a movie
  getMovieDetails = (movieObject) => {

    // Output
    if (movieObject.poster && movieObject.title) {
      return (
        <div style={posterStyle}>
          <Card>
            <CardImg top width="100%" src={movieObject.poster} alt={"Movie Poster for " + movieObject.title} />
            <CardTitle>{movieObject.title + ' (' + movieObject.type.charAt(0).toUpperCase() + movieObject.type.substr(1) + ': ' + movieObject.year + ')'}</CardTitle>
            <CardSubtitle>{movieObject.production + ' (' +  movieObject.runtime + ' - ' + movieObject.rated + ')'}</CardSubtitle>
            <CardText>{movieObject.plot}</CardText>
          </Card>
        </div>
      );
    } else {
      return (
        <span></span>
      );
    }
  }

  // Toggling sections in the UI
  toggleSectionVideosNew(event) {
    this.setState({
      toggleSectionVideosNew: event.target.checked,
    });
  }
  toggleSectionVideosWorking(event) {
    this.setState({
      toggleSectionVideosWorking: event.target.checked,
    });
  }
  toggleSectionVideosReported(event) {
    this.setState({
      toggleSectionVideosReported: event.target.checked,
    });
  }
  toggleSectionVideosVerified(event) {
    this.setState({
      toggleSectionVideosVerified: event.target.checked,
    });
  }
  toggleSectionVideosPrime(event) {
    this.setState({
      toggleSectionVideosPrime: event.target.checked,
    });
  }

  // Title changed
  handleTitleChange(event) {

    // Set the state
    this.setState({
      filterTitle: event.target.value,
    }, () => {
      this.doMovieTitleSearch();
    });
  }

  // Series season changed
  handleSeriesSeasonChange(event) {

    // Set the state
    this.setState({
      imdbMovieSeriesSeason: event.target.value,
    });
  }

  // Series Swears checkbox changed
  handleSeriesSwearsChange(event) {

    // Set the state
    this.setState({
      getSwears: event.target.checked,
    }, () => {

    });
  }

  // Series episode changed
  handleSeriesEpisodeChange(event) {

    // Set the state
    this.setState({
      imdbMovieSeriesEpisode: event.target.value,
    }, () => {
      if (this.state.getSwears) {
        this.doSeriesEpisodeChange();
      }
    });
  }
  doSeriesEpisodeChange = debounce(
    () => {

      // Only if they have entered a season and episode number
      if (this.state.imdbMovieSeriesSeason && this.state.imdbMovieSeriesEpisode) {

        var movieId = this.state.imdbMovie;
        var movieDetails = this.state.imdbMovieDetails;

        //var serviceUrl = `https://subtitle-api.org/videos/${movieId}/subtitles?lang=en`;
        var serviceUrl = `https://rest.opensubtitles.org/search/episode-${this.state.imdbMovieSeriesEpisode}/imdbid-${movieId.replace('tt', '')}/season-${this.state.imdbMovieSeriesSeason}/sublanguageid-eng`;

        fetch(serviceUrl, {
          method: 'GET',
          headers: {
            'X-User-Agent': 'TemporaryUserAgent',
          }
        })
        .then(res => {

          console.log('ONE: ', res);

          return res.json();
        })
        .then(res => {

          console.log('TWO: ', res);

          // Loop to get the correct SRT file
          var numItems = 0;
          var foundItem = null;
          for (var item of res) {
            if (item.SubFormat === 'srt') {
              if (numItems === this.state.whichSrt) {
                foundItem = item;
                break;
              }
              numItems++;
            }
          }

          // If we didn't get one, then just grab the first one
          if (foundItem === null) {
            for (var item of res) {
              if (item.SubFormat === 'srt') {
                foundItem = item;
                break;
              }
            }
          }

          var replaceLink = foundItem.SubDownloadLink.replace('.gz', '.srt');
          console.log('Found item with score: ' + foundItem.Score + ' from ' + foundItem.UserNickName, replaceLink);

          //var serviceUrl = `https://subtitle-api.org/videos/${movieId}/subtitles/${res.best.id}?preview=true`;
          return fetch(replaceLink, {
            method: 'GET',
            headers: {
              'X-User-Agent': 'TemporaryUserAgent',
            }
          });
        })
        .then(res => {

          console.log('THREE: ', res);

          return res.text();
        })
        .then(res => {

          console.log('FOUR: ', res);

          // Put all variations into an array
          var putTogetherAlpha = [];
          var putTogetherAll = [];
          for (var i = 0; i < badWords.length; i++) {
            for (var j = 0; j < badWords[i].variations.length; j++) {
              putTogetherAlpha.push(badWords[i].variations[j]);
              putTogetherAll.push('[^a-z](' + badWords[i].variations[j] + ')[^a-z]');
            }
          }
          regex = new RegExp(putTogetherAll.join('|'), 'gi');

          // Grab the entire file into a string
          fileContents = res;

          //console.log('Contents', fileContents);

          // Parse it into a json object
          var allFilters = [];
          try {
            var fileJson = parseSRT(fileContents);
            allFilters = checkBadWords(fileJson);
          } catch (error) {
            console.log('There was an error parsing the SRT subtitle file into JSON.', error);
          }

          this.setState({
            imdbMovieDetails: movieDetails,
            imdbMovieSeriesSeason: this.state.imdbMovieSeriesSeason,
            getSwears: this.state.getSwears,
            imdbMovieSeriesEpisode: this.state.imdbMovieSeriesEpisode,
            imdbMovieSeriesName: this.state.imdbMovieSeriesName,
            filterTitleReal: movieDetails.title,
            defaultFilters: allFilters,
            defaultFiltersText: fileContents,
          });
        })
        .catch(err => {
          console.log('ERROR: Could not get movie info (2).', err);
          this.setState({
            imdbMovieDetails: {},
            imdbMovieSeriesSeason: '',
            getSwears: false,
            imdbMovieSeriesEpisode: '',
            imdbMovieSeriesName: '',
            defaultFilters: [],
            defaultFiltersText: '',
            selectedFilters: [],
            selectedFiltersFull: [],
          });
        });
      }
    }, 1000
  );

  // Series episode name changed
  handleSeriesNameChange(event) {

    // Set the state
    this.setState({
      imdbMovieSeriesName: event.target.value,
    });
  }

  // Source changed
  handleSourceChange(event) {

    var url = event.target.value;

    var source;
    var id;
    var full;

    var splitStringOne;
    var splitStringTwo;

    // ===============
    // === Youtube ===
    // ===============
    if (url.match(/^https:\/\/www\.youtube\.com\/watch\?v=(.+)&?/)) {
      splitStringOne = url.split('?v=');
      splitStringTwo = splitStringOne[1].split('&');
      source = 'youtube';
      id = splitStringTwo[0];
      full = `https://www.youtube.com/watch?v=${id}`;
    }

    // ===============
    // === Netflix ===
    // ===============
    else if (url.match(/^https:\/\/www\.netflix\.com\/watch\/(.+)\??/)) {
      splitStringOne = url.split('/watch/');
      splitStringTwo = splitStringOne[1].split('?');
      source = 'netflix';
      id = splitStringTwo[0];
      full = `https://www.netflix.com/watch/${id}`;
    }

    // ==============
    // === Amazon ===
    // ==============
    else if (url.match(/^https:\/\/www\.amazon\.com\/dp\/(.+)\??/)) {
      splitStringOne = url.split('/dp/');
      splitStringTwo = splitStringOne[1].split('?');
      source = 'amazon';
      id = splitStringTwo[0];
      full = `https://www.amazon.com/dp/${id}`;
    }
    else if (url.match(/^https:\/\/www\.amazon\.com\/.+\/dp\/(.+)\??/)) {
      splitStringOne = url.split('/dp/');
      splitStringTwo = splitStringOne[1].split('?');
      source = 'amazon';
      id = splitStringTwo[0];
      full = `https://www.amazon.com/dp/${id}`;
    }
    else if (url.match(/^https:\/\/www\.amazon\.com\/(.+)\/dp\/(.+)\/ref/)) {
      splitStringOne = url.split('/dp/');
      splitStringTwo = splitStringOne[1].split('/ref');
      source = 'amazon';
      id = splitStringTwo[0];
      full = `https://www.amazon.com/dp/${id}`;
    }
    else if (url.match(/^https:\/\/www\.amazon\.com\/gp\/video\/detail\/(.+)\/ref\??/)) {
      splitStringOne = url.split('/detail/');
      splitStringTwo = splitStringOne[1].split('/ref');
      source = 'amazon';
      id = splitStringTwo[0];
      full = `https://www.amazon.com/dp/${id}`;
    }
    else if (url.match(/^https:\/\/www\.amazon\.com\/gp\/video\/detail\/([a-zA-Z0-9]+)$/)) {
      splitStringOne = url.split('/detail/');
      source = 'amazon';
      id = splitStringOne[1];
      full = `https://www.amazon.com/dp/${id}`;
    }

    // ===============
    // === Hulu ===
    // ===============
    else if (url.match(/^https:\/\/www\.hulu\.com\/watch\/(.+)\??/)) {
      splitStringOne = url.split('/watch/');
      splitStringTwo = splitStringOne[1].split('?');
      source = 'hulu';
      id = splitStringTwo[0];
      full = `https://www.hulu.com/watch/${id}`;
    }

    // ===================
    // === Disney Plus ===
    // ===================
    else if (url.match(/^https:\/\/www\.disneyplus\.com\/video\/(.+)\??/)) {
      splitStringOne = url.split('/video/');
      splitStringTwo = splitStringOne[1].split('?');
      source = 'disney';
      id = splitStringTwo[0];
      full = `https://www.disneyplus.com/video/${id}`;
    }

    // ===============
    // === Peacock ===
    // ===============
    else if (url.match(/^https:\/\/www\.peacocktv\.com\/watch\/playback\/vod\/(.+)\??/)) {
      splitStringOne = url.split('/watch\/playback\/vod/');
      splitStringTwo = splitStringOne[1].split('?');
      source = 'peacock';
      id = splitStringTwo[0];
      full = `https://www.peacocktv.com/watch/playback/vod/${id}`;
    }

    // ============
    // === Vudu ===
    // ============
    else if (url.match(/^https:\/\/www\.vudu\.com\/content\/movies\/play\/(.+)\/PURCHASED_CONTENT\??/)) {
      splitStringOne = url.split('/content\/movies\/play/');
      splitStringTwo = splitStringOne[1].split('/PURCHASED_CONTENT');
      source = 'vudu';
      id = splitStringTwo[0];
      full = `https://www.vudu.com/content/movies/play/${id}/PURCHASED_CONTENT`;
    }

    // =======================
    // === Movies Anywhere ===
    // =======================
    else if (url.match(/^https:\/\/moviesanywhere\.com\/movie\/(.+)\??/)) {
      splitStringOne = url.split('/movie/');
      splitStringTwo = splitStringOne[1].split('?');
      source = 'moviesanywhere';
      id = splitStringTwo[0];
      full = `https://moviesanywhere.com/movie/${id}`;
    }

    // Set the states
    if (source && id && full) {
      this.setState({
        sourceName: source,
        sourceId: id,
        sourceUrl: full,
      });
    } else {
      this.setState({
        sourceName: null,
        sourceId: null,
        sourceUrl: null,
      });
    }
  }

  // ID changed
  handleIdChange(event) {
    this.setState({
      filterId: event.target.value,
    });
  }

  // Source subscription changed
  handleSourceSubscriptionChange(event) {
    this.setState({
      sourceSubscription: event.target.value,
    });
  }

  // Source name changed
  handleSourceNameChange(event) {
    this.setState({
      sourceName: event.target.value,
    });
  }

  // Source id changed
  handleSourceIdChange(event) {
    this.setState({
      sourceId: event.target.value,
    });
  }

  // When the new filter form is submitted
  handleSubmit(event) {

    // Make sure no other action is taken
    event.preventDefault();

    var newTitle = (this.state.filterTitleReal ? this.state.filterTitleReal : this.state.filterTitle);
    var newSources = [{
      name: this.state.sourceName,
      draft: false,
      available: false,
      verified: null,
      subscription: null,
    }];
    var newFilters = this.state.selectedFiltersFull;
    var newInfo = this.state.imdbMovieDetails;

    console.log('Title: ', newTitle);
    console.log('Sources: ', newSources);
    console.log('Filters: ', newFilters);
    console.log('Info: ', newInfo);
    console.log('Series season=' + this.state.imdbMovieSeriesSeason + ', episode=' + this.state.imdbMovieSeriesEpisode + ', episode name=' + this.state.imdbMovieSeriesName + '...');

    var newSeries = null;
    if (this.state.imdbMovieSeriesName) {
      newSeries = newTitle;
      newTitle = this.state.imdbMovieSeriesName;
    }

    // Loop through the filters and set the IDs
    var startId = 1001;
    newFilters.forEach(function(item) {
      item.id = startId;
      startId++;
    });

    // Get our references
    var filterRef;
    var userRef = this.props.firebase.db.collection('users').doc(this.props.firebase.auth.currentUser.uid);

    // Get the user's info
    userRef.get().then((user) => {

      if (user.exists) {

        var userInfo = user.data();

        // A value to help us check for duplicates
        var uniqueIdentifierForDuplicates = null;
        if (newInfo && newInfo.imdbid) {
          if (newSeries && this.state.imdbMovieSeriesSeason && this.state.imdbMovieSeriesEpisode) {
            uniqueIdentifierForDuplicates = newInfo.imdbid + '---' + this.state.imdbMovieSeriesSeason + '---' + this.state.imdbMovieSeriesEpisode;
          } else {
            uniqueIdentifierForDuplicates = newInfo.imdbid;
          }
        } else if (this.state.sourceName === 'youtube') {
          uniqueIdentifierForDuplicates = 'youtube---' + newTitle;
        }
        var foundDuplicate = find(this.state.tbody, function(o) {
          return o.data.uniqueIdentifierForDuplicates === uniqueIdentifierForDuplicates;
        });
        if (!foundDuplicate || window.confirm("It appears this video may already exist in our system. Please hit Cancel to check. If you really want to proceed and create it anyways, hit OK.")) {

          // Add a new filter item
          var addObject = {
            title: newTitle,
            sources: newSources,
            filters: newFilters,
            nextFilterId: startId,
            published: null,
            createdAt: this.props.firebase.fieldValue.serverTimestamp(),
            createdBy: userRef,
            createdByString: userInfo.name,
            confirmedBy: null,
            vidInfo: newInfo,
            season: (this.state.imdbMovieSeriesSeason ? this.state.imdbMovieSeriesSeason : null),
            episode: (this.state.imdbMovieSeriesEpisode ? this.state.imdbMovieSeriesEpisode : null),
            series: newSeries,
          };

          //console.log('Adding NEW Video: ' + newTitle + ' (' + uniqueIdentifierForDuplicates + ')', addObject);

          this.props.firebase.db.collection('filters').add(addObject)

          .then(function(docRef) {

            filterRef = docRef;

            // Add a new source
            return this.props.firebase.db.collection('sources').add({
              filters: docRef,
              name: this.state.sourceName,
              offset: 0,
              published: null,
              url: this.state.sourceId,
              createdAt: this.props.firebase.fieldValue.serverTimestamp(),
              createdBy: userRef,
              confirmedBy: null,
            });
          }.bind(this))

          .then(function() {

            // Clear the fields
            document.getElementById('new-filter-form').reset();

            // Clear the state
            this.setState({
              imdbMovies: null,
              imdbMovie: {},
              imdbMovieDetails: {},
              defaultFilters: [],
              defaultFiltersText: '',
              selectedFilters: [],
              selectedFiltersFull: [],
              filterTitle: '',
              filterTitleReal: '',
              filterSource: '',
              filterId: '',
              sourceName: '',
              sourceId: '',
              sourceUrl: '',
            });

            // Also try to get justwatch information and save it to the vidInfo2
            justwatch.search({
              query: newTitle,
            })

            // We are just going to grab the first result and hope it's a good one
            .then(function(result) {
              if (result.items && result.items[0] && result.items[0].id) {
                return justwatch.getTitle((newSeries === null ? 'movie' : 'show'), result.items[0].id);
              } else {
                return Promise.reject('No results from JustWatch search.');
              }
            })

            // Now we will make sure we have an imdbid match and then save to the database
            .then(function(result) {

              var tstImdbId = null;
              if (result.external_ids && result.external_ids.length > 0) {
                for (var i = 0; i < result.external_ids.length; i++) {
                  if (result.external_ids[i].provider && result.external_ids[i].provider === 'imdb' && result.external_ids[i].external_id) {
                    tstImdbId = result.external_ids[i].external_id;
                    break;
                  }
                }
              }

              if (tstImdbId !== null && tstImdbId === newInfo.imdbid) {

                // Here is where we peel out the offers section and parse it for our own use
                var offers = result.offers;
                delete result.offers;

                console.log('OFFERS:', offers);

                var offersList = getOffersList(offers);

                console.log('LIST:', offersList);

                // Now save to the database
                return filterRef.update({
                  vidInfo2: result,
                  offers: offersList,
                });
              }
            })
            .catch(function(error) {
              console.log('ERROR: ', error);
            });

          }.bind(this));
        }
      }
    });
  };

  // Reserves a video
  reserveVideo(videoId, key) {

    // Get the user's full display name
    var name = this.state.currentUser.name;

    // Split on whitespace
    var nameSplit = name.split(/\s+/);

    // Now take the first name and first letter of last name
    //var finalName = nameSplit[0] + ' ' + nameSplit[1].charAt(0) + '.';
    var finalName = name;

    // Set it
    var filterRef = this.props.firebase.db.collection('filters').doc(videoId);
    var userRef = this.props.firebase.db.collection('users').doc(this.props.firebase.auth.currentUser.uid);

    // If published is null
    filterRef.update({
      reservedBy: userRef,
      reservedByString: finalName,
    }).then(function() {

      // Update the UI
      var tbodyState = this.state.tbody;
      var thisItem = tbodyState[key];
      thisItem.data.reservedByString = finalName;
      tbodyState[key] = thisItem;
      this.setState({
        tbodyState,
      });
    }.bind(this));
  }

  // Un-Reserves a video
  unreserveVideo(videoId, key) {
    var filterRef = this.props.firebase.db.collection('filters').doc(videoId);
    filterRef.update({
      reservedBy: null,
      reservedByString: null,
    }).then(function() {
      var tbodyState = this.state.tbody;
      var thisItem = tbodyState[key];
      thisItem.data.reservedByString = null;
      tbodyState[key] = thisItem;
      this.setState({
        tbodyState,
      });
    }.bind(this));
  }

  // When the form is submitted
  preToggleModal(type, data, key) {

    // Set our modal data
    this.setState({
      modalData: data,
      modalDataItem: key,
      modalType: type,
    });
  }
  closeModal() {
    this.setState(prevState => ({
      modalType: '',
      modalData: null,
      modalDataItem: null,
    }));
  };

  // Deletes a filter and all sources
  deleteFilter() {

    // Get our reference point
    var filterId = this.state.modalData.id;
    var filterRef = this.props.firebase.db.collection('filters').doc(filterId);

    // Get all sources for this filter set
    this.props.firebase.db.collection('sources').where('filters', '==', filterRef).get().then(function(snapshot) {
      snapshot.forEach(function(doc) {

        // Delete
        this.props.firebase.db.collection('sources').doc(doc.id).delete().then(function() {
          console.log('Deleted Source: ', doc.id);
        });
      }.bind(this));
    }.bind(this));

    // Delete the filter set itself too
    this.props.firebase.db.collection('filters').doc(filterId).delete().then(function() {
      console.log('Deleted Filters: ', filterId);
    });

    this.closeModal();
  }

  // Promotes a filter set
  promoteFilter() {

    // Get our reference point
    var filterId = this.state.modalData.id;
    var filterRef = this.props.firebase.db.collection('filters').doc(filterId);

    // Get all filter data
    filterRef.get().then(function(doc) {

      // See if we find one that is disabled
      var foundDisabled = false;
      if (doc.exists) {
        var allFiltersTmp = doc.data().filters;
        for (var i = 0; i < allFiltersTmp.length; i++) {
          if (allFiltersTmp[i].disabled) {
            foundDisabled = true;
          }
        }
      }

      // Now check
      if (foundDisabled) {

        alert('We cannot promote this video because one or more of your filters for this video are set to disabled. Please open up the list of filters for this video and make sure each disabled filter is either enabled or deleted. Then you will be able to promote this video.');

        // Close the modal
        this.closeModal();

      } else {

        // If published is null
        if (this.state.modalData.status === null) {
          filterRef.update({
            published: false,
            confirmedBy: this.props.firebase.db.collection('users').doc(this.props.firebase.auth.currentUser.uid)
          });
        }

        // If published is false
        else if (this.state.modalData.status === false) {
          filterRef.update({
            published: true,
          });
        }

        // Close the modal
        this.closeModal();
      }
    }.bind(this));
  }

  // Demotes a filter set
  demoteFilter() {

    // Get our reference point
    var filterId = this.state.modalData.id;
    var filterRef = this.props.firebase.db.collection('filters').doc(filterId);

    // If published is false
    if (this.state.modalData.status === false) {
      filterRef.update({
        published: null,
      });
    }

    // If published is true
    else if (this.state.modalData.status === true) {
      filterRef.update({
        published: false,
      });
    }

    // Close the modal
    this.closeModal();
  }

  // Marks a video source as available
  availableSource(event) {

    // Make sure no other action is taken
    event.preventDefault();

    // Make sure we have input
    if (this.state.sourceName !== '' && this.state.sourceSubscription !== '') {

      var sourceName = this.state.sourceName;
      var sourceSubscription = this.state.sourceSubscription;
      var allSources = this.state.modalData.sources;

      // Get our reference point
      var filterId = this.state.modalData.id;
      var filterRef = this.props.firebase.db.collection('filters').doc(filterId);

      // The new object
      var newSource = {
        name: sourceName,
        draft: false,
        available: true,
        verified: new Date().getTime(),
        subscription: sourceSubscription,
      };

      for (var i = 0; i < allSources.length; i++) {
        if (allSources[i].name === sourceName) {
          allSources[i] = newSource;
        }
      }

      // Update it
      filterRef.update({
        sources: allSources,
      }).then(function() {
        this.setState({
          sourceName: '',
          sourceSubscription: '',
        });
        this.closeModal();
      }.bind(this)).catch(function(error) {
        console.log('[ERROR] Could not update the source!', error);
        alert('Could not update the source!');
      });
    }

    // Error
    else {
      alert('Please select a source and check one of the boxes!');
    }
  }

  // Marks a video source as NO LONGER available
  unavailableSource(event) {

    // Make sure no other action is taken
    event.preventDefault();

    // Make sure we have input
    if (this.state.sourceName !== '') {

      var sourceName = this.state.sourceName;

      var allSources = this.state.modalData.sources;

      // Get our reference point
      var filterId = this.state.modalData.id;
      var filterRef = this.props.firebase.db.collection('filters').doc(filterId);

      // The new object
      var newSource = {
        name: sourceName,
        draft: false,
        available: false,
        verified: new Date().getTime(),
        subscription: null,
      };

      for (var i = 0; i < allSources.length; i++) {
        if (allSources[i].name === sourceName) {
          allSources[i] = newSource;
        }
      }

      // Update it
      filterRef.update({
        sources: allSources,
      }).then(function() {
        this.closeModal();
      }.bind(this)).catch(function(error) {
        console.log('[ERROR] Could not set the source as unavailable!', error);
        alert('Could not set the source as unavailable!');
      });
    }

    // Error
    else {
      alert('Please select a source!');
    }
  }

  // Adds a new source to a video
  addSource(event) {

    // Make sure no other action is taken
    event.preventDefault();

    // Make sure we have input
    if (this.state.sourceName !== '' && this.state.sourceId !== '') {

      // Get our reference point
      var modalIndex = this.state.modalDataItem;
      var modalItem = this.state.tbody[modalIndex];
      var tbodyState = this.state.tbody;
      var filterId = this.state.modalData.id;
      var filterRef = this.props.firebase.db.collection('filters').doc(filterId);
      var userRef = this.props.firebase.db.collection('users').doc(this.props.firebase.auth.currentUser.uid);

      // Add a new source
      this.props.firebase.db.collection('sources').add({
        filters: filterRef,
        name: this.state.sourceName,
        offset: 0,
        published: null,
        url: this.state.sourceId,
        createdAt: this.props.firebase.fieldValue.serverTimestamp(),
        createdBy: userRef,
      });

      // Add it to the array of drafts
      modalItem.data.sources.push({
        available: false,
        draft: true,
        name: this.state.sourceName,
        subscription: null,
        verified: null,
      });

      // Update the filter
      filterRef.update({
        sources: modalItem.data.sources
      });

      // Set and update the state
      tbodyState[modalIndex] = modalItem;
      this.setState({
        tbodyState,
      });

      // Clear
      this.setState({
        sourceName: '',
        sourceId: '',
        sourceUrl: '',
      });
      document.getElementById('new-source-form').reset();
      this.closeModal();
    }

    // Error
    else {
      // TODO: Show in the UI (either as an alert, or as an inline form error)
      console.log('ERROR! Invalid inputs.');
    }
  }

  // Deletes a source from a video
  deleteSource(event) {

    // Make sure no other action is taken
    event.preventDefault();

    // Make sure we have input
    if (this.state.sourceName !== '') {

      console.log('We have a source name: ' + this.state.sourceName);

      var sourceName = this.state.sourceName;

      // Get our reference point
      var modalIndex = this.state.modalDataItem;
      var modalItem = this.state.tbody[modalIndex];
      var tbodyState = this.state.tbody;
      var filterId = this.state.modalData.id;
      var filterRef = this.props.firebase.db.collection('filters').doc(filterId);

      // Remove the source
      this.props.firebase.db.collection('sources').where('filters', '==', filterRef).where('name', '==', sourceName).get().then(function(snapshot) {
        snapshot.forEach(function(doc) {

          console.log('One: ', doc.id);

          // Delete
          this.props.firebase.db.collection('sources').doc(doc.id).delete().then(function() {
            console.log('Deleted Source: ', doc.id);
          });

          console.log('Soiruyces before delete: ', modalItem.data.sources);

          // Get where the source is currently
          var sourceToDelete = -1;
          for (var i = 0; i < modalItem.data.sources.length; i++) {
            if (modalItem.data.sources[i].name === sourceName) {
              sourceToDelete = i;
              break;
            }
          }

          if (sourceToDelete !== -1) {
            modalItem.data.sources.splice(sourceToDelete, 1);
          }

          console.log('Soiruyces after delete: ', modalItem.data.sources);

          // Update the filter
          filterRef.update({
            sources: modalItem.data.sources
          });

          // Set and update the state
          tbodyState[modalIndex] = modalItem;
          this.setState({
            tbodyState,
          });

        }.bind(this));
      }.bind(this));

      // Clear
      this.setState({
        sourceName: '',
      });
      document.getElementById('delete-source-form').reset();
      this.closeModal();
    }

    // Error
    else {
      alert('Could not delete the source!');
    }
  }

  // Promotes a source in a video
  promoteSource(event) {

    // Make sure no other action is taken
    event.preventDefault();

    // Make sure we have input
    if (this.state.sourceName !== '') {

      var sourceName = this.state.sourceName;

      // Get our reference point
      var modalIndex = this.state.modalDataItem;
      var modalItem = this.state.tbody[modalIndex];
      var tbodyState = this.state.tbody;
      var filterId = this.state.modalData.id;
      var filterRef = this.props.firebase.db.collection('filters').doc(filterId);
      var userRef = this.props.firebase.db.collection('users').doc(this.props.firebase.auth.currentUser.uid);

      console.log('Promoting source ' + sourceName + '!');

      // Promote the source
      this.props.firebase.db.collection('sources').where('filters', '==', filterRef).where('name', '==', sourceName).get().then(function(snapshot) {
        snapshot.forEach(function(doc) {

          // Discover where to promote it to
          var promodata = doc.data();
          var promotion = true;
          var promouser = userRef;

          // Promote
          this.props.firebase.db.collection('sources').doc(doc.id).update({
            published: promotion,
            confirmedBy: promouser,
          }).then(function() {

            console.log('Promoted Source: ', doc.id);

            // Remove it from the drafts and move it to the published
            var sourceToPromote = -1;
            for (var i = 0; i < modalItem.data.sources.length; i++) {
              if (modalItem.data.sources[i].name === sourceName && modalItem.data.sources[i].draft) {
                sourceToPromote = i;
                break;
              }
            }
            modalItem.data.sources[sourceToPromote].draft = false;

            // Update the filter
            filterRef.update({
              sources: modalItem.data.sources,
            });

            // Set and update the state
            tbodyState[modalIndex] = modalItem;
            this.setState({
              tbodyState,
            });

            // Clear
            this.setState({
              sourceName: '',
            });
            document.getElementById('promote-source-form').reset();
            this.closeModal();

          }.bind(this)).catch(function(error) {
            console.log('ERROR PROMOTING SOURCE: ', error);
          });

        }.bind(this));
      }.bind(this));
    }

    // Error
    else {
      // TODO: Show in the UI (either as an alert, or as an inline form error)
      console.log('ERROR! Invalid inputs.');
    }
  }

  changeUnmountOnClose(e) {
    let value = e.target.value;
    console.log('Value: ', value);
  }

  // This checks the reply that came back from the extension and sets the state to true if it finds the extension
  toggleExtension(reply) {
    if (reply && reply.message && reply.message === 'yesisuream!') {
      this.setState({
        hasExtension: true,
      });
    } else {
      this.setState({
        hasExtension: false,
      });
    }
  }

  componentDidMount() {

    /*var blahBlahName = 'My Girl';
    var blahBlahId = '2hypEcZKcNX4u57vdITN';
    var blahBlahType = 'movie';

    // In case we need the full list of providers
    justwatch.getProviders()
    .then(function(result) {
      console.log('PROVIDERS: ', result);
    });

    // A sample search using the above data
    justwatch.search({
      query: blahBlahName,
    })
    .then(function(result) {
      console.log('RESULT 1: ', result);
      if (result.items && result.items[0] && result.items[0].id) {
        return justwatch.getTitle(blahBlahType, result.items[0].id);
      } else {
        return Promise.reject('No results from JustWatch search.');
      }
    })
    .then(function(result) {
      console.log('RESULT 2: ', result);

      var offers = result.offers;
      delete result.offers;

      console.log('OFFERS:', offers);

      var offersList = getOffersList(offers);

      console.log('LIST:', offersList);

      // Now save to the database
      var filterRef = this.props.firebase.db.collection('filters').doc(blahBlahId);
      return filterRef.update({
        vidInfo2: result,
        offers: offersList,
      });
    }.bind(this))
    .catch(function(error) {
      console.log('ERROR: ', error);
    });*/

    // Get our current user (the database one, not the firebase one)
    var userRef = this.props.firebase.db.collection('users').doc(this.props.firebase.auth.currentUser.uid);
    userRef.get().then((user) => {
      if (user.exists) {
        var currentUser = user.data();
        this.setState({
          currentUser,
        });
      }
    });

    // Get a list of all videos that have active reports
    allReports = [];
    allReportsCount = [];
    this.props.firebase.db.collection('issues').get().then(function(snapshot) {
      snapshot.forEach(function(doc) {
        if (doc.data().reports) {
          allReports.push(doc.data().filters.id);
          allReportsCount.push(doc.data().reports.length);
        }
      });
    });

    // Adjust OMDB config (HTTPS/SSL error)
    omdb.utils.config.url = omdb.utils.config.url.replace("http:", "https:");

    // Start looking at Firebase filters (will also auto-update as filters change!)
    this.unsubscribe = this.props.firebase.filters().orderBy('title', 'asc').onSnapshot(snapshot => {

      // Start with an empty table of results
      var tbody = [];

      // Loop through each filter/video that we found
      snapshot.forEach(doc => {

        // Get the data
        var data = doc.data();

        // See if the title needs an adjustment
        var newTitle = data.title;
        if (data.vidInfo && data.vidInfo.type === 'series') {
          newTitle = data.series + ' S' + data.season + ':E' + data.episode + ' (' + data.title + ')';
        }

        // Of the LIVE sources, see which ones are actually still available
        var sourcesDrafts = [];
        var sourcesAvailable = [];
        var sourcesOutdated = [];
        var sourcesUnavailable = [];

        for (var i = 0; i < data.sources.length; i++) {

          // Get our source
          var thisSource = data.sources[i];

          // Get a difference in time
          var sourceDifference = null;
          if (thisSource.verified !== null) {
            var nowTime = Math.ceil(new Date().getTime()/1000);
            var verifiedTime = Math.ceil(thisSource.verified/1000);
            sourceDifference = nowTime - verifiedTime;
            //console.log('Source difference for ' + newTitle + ' is ' + sourceDifference);
          }

          // If it is a draft
          if (thisSource.draft) {
            sourcesDrafts.push(thisSource);
          }

          // If it is outdated
          else if (thisSource.verified === null || sourceDifference === null || sourceDifference > (86400*45)) {
            sourcesOutdated.push(thisSource);
          }

          // If it is available
          else if (thisSource.available) {
            sourcesAvailable.push(thisSource);
          }

          // If it is not available
          else if (!thisSource.available) {
            sourcesUnavailable.push(thisSource);
          }

          // Something else should never happen, but default to outdated
          else {
            console.log('THIS SHOULD NOT HAPPEN!');
            sourcesOutdated.push(thisSource);
          }
        }

        // A value to help us check for duplicates
        var uniqueIdentifierForDuplicates = null;
        if (data.vidInfo && data.vidInfo.imdbid) {
          if (data.series && data.season && data.episode) {
            uniqueIdentifierForDuplicates = data.vidInfo.imdbid + '---' + data.season + '---' + data.episode;
          } else {
            uniqueIdentifierForDuplicates = data.vidInfo.imdbid;
          }
        } else if (data.sources.length === 1 && data.sources[0].name === 'youtube') {
          uniqueIdentifierForDuplicates = 'youtube---' + data.title;
        }

        // Add to the table
        tbody.push({
          className: "",
          data: {
            title: newTitle,
            status: data.published,
            filters: data.filters.length,
            sources: data.sources,
            sourcesDrafts: sourcesDrafts,
            sourcesAvailable: sourcesAvailable,
            sourcesOutdated: sourcesOutdated,
            sourcesUnavailable: sourcesUnavailable,
            id: doc.id,
            uniqueIdentifierForDuplicates: uniqueIdentifierForDuplicates,
            createdBy: data.createdBy.id,
            createdByString: data.createdByString,
            confirmedBy: (data.confirmedBy ? data.confirmedBy.id : null),
            confirmedByString: data.confirmedByString,
            reservedBy: (data.reservedBy ? data.reservedBy.id : null),
            reservedByString: data.reservedByString,
            vidInfo: data.vidInfo,
            seriesName: (data.series ? data.series : null),
            seriesSeason: (data.season ? data.season : null),
            seriesEpisode: (data.episode ? data.episode : null),
            seriesTitle: (newTitle !== data.title ? data.title : null),
          },
        });
      });

      // Update the state
      this.setState({
        tbody,
      });

    });

    // Send a message to the extension to see if it exists

    // Try the live one, and if it's not there, try the local one
    chrome.runtime.sendMessage('peheiohonoimeohgjknaaeoaflocficn', { message: "areyououtthere?" },
      function(response) {
        if (response === undefined) {
          chrome.runtime.sendMessage('cgglcmlnminfemdoofahammhokmlbmjg', { message: "areyououtthere?" },
            function(response2) {
              this.toggleExtension(response2);
            }.bind(this)
          );
        } else {
          this.toggleExtension(response);
        }
      }.bind(this)
    );
  }

  componentWillUnmount() {
    this.unsubscribe && this.unsubscribe();
  }

  // Create filterset view link
  createFilterSetLink = (link) => {
    return '/filterset/' + link;
  }
  createFilterNewLink = (link) => {
    return '/filternew/' + link;
  }
  createFilterSet2Link = (link) => {
    return '/filterset2/' + link;
  }

  // This can be used to execute some arbitrary code when you click on a video
  tmpFilterSet = () => {
    //var filterRef = this.props.firebase.db.collection('filters').doc('put-doc-id-here');
    //var daObject = {};
    //filterRef.update({
    //  vidInfo: daObject,
    //});
  }

  // Creates a play link
  createPlayLink = (source, id) => {

    // Get our reference point
    var filterRef = this.props.firebase.db.collection('filters').doc(id);

    // Get all sources for this filter set
    this.props.firebase.db.collection('sources').where('filters', '==', filterRef).get().then(function(snapshot) {
      snapshot.forEach(function(doc) {

        // TODO: Here we can set up the filters, etc. for the speciifc user and authenticate them, etc.

        var sourceData = doc.data();

        // Only if the source matches
        if (sourceData.name === source) {
          var theLink = links[source] + sourceData.url;
          //if (sourceData.name === 'amazon') {
          //  theLink += '?autoplay=1&t=0';
          //}
          window.open(theLink, '_blank');
        }
      });
    });
  }

  // Takes multiple sources and creates links to the videos from them
  createLinks = (id, sources, key) => {

    let filterActions = []
    for (var i = 0; i < sources.length; i++) {
      var thisSource = sources[i].name ? sources[i].name : sources[i];
      var image = null;
      if (thisSource === 'hulu') {
        image = hulu;
      } else if (thisSource === 'peacock') {
        image = peacock;
      } else if (thisSource === 'vudu') {
        image = vudu;
      } else if (thisSource === 'moviesanywhere') {
        image = moviesanywhere;
      } else if (thisSource === 'youtube') {
        image = youtube;
      } else if (thisSource === 'amazon') {
        image = amazon;
      } else if (thisSource === 'netflix') {
        image = netflix;
      } else if (thisSource === 'disney') {
        image = disney;
      }
      if (image !== null) {
        filterActions.push(<a target="_blank" key={id + thisSource} rel="noopener noreferrer"><img src={image} width="24" alt={thisSource} title="Click to watch!" key={id + thisSource} onClick={() => this.createPlayLink(thisSource, id)} /></a>);
      }
    }
    return filterActions;
  }

  // Creates edit actions for filters and sources
  createActions = (data, key, status, createdByMe, reservedByMe, isAdmin) => {

    var hasAccess = createdByMe || (this.state.currentUser && (this.state.currentUser.permission === 'admin' || this.state.currentUser.permission === 'supervisor'));

    // Creating
    if (status === null) {
      if (hasAccess) {
        return (
          <div>
            <i className='fa fa-trash-alt' onClick={() => this.preToggleModal('filterdel', data, key)} title='Delete entire video and all filters' />
            &nbsp; &nbsp;
            <i className='fa fa-star' onClick={() => this.preToggleModal('filterpro', data, key)} title="Send for REVIEW" />
          </div>
        );
      } else {
        return null;
      }
    }

    // Pending
    else if (status === false) {
      return (
        <div>
          <i className='fa fa-trash-alt' style={isAdmin ? null : hidden} onClick={() => this.preToggleModal('filterdel', data, key)} title='Delete entire video and all filters' />
          &nbsp; &nbsp;
          <i className='fa fa-star' style={hasAccess ? null : hidden} onClick={() => this.preToggleModal('filterpro', data, key)} title="Promote to READY" />
          &nbsp; &nbsp;
          <i className='fa fa-times' style={hasAccess ? null : hidden} onClick={() => this.preToggleModal('filterback', data, key)} title="Return this back to CREATE" />
          &nbsp; &nbsp;
          <i className='fa fa-search' style={data.reservedBy || !hasAccess ? hidden : null} onClick={() => this.reserveVideo(data.id, key)} title="Reserve" />
          &nbsp; &nbsp;
          <i className='fa fa-times-circle' style={reservedByMe ? null : hidden} onClick={() => this.unreserveVideo(data.id, key)} title="Un-Reserve" />
        </div>
      );
    }

    // Ready
    else {
      if (hasAccess) {
        return (
          <div>
            <i className='fa fa-trash-alt' style={isAdmin ? null : hidden} onClick={() => this.preToggleModal('filterdel', data, key)} title='Delete entire video and all filters' />
            &nbsp; &nbsp;
            <i className='fa fa-plus' onClick={() => this.preToggleModal('sourceadd', data, key)} title="Add a new source for this video" />
            &nbsp; &nbsp;
            <i className='fa fa-minus' onClick={() => this.preToggleModal('sourcedel', data, key)} title="Remove a source from this video" />
            &nbsp; &nbsp;
            <i className='fa fa-heart' onClick={() => this.preToggleModal('sourcepro', data, key)} title="Mark a source as ready" />
            &nbsp; &nbsp;
            <i className='fa fa-times' onClick={() => this.preToggleModal('sourceunavailable', data, key)} title="Mark a source as no longer available" />
            &nbsp; &nbsp;
            <i className='fa fa-check' onClick={() => this.preToggleModal('sourceavailable', data, key)} title="Mark a source as available" />
            &nbsp; &nbsp;
            {
              isEmpty(data.vidInfo) && !(data.sources && data.sources.length === 1 && data.sources[0].name === 'youtube')
              ?
                null
              :
                null
            }
          </div>
        );
      } else {
        return null;
      }
    }
  }

  // Creates a source list (whichToShow 0 is show all, 1 is only live souces, 2 is only drafts)
  createSourceList = (data, whichToShow) => {

    // If there is no data, the modal is not open
    if (data && data.sources.length) {

      // Set up our items
      var items = [];
      data.sources.forEach(source => {
        if (whichToShow === 0 || (whichToShow === 1 && !source.draft) || (whichToShow === 2 && source.draft)) {
          items.push(<option value={source.name} key={source.name}>{source.name} {source.draft ? '(draft)' : '(live)'}</option>);
        }
      });

      return (
        <Input type="select" name="source-name" id="source-name" onChange={this.handleSourceNameChange}>
          <option value="">Select a Source</option>
          {items}
        </Input>
      );
    } else {
      return (
        <div>No sources exist. Please create one first.</div>
      );
    }
  }

  render() {

    let hasExtension;
    if (!this.state.hasExtension) {
    hasExtension =
      <Alert
        color='danger'
        className='alert-with-icon'
      >
        <span data-notify="icon" className="nc-icon nc-alert-circle-i"></span>
        <span data-notify="message">It does not appear that you have the FinallyFiltered Chrome extension installed. You will need to have that installed in order to watch any filtered videos. <a href="https://chrome.google.com/webstore/detail/peheiohonoimeohgjknaaeoaflocficn" target="_blank">Click here</a> to install it.</span>
      </Alert>;
    }

    return (
      <div className="content">
        <Row>
          <Col xs={12}>

            <BrowserDetection>
              { browserHandler }
            </BrowserDetection>
            {hasExtension}

            <h6>Sections to display: </h6>
            <Input type="checkbox" name="toggleSectionVideosNew" id="toggleSectionVideosNew" style={{ marginLeft: '10px' }} onChange={this.toggleSectionVideosNew} checked={this.state.toggleSectionVideosNew} />
            <Label style={{ marginLeft: '30px' }}>New Filter</Label>
            <Input type="checkbox" name="toggleSectionVideosWorking" id="toggleSectionVideosWorking" style={{ marginLeft: '10px' }} onChange={this.toggleSectionVideosWorking} checked={this.state.toggleSectionVideosWorking} />
            <Label style={{ marginLeft: '30px' }}>In-Progress</Label>
            <Input type="checkbox" name="toggleSectionVideosReported" id="toggleSectionVideosReported" style={{ marginLeft: '10px' }} onChange={this.toggleSectionVideosReported} checked={this.state.toggleSectionVideosReported} />
            <Label style={{ marginLeft: '30px' }}>Videos Reported</Label>
            <Input type="checkbox" name="toggleSectionVideosVerified" id="toggleSectionVideosVerified" style={{ marginLeft: '10px' }} onChange={this.toggleSectionVideosVerified} checked={this.state.toggleSectionVideosVerified} />
            <Label style={{ marginLeft: '30px' }}>Need Verification</Label>
            <Input type="checkbox" name="toggleSectionVideosPrime" id="toggleSectionVideosPrime" style={{ marginLeft: '10px' }} onChange={this.toggleSectionVideosPrime} checked={this.state.toggleSectionVideosPrime} />
            <Label style={{ marginLeft: '30px' }}>Live</Label>

            <div style={this.state.toggleSectionVideosNew ? null : hidden}>
              <h4>Filter a new video</h4>
              <a style={{ color: 'blue', display: 'block', marginBottom: '10px', marginTop: '-10px', cursor: 'pointer' }} onClick={() => this.preToggleModal('filterinstructions', null, null)} title="Instructions">(Click here for instructions)</a>
              <Card className="card-user">
                <CardBody>
                  <Form id="new-filter-form" onSubmit={this.handleSubmit}>
                    <FormGroup>
                      <Label for="filterUrl">Video URL</Label>
                      <Input type="text" name="filterUrl" id="filterUrl" placeholder="Paste the full URL of the video here" onChange={this.handleSourceChange} />
                      {this.getSourceDetails(this.state.sourceName, this.state.sourceId, this.state.sourceUrl)}
                    </FormGroup>
                    <FormGroup>
                      <Label for="filterTitle">Video Search (we will check IMDB for a match)</Label>
                      <Input type="text" name="filterTitle" id="filterTitle" placeholder="Type or paste in the title of the movie/show" onChange={this.handleTitleChange} />
                    </FormGroup>

                    {this.populateMovieTitles()}
                    {this.populateSeriesDetails()}
                    {this.getMovieCusses(this.state.defaultFilters)}
                    {this.getMovieSubmit()}

                  </Form>

                </CardBody>
              </Card>
            </div>

            <div style={this.state.toggleSectionVideosWorking ? null : hidden}>
              <h4>Videos being worked on</h4>
              <Card>
                <CardBody>
                  <Table responsive>
                    <thead className="text-primary">
                      <tr>
                        <th>Series</th>
                        <th>Season</th>
                        <th>Episode</th>
                        <th>Title</th>
                        <th># Filters</th>
                        <th>Creating On</th>
                        <th>Creator</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.tbody.map((prop, key) => {

                        // If this is a 'Creating'
                        if (prop.data.status === null) {
                          return (
                            <tr key={'key-creating-' + key}>
                              <td>{prop.data.seriesName}</td>
                              <td>{prop.data.seriesSeason}</td>
                              <td>{prop.data.seriesEpisode}</td>
                              <td>
                                <Link to={this.createFilterSetLink(prop.data.id)} onClick={() => this.tmpFilterSet()}>{prop.data.seriesTitle ? prop.data.seriesTitle : prop.data.title}</Link>
                              </td>
                              <td>
                                {prop.data.filters}
                              </td>
                              <td>
                                <span>{this.createLinks(prop.data.id, prop.data.sources, key)}</span>
                              </td>
                              <td>
                                <span>{
                                  ((this.props.firebase.auth.currentUser.uid === prop.data.createdBy)
                                  ||
                                  (this.state.currentUser && (this.state.currentUser.permission === 'admin' || this.state.currentUser.permission === 'supervisor')))
                                  ?
                                    prop.data.createdByString
                                  :
                                    prop.data.createdByString.split(" ").reduce((response, word) => response += word.slice(0,1), '')
                                }</span>
                              </td>
                              <td>
                                {this.createActions(prop.data, key, prop.data.status, (this.props.firebase.auth.currentUser.uid === prop.data.createdBy), (this.props.firebase.auth.currentUser.uid === prop.data.reservedBy), (this.props.firebase.auth.currentUser.email === 'dormentd@gmail.com'))}
                              </td>
                            </tr>
                          );
                        } else {
                          return (
                            <tr key={"key-creating-empty-" + prop.data.id}></tr>
                          );
                        }
                      })}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </div>

            <div style={this.state.toggleSectionVideosReported ? null : hidden}>
              <h4>Videos that have issues that need to be fixed ASAP</h4>
              <Card>
                <CardBody>
                  <Table responsive>
                    <thead className="text-primary">
                      <tr>
                        <th>Series</th>
                        <th>Season</th>
                        <th>Episode</th>
                        <th>Title</th>
                        <th># Reports</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.tbody.map((prop, key) => {

                        // If this video has any reports
                        var allReportsIndex = allReports.indexOf(String(prop.data.id));
                        if (allReportsIndex !== -1) {

                          var numOfReports = allReportsCount[allReportsIndex];

                          return (
                            <tr key={'key-creating-' + key}>
                              <td>{prop.data.seriesName}</td>
                              <td>{prop.data.seriesSeason}</td>
                              <td>{prop.data.seriesEpisode}</td>
                              <td>
                                <Link to={this.createFilterSetLink(prop.data.id)} onClick={() => this.tmpFilterSet()}>{prop.data.seriesTitle ? prop.data.seriesTitle : prop.data.title}</Link>
                              </td>
                              <td>
                                {numOfReports}
                              </td>
                            </tr>
                          );
                        } else {
                          return (
                            <tr key={"key-creating-empty-" + prop.data.id}></tr>
                          );
                        }
                      })}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </div>

            <div style={this.state.toggleSectionVideosVerified ? null : hidden}>
              <h4>Videos needing to be verified</h4>
              <Card>
                <CardBody>
                  <Table responsive>
                    <thead className="text-primary">
                      <tr>
                        <th>Series</th>
                        <th>Season</th>
                        <th>Episode</th>
                        <th>Title</th>
                        <th>Filters</th>
                        <th>Creator</th>
                        <th>Reviewer</th>
                        <th>Source</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.tbody.map((prop, key) => {

                        // If this is a 'Pending'
                        if (prop.data.status === false) {
                          return (
                            <tr key={'key-pending-' + key}>
                              <td>{prop.data.seriesName}</td>
                              <td>{prop.data.seriesSeason}</td>
                              <td>{prop.data.seriesEpisode}</td>
                              <td>
                                <Link to={this.createFilterSetLink(prop.data.id)} onClick={() => this.tmpFilterSet()}>{prop.data.seriesTitle ? prop.data.seriesTitle : prop.data.title}</Link>
                              </td>
                              <td>
                                {prop.data.filters}
                              </td>
                              <td>
                                <span>{prop.data.createdByString}</span>
                              </td>
                              <td>
                                <span>{prop.data.reservedByString}</span>
                              </td>
                              <td>
                                <span>{this.createLinks(prop.data.id, prop.data.sources, key)}</span>
                              </td>
                              <td>
                                {this.createActions(prop.data, key, prop.data.status, (this.props.firebase.auth.currentUser.uid === prop.data.createdBy), (this.props.firebase.auth.currentUser.uid === prop.data.reservedBy), (this.props.firebase.auth.currentUser.email === 'dormentd@gmail.com'))}
                              </td>
                            </tr>
                          );
                        } else {
                          return (
                            <tr key={"key-pending-empty-" + prop.data.id}></tr>
                          );
                        }
                      })}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </div>

            <div style={this.state.toggleSectionVideosPrime ? null : hidden}>
              <h4>Videos ready for prime-time</h4>
              <Card>
                <CardBody>
                  <Table responsive>
                    <thead className="text-primary">
                      <tr>
                        {thead.map((prop, key) => {
                          if (key !== thead.length - 1) {
                            return <th key={'key-ready-header-' + key}>{prop}</th>;
                          } else {
                            return null;
                          }
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.tbody.map((prop, key) => {

                        // If this is a 'Ready'
                        if (prop.data.status === true) {
                          return (
                            <tr key={'key-ready-' + key}>
                              <td>{prop.data.seriesName}</td>
                              <td>{prop.data.seriesSeason}</td>
                              <td>{prop.data.seriesEpisode}</td>
                              <td>
                                <Link to={this.createFilterSetLink(prop.data.id)} onClick={() => this.tmpFilterSet()}>{prop.data.seriesTitle ? prop.data.seriesTitle : prop.data.title}</Link>
                              </td>
                              <td>
                                {prop.data.filters}
                              </td>
                              <td>
                                <span>{this.createLinks(prop.data.id, prop.data.sourcesAvailable, key)}</span>
                              </td>
                              <td>
                                <span>{this.createLinks(prop.data.id, prop.data.sourcesUnavailable, key)}</span>
                              </td>
                              <td>
                                <span>{this.createLinks(prop.data.id, prop.data.sourcesOutdated, key)}</span>
                              </td>
                              <td>
                                <span>{this.createLinks(prop.data.id, prop.data.sourcesDrafts, key)}</span>
                              </td>
                              <td>
                                {this.createActions(prop.data, key, prop.data.status, (this.props.firebase.auth.currentUser.uid === prop.data.createdBy), (this.props.firebase.auth.currentUser.uid === prop.data.reservedBy), (this.props.firebase.auth.currentUser.email === 'dormentd@gmail.com'))}
                              </td>
                            </tr>
                          );
                        } else {
                          return (
                            <tr key={"key-ready-empty-" + prop.data.id}></tr>
                          );
                        }
                      })}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </div>

          </Col>
        </Row>

        <Modal isOpen={this.state.modalType === 'filterdel'} toggle={this.closeModal}>
          <ModalHeader toggle={this.closeModal}>Are you sure you want to delete this filter?</ModalHeader>
          <ModalBody>
            This will delete EVERYTHING associated with this filter including all filters and sources! This is NOT reversible! You will need to re-create all filters from scratch.
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.deleteFilter}>Yes! Delete.</Button>{' '}
            <Button color="secondary" onClick={this.closeModal}>Cancel</Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={this.state.modalType === 'sourceadd'} toggle={this.closeModal}>
          <form id="new-source-form" onSubmit={this.addSource}>
            <ModalHeader toggle={this.closeModal}>Add Source</ModalHeader>
            <ModalBody>
              This will add a new source for this video.
              <br /><br />
              <Input type="select" name="source-name" id="source-name" onChange={this.handleSourceNameChange}>
                <option vaue="">Select Source</option>
                <option value="netflix">Netflix</option>
                <option value="hulu">Hulu</option>
                <option value="peacock">Peacock</option>
                <option value="vudu">Vudu</option>
                <option value="moviesanywhere">Movies Anywhere</option>
                <option value="amazon">Amazon</option>
                <option value="disney">Disney+</option>
                <option value="youtube">YouTube</option>
              </Input>
              <br />
              <Input type="text" name="source-id" id="source-id" placeholder="Last part of video URL" onChange={this.handleSourceIdChange} />
            </ModalBody>
            <ModalFooter>
              <Button color="primary">Add Source</Button>{' '}
              <Button color="secondary" onClick={this.closeModal}>Cancel</Button>
            </ModalFooter>
          </form>
        </Modal>

        <Modal isOpen={this.state.modalType === 'sourcedel'} toggle={this.closeModal}>
          <form id="delete-source-form" onSubmit={this.deleteSource}>
            <ModalHeader toggle={this.closeModal}>Delete Source</ModalHeader>
            <ModalBody>
              Select a source to delete.
              <br /><br />
              {this.createSourceList(this.state.modalData, 0)}
            </ModalBody>
            <ModalFooter>
              <Button color="primary">Delete Source</Button>{' '}
              <Button color="secondary" onClick={this.closeModal}>Cancel</Button>
            </ModalFooter>
          </form>
        </Modal>

        <Modal isOpen={this.state.modalType === 'sourcepro'} toggle={this.closeModal}>
          <form id="promote-source-form" onSubmit={this.promoteSource}>
            <ModalHeader toggle={this.closeModal}>Promote Source</ModalHeader>
            <ModalBody>
              Select a source to promote. This will make this source instantly available to all users.
              <br /><br />
              {this.createSourceList(this.state.modalData, 2)}
            </ModalBody>
            <ModalFooter>
              <Button color="primary">Promote Source</Button>{' '}
              <Button color="secondary" onClick={this.closeModal}>Cancel</Button>
            </ModalFooter>
          </form>
        </Modal>

        <Modal isOpen={this.state.modalType === 'sourceavailable'} toggle={this.closeModal}>
          <form id="available-source-form" onSubmit={this.availableSource}>
            <ModalHeader toggle={this.closeModal}>Mark a Source as Available</ModalHeader>
            <ModalBody>
              Select a source to mark as available. This will make it instantly available to all users for 45 days, after which it will need to be verified again.
              <br /><br />
              {this.createSourceList(this.state.modalData, 1)}
              <br />
              <div style={{ marginLeft: '15px' }}>
                <Input type="radio" name="source-subscription" value="sub" checked={this.state.sourceSubscription === 'sub'} onChange={this.handleSourceSubscriptionChange} /> Available free, or with a subscription.
              </div>
              <div style={{ marginLeft: '15px' }}>
                <Input type="radio" name="source-subscription" value="pay" checked={this.state.sourceSubscription === 'pay'} onChange={this.handleSourceSubscriptionChange} /> Only available by renting or buying.
              </div>
            </ModalBody>
            <ModalFooter>
              <Button color="primary">Mark as Available</Button>{' '}
              <Button color="secondary" onClick={this.closeModal}>Cancel</Button>
            </ModalFooter>
          </form>
        </Modal>

        <Modal isOpen={this.state.modalType === 'sourceunavailable'} toggle={this.closeModal}>
          <form id="unavailable-source-form" onSubmit={this.unavailableSource}>
            <ModalHeader toggle={this.closeModal}>Mark a Source as NOT Available</ModalHeader>
            <ModalBody>
              Select a source to mark as NO LONGER available. This will remove it from being viewed on the "Watch" page until it is available again.
              <br /><br />
              {this.createSourceList(this.state.modalData, 1)}
            </ModalBody>
            <ModalFooter>
              <Button color="primary">Mark as NOT Available</Button>{' '}
              <Button color="secondary" onClick={this.closeModal}>Cancel</Button>
            </ModalFooter>
          </form>
        </Modal>

        <Modal isOpen={this.state.modalType === 'filterpro'} toggle={this.closeModal}>
          <ModalHeader toggle={this.closeModal}>Are you sure you want to promote this filter?</ModalHeader>
          <ModalBody>
            This will change it from "Creating" to "Review" status, or from "Review" to "Ready" status.
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.promoteFilter}>Yes! Promote.</Button>{' '}
            <Button color="secondary" onClick={this.closeModal}>Cancel</Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={this.state.modalType === 'filterback'} toggle={this.closeModal}>
          <ModalHeader toggle={this.closeModal}>Are you sure you want to demote this filter?</ModalHeader>
          <ModalBody>
            This will change it from "Review" back to "Creating" status, or from "Ready" back to "Review" status.
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.demoteFilter}>Yes! Demote.</Button>{' '}
            <Button color="secondary" onClick={this.closeModal}>Cancel</Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={this.state.modalType === 'filterinstructions'} toggle={this.closeModal}>
          <ModalHeader toggle={this.closeModal}>Filtering Instructions</ModalHeader>
          <ModalBody>

            <p><strong>Movies</strong></p>
            <ol>
              <li>Go online and find the movie or TV show that you want to filter and hit "Play" to start watching it.</li>
              <li>Copy the address in the address bar.</li>
              <li>Paste that address into the "Video URL" field.</li>
              <li>If it was successful, the <strong>Source</strong>, <strong>ID</strong>, and <strong>URL</strong> fields should show up right below what you pasted.</li>
              <li>Now do a search for the name of the movie or series. It doesn't have to be the full name, for example, for "A Quiet Place" you could just type "Quiet Place".</li>
              <li>Select the correct movie or series from the "IMDB Video" field. Note: Sometimes the name (like "24" or "Alone") is ambiguous enough that it gets too many hits returned; if this happens, let us know so that we can add a special case for it.</li>
              <li>The movie poster and details about the movie should show up.</li>
              <li>The first checkbox after that is for if you want us to try to pre-determine all swear words in the show before you start filtering. Note: If the show you are going to be filtering is likely to be very clean (other than some language) then it may be in your best interest to select this option. However, if you are likely going to have to filter out other things like sex, violence, etc. then you may not want to check this box. It kind of depends on your filtering preference as you become more advanced.</li>
              <li>If you opted to get the pre-set swears, you will get a text area which includes all of the potential swear words or phrases in the movie.</li>
              <li>Look through that list and make sure that all of the lines that contain actual swear words are selected!</li>
              <li>For TV series you need to enter the season number, episode number, and episode name.</li>
              <li>Hit "Submit".</li>
              <li>Your video will now show up under "Videos being worked on".</li>
            </ol>
            <p>
              Now, this part gets a bit tricky. We use a tool to automatically download and detect swear words and phrases in the movie and we set up filters based on those timestamps. However, sometimes these timestamps can be a little bit off. So, what you will need to do is follow these instructions to test your timestamps, then re-add the movie if they are off.
            </p>
            <ol>
              <li>Go back to Amazon/Netflix/Hulu/Peacock/Disney Plus/YouTube and refresh the screen.</li>
              <li>The FinallyFiltered toolbar should now turn yellow and show you how many draft filters you have so far.</li>
              <li>Hover over the toolbar and hit the scissors to enter Edit mode.</li>
              <li>Hit the button to "Go to just before the next filter"</li>
              <li>It will play and show you the section that was filtered (Note: It sometimes helps to turn on subtitles so that you can SEE what is being said too).</li>
              <li>The way it works is that the entire phrase that has the swear word in it should be muted.</li>
              <li>If the muting is late (i.e. you hear the first few words of the phrase), then you'll need to adjust the filters backwards in time.</li>
              <li>If the muting is early then you'll need to adjust the filters ahead.</li>
              <li>To do this, go back to the Control Center and click the title of the video. Use "Adjust all filters" and set either a negative/backwards (e.g. -1.2) or a postive/ahead (e.g. 4.0) decimal number to say how much you want all filters to be adjusted, in seconds. Hit "Go".</li>
              <li>Now re-load the video and see if it's right on or not. Continue to adjust as needed.</li>
              <li>If the subtitle file was really bad and everything is off by different amounts, then you can just delete the movie and re-add it, but this time, when it loads the list of swears, you can click the link to get the NEXT subtitle file. Hopefully it is a better one.</li>
            </ol>
            <p>
              At this point, if you used the pre-swear filters, you will need to go in and verify and adjust those filters so they are accurate. Then (whether you used the pre-set swear filters or not) you will need to go through the entire video to filter out any other inappropriate content. Here are some helps on how to do this (however, in the end, usually it is easiest to just watch the entire video in a faster speed, creating filters as you go).
            </p>
            <ul>
              <li>Open up kids-in-mind.com and pull up the video. This gives a very detailed list of language, sexual content and violence that may need to be filtered out. If kids-in-mind has not created a document for this movie, then you can also try "IMDB parent's guide" or "Common Sense Media", though these are a lot less specific usually.</li>
              <li>Find the places in the movie that correspond with what was pointed out (using scrubbing/skipping/fast-forwarding/fast-playing).</li>
              <li>Use edit mode in the extension and the "Start Filter", "End Filter" and "Check Filter" and "Submit" buttons to add filters for each one.</li>
              <li>As you add them, they will automatically show up on the list inside Control Center.</li>
              <li>Note: Any editing/deleting of filters once a filter is added must happen through Control Center.</li>
            </ul>
            <p>
              Once you're satisfied with the video, submit it for review.
            </p>

            <p><strong>Review</strong></p>
            <p>
              In order to not accidentally show inappropriate content to any of our viewers, it's important that all videos that are filtered are done carefully and accurately. To ensure accuracy, each video goes through a two-step review process.
            </p>
            <p>
              Once you start creating filters for a new movie/show/video, it will show up under "Videos being worked on". Your name will show up as the "Creator". When you are satisfied that you have filtered everything, go ahead and hit the star icon to send it to review.
            </p>
            <p>
              If you are the reviewer for a video, you should watch the complete video from start-to-finish to make sure that everything worked OK. If there were errors, they will need to be fixed before promoting the video. If everything is fine, hit the star icon to send it live.
            </p>
            <p>
              Once a video is live, anyone with an account can watch it filtered. It will now show up in the "Videos ready for prime-time" list, as well as on the "Watch" tab for regular users.
            </p>

            <p>If you have any questions or issues, please contact us at <strong>support@finallyfiltered.com</strong></p>

          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.closeModal}>OK</Button>
          </ModalFooter>
        </Modal>

      </div>
    );
  }
}

const condition = authUser => !!authUser;

export default compose(
  withFirebase,
  withEmailVerification,
  withAuthorization(condition),
)(RegularTables);
