import React, {Component} from 'react';
import {CardElement, injectStripe} from 'react-stripe-elements';
import {Alert, Button, Row, Col} from "reactstrap";

///////// STYLES /////////
const paymentForm = {
  backgroundColor: 'rgb(244,243,239)',
  margin: '10px',
  input: {
    backgroundColor: 'red',
  },
};

// Built-in Stripe payments form
class CheckoutForm extends Component {

  // Constructor
  constructor(props) {
    super(props);

    this.state = {
      error: '',
      message: '',
      showButton: true,
    };

    this.submit = this.submit.bind(this);
  }

  // This handles when they submit the elements payment form
  async submit(ev) {

    try {

      // Grab our token
      let {token} = await this.props.stripe.createToken({name: "Name"});

      // Make sure we got a valid token
      if (token !== undefined) {

        // Now, save it to the user's table
        var userRef = this.props.firebase.db.collection('users').doc(this.props.firebase.auth.currentUser.uid);
        const user = await userRef.update({
          stripeCard: token,
        });

        // Set a message
        this.setState({
          error: '',
          message: 'Card saving. Please wait...',
          showButton: false,
        });

        // Success!
        setTimeout(function() {
          window.location.assign('/profile');
        }, 3000);
      } else {
        this.setState({
          error: 'Invalid payment data. Please fill in all of the fields correctly.',
        });
      }

      // Wait for it to finish, and then let them know it worked!
      //if (response.ok) console.log("Purchase Complete!")
    } catch (error) {
      console.log('We had a CheckoutForm.js error', error);
      this.setState({
        error: 'There was an error contacting our payment provider. Please try again. If you keep having trouble, please contact support (support@finallyfiltered.com).',
      });
    }
  }

  // The UI display for the payment form
  render() {
    return (
      <div className="checkout">
        <Row>
          <Col xs="12" sm="12" md="6" lg="6">
            <CardElement {...{
              style: {
                base: {
                  color: 'rgb(84, 202, 205)',
                },
                invalid: {
                  color: 'red',
                }
              }
            }} />
            {this.state.showButton ? <a onClick={this.submit}>Add/Update Card</a> : null}
            <br />
            {this.state.error !== '' ? <Alert color="danger">{this.state.error}</Alert> : ''}
            {this.state.message ? <Alert color="primary">{this.state.message}</Alert> : ''}
          </Col>
        </Row>
      </div>
    );
  }
}

// Their own wrapper
export default injectStripe(CheckoutForm);
