import React from "react";
//import { Row } from "reactstrap";

class Home extends React.Component {
  render() {
    return (
      <div className="content">

        <h1>Terms of Use</h1>
        <p>
          Effective 29 March 2019.
        </p>

        <p>
          We're going to keep this pretty simple. Your use of the service means your acceptance of the following terms and conditions. If you need to contact us, please email support [at] finallyfiltered [dot] com.
        </p>
        <p>
          You have the right to use this service as long as you are under a valid free trial or you have a valid paying account and all payments are current and up-to-date.
        </p>
        <p>
          While we make every attempt to keep the service up and available, there is no guarantee of any level of availability.
        </p>
        <p>
          We do our best to filter a large quantity of videos so that you have a lot of things to watch, however there is no guarantee to the number of filtered vidoes available, or the rate at which the number of available videos will increase.
        </p>
        <p>
          Please be patient with us in these early phases. We are constantly improving our software and increasing the number of filters.
        </p>
        <p>
          We work hard to make sure we catch anything that may be objectionable, however, this is a very subjective undertaking, so there is no guarantee that you will never, when using this service, see or encounter objectionable material.
        </p>
        <p>
          The process by which we are providing this service requires technical and complex programming and is dependent on the code working correctly, and network conditions and local computer conditions. In other words, there are a lot of factors that come into play and there is no guarantee that your video will hide, mute, or block all of the objectionable material. We cannot be held liable for anything you or your children see that may be inappropriate. Use this service at your own risk!
        </p>
        <p>
          You agree not to reverse engineer, hack, or otherwise tamper with or manipulate our service.
        </p>
        <p>
          You agree not to use any filter/tagging data in any other service or site.
        </p>
        <p>
          Any changes to our terms of use will be posted here. Check back often. Anything super important or material will be sent out via email to our customers.
        </p>

      </div>
    );
  }
}

export default Home;
