import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';

const config = {
  apiKey: process.env.REACT_APP_API_KEY || 'AIzaSyAVANkOJjYDcoApSnaksVFY-UYzQ5yqcOo',
  authDomain: process.env.REACT_APP_AUTH_DOMAIN || 'finally-filtered.firebaseapp.com',
  databaseURL: process.env.REACT_APP_DATABASE_URL || 'https://finally-filtered.firebaseio.com',
  projectId: process.env.REACT_APP_PROJECT_ID || 'finally-filtered',
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET || 'finally-filtered.appspot.com',
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID || '764714491191',
};

class Firebase {
  constructor() {
    app.initializeApp(config);

    /* Helper */

    this.fieldValue = app.firestore.FieldValue;
    this.emailAuthProvider = app.auth.EmailAuthProvider;

    /* Firebase APIs */

    this.auth = app.auth();
    this.db = app.firestore();
    this.functions = app.functions();

    /* Social Sign In Method Provider */

    //this.googleProvider = new app.auth.GoogleAuthProvider();
    //this.facebookProvider = new app.auth.FacebookAuthProvider();
    //this.twitterProvider = new app.auth.TwitterAuthProvider();
  }

  // *** Auth API ***

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  //doSignInWithGoogle = () =>
  //  this.auth.signInWithPopup(this.googleProvider);

  //doSignInWithFacebook = () =>
  //  this.auth.signInWithPopup(this.facebookProvider);

  //doSignInWithTwitter = () =>
  //  this.auth.signInWithPopup(this.twitterProvider);

  doSignOut = () => this.auth.signOut();

  doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

  doSendEmailVerification = () =>
    this.auth.currentUser.sendEmailVerification({
      url: process.env.REACT_APP_CONFIRMATION_EMAIL_REDIRECT || 'https://finallyfiltered.com',
    });

  doPasswordUpdate = password =>
    this.auth.currentUser.updatePassword(password);

  // *** Merge Auth and DB User API *** //

  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged(authUser => {
      if (authUser) {
        this.user(authUser.uid)
          .get()
          .then(snapshot => {
            const dbUser = snapshot.data();

            if (dbUser) {

              // default empty permission
              if (!dbUser.permission) {
                dbUser.permission = 'viewer';
              }

              // merge auth and db user
              authUser = {
                uid: authUser.uid,
                email: authUser.email,
                emailVerified: authUser.emailVerified,
                providerData: authUser.providerData,
                ...dbUser,
              };

              next(authUser);
            } else {
              fallback();
            }
          });
      } else {
        fallback();
      }
    });

  // *** User API ***

  user = uid => this.db.doc(`users/${uid}`);

  users = () => this.db.collection('users');

  // *** Filter API ***

  filter = uid => this.db.doc(`filters/${uid}`);

  filters = () => this.db.collection('filters');

  // Gets all sources for a given filter
  sources = (filter) => {
    return this.db.collection('sources').where('filters', '==', filter.ref);
  }
}

export default Firebase;
