import React, { Component } from 'react';

import {
  Input, Row, Col, Button, Alert, Spinner
} from "reactstrap";
import {Elements, StripeProvider} from 'react-stripe-elements';
import CheckoutForm from './CheckoutForm';
import { compose } from 'recompose';
import {
  withAuthorization,
} from 'old-components/Session';
import { withFirebase } from '../Firebase';

class UserPaymentForm extends Component {

  constructor(props) {
    super(props);

    this.state = {
      errorMessage: '',
      stripeCard: null,
      stripeUser: props.stripeUser,
      stripePlan: props.stripePlan,
      stripeValid: props.stripeValid,
      stripeMessage: props.stripeMessage,
    };
  }

  componentDidMount() {

    // Get the user's current card, if it exists
    var userRef = this.props.firebase.db.collection('users').doc(this.props.firebase.auth.currentUser.uid);
    userRef.get().then((user) => {
      if (user.exists) {
        var userInfo = user.data();
        if (userInfo.stripeCardReadable) {
          this.setState({
            stripeCard: userInfo.stripeCardReadable.brand + ' ending in ' + userInfo.stripeCardReadable.last4 + ' expiring ' + userInfo.stripeCardReadable.exp_month + '/' + userInfo.stripeCardReadable.exp_year,
          });
        }
      }
    });
  }

  // When they click to cancel their account
  cancelClick(subscription) {

    var c = window.confirm('Are you sure you want to cancel your subscription? You will lose access to FinallyFiltered at the end of this billing period.');

    if (c === true) {

      //console.log('Sub: ' + subscription + ', User: ' + this.state.stripeUser + ', Our User: ' + this.props.firebase.auth.currentUser.uid);

      // Submit the cancellation call
      var stripeFunction = this.props.firebase.functions.httpsCallable('cancelStripeSubscription');
      var stripeObject = {
        user: this.props.firebase.auth.currentUser.uid,
        stripe: this.state.stripeUser,
        subscription: subscription,
      };
      stripeFunction(stripeObject)

      // Wait until we have our stripeFunction result
      .then(function(result) {
        if (result.data && result.data.status && (result.data.status === 'active' || result.data.status === 'trialing') && result.data.cancel_at_period_end === true) {
          this.setState({
            stripeUser: this.state.stripeUser,
            stripePlan: result.data,
            stripeValid: true,
            stripeMessage: null,
          });
        } else {
          this.setState({
            errorMessage: 'We had some trouble cancelling your subscription. Sorry about that! Please contact support (support@finallyfiltered.com) and we will be sure to cancel it for you.',
          });
        }
      }.bind(this));
    }
  }

  // When they click to re-activate their subscription
  reactivateClick(stuff) {

    var c = window.confirm('Are you sure you want to re-activate your subscription? You will be billed at the beginning of the next billing period, plus every month thereafter until you cancel.');

    if (c === true) {

      // Submit the request
      var stripeFunction = this.props.firebase.functions.httpsCallable('restartStripeSubscription');
      var stripeObject = {
        user: this.props.firebase.auth.currentUser.uid,
        subscription: stuff,
      };
      stripeFunction(stripeObject)

      // Wait until we have our stripeFunction result
      .then(function(result) {
        if (result.data && result.data.status && (result.data.status === 'active' || result.data.status === 'trialing')) {
          this.setState({
            stripeUser: this.state.stripeUser,
            stripePlan: result.data,
            stripeValid: true,
            stripeMessage: null,
          });
        } else {
          this.setState({
            errorMessage: 'We had some trouble re-activating your subscription. Sorry about that! Please contact support (support@finallyfiltered.com) and we will be sure to get it started for you.',
          });
        }
      }.bind(this));
    }
  }

  // When they say to try the billing again
  billNow(planId, nextChargeAmount) {

    var c = window.confirm('This will attempt to charge $' + nextChargeAmount + ' to your payment card now. Should we proceed?');

    if (c === true) {

      // Get the current invoice
      var stripeFunction = this.props.firebase.functions.httpsCallable('billStripeSubscription');
      var stripeObject = {
        user: this.props.firebase.auth.currentUser.uid,
        subscription: planId,
      };
      stripeFunction(stripeObject)

      // Wait until we have our stripeFunction result
      .then(function(result) {

        console.log('Stripe Subscription Billing Result: ', result);

        /*if (result.data && result.data.status && result.data.status === 'active') {
          window.location.assign('/profile');
        } else {
          this.setState({
            errorMessage: 'We had some trouble re-activating your subscription. Sorry about that! Please contact support (support@finallyfiltered.com) and we will be sure to get it started for you.',
          });
        }*/
      });
    }
  }

  render() {

    const error = this.state.errorMessage;
    const user = this.state.stripeUser;
    const plan = this.state.stripePlan;
    const valid = this.state.stripeValid;
    const card = this.state.stripeCard;

    //console.log('Plan (valid=' + valid + '): ', plan);

    // No data yet (loading)
    if (user === null && plan === null && valid === null && error === '') {
      return (
        <div>
          <h5>Subscription</h5>
          <span>Loading subscription information...</span>
        </div>
      );
    }

    // Some error happened (and not just a usual error -- one that caused us to get NO data)
    else if (user === null && plan === null && valid === null && error !== '') {
      return (
        <div>
          <h5>Subscription</h5>
          <Alert color="danger">{error}</Alert>
        </div>
      );
    }

    // All other cases (we have valid data, it's just getting the right stuff to display at the right time)
    else {

      // Figure out their charge stuff
      var nextChargeDate;
      var nextChargeAmount;
      if (plan && (valid || plan.current_period_end)) {
        nextChargeDate = new Date(plan.current_period_end * 1000);
        var nextChargeAmount = plan.plan.amount;
        if (plan.discount && plan.discount.coupon && plan.discount.coupon.percent_off) {
          var amountOff = (nextChargeAmount * plan.discount.coupon.percent_off/100);
          nextChargeAmount = nextChargeAmount - amountOff;
        }
        nextChargeAmount = nextChargeAmount / 100;
      }

      // Figure out their trial stuff
      var trial = null;
      if (plan && valid) {
        var dateNow = Math.floor(Date.now()/1000);
        var trial = 'Ended';
        if (plan.trial_end) {
          if (plan.trial_end <= dateNow) {
            trial = 'Ended ' + Math.ceil((dateNow - plan.trial_end) / 60 / 60 / 24) + ' day(s) ago';
          } else {
            trial = Math.ceil((plan.trial_end - dateNow) / 60 / 60 / 24) + ' day(s) left';
          }
        }
      }

      const planRender = (
        <div>
          <h5>Plan</h5>
          {this.state.stripeMessage && <Alert color="danger">{this.state.stripeMessage}</Alert>}
          {
            // They have a valid plan!
            plan ?
              <div>
                {
                  valid ?
                    <div>
                      <p>
                        <strong>Plan:</strong> {plan.plan.nickname}<strong>{trial !== null ? ' -- Free Trial, ' + trial : ''}</strong>
                      </p>
                      <div>
                        {
                          plan.cancel_at_period_end === true ?
                            <div>
                              <p>
                                Your plan is set to terminate on <strong>{nextChargeDate.toString()}</strong>.
                              </p>
                              {
                                // STATUS:
                                // - plan is valid
                                // - plan is set to end at end of current period
                                // - card exists
                                // ACTION:
                                // - still let them use the service
                                // - let them re-activate their subscription before it expires
                                card ?
                                  <Button color="primary" onClick={() => this.reactivateClick(plan.id)}>Re-activate Subscription</Button>
                                // STATUS:
                                // - plan is valid
                                // - plan is set to end at end of current period
                                // - card does NOT exist
                                // ACTION:
                                // - still let them use the service
                                // - they can't re-activate until they add a payment card
                                :
                                  <p>
                                    If you would like to re-activate your subscription rather than let it expire, please first add a payment card.
                                  </p>
                              }
                            </div>

                          :
                            <div>
                              <p>Your next billing cycle begins on <strong>{nextChargeDate.toString()}</strong>.</p>
                              {
                                // STATUS:
                                // - plan is valid
                                // - card exists
                                // ACTION:
                                // - still let them use the service
                                // - allow them to cancel their subscription if they'd like
                                card ?
                                  <div>
                                    <p>
                                      Your current payment method will be charged <strong>${nextChargeAmount}</strong> at that time.
                                    </p>
                                    <p className='stripepayment'>
                                      <a onClick={() => this.cancelClick(plan.id)}>Cancel Subscription</a>
                                    </p>
                                  </div>
                                // STATUS:
                                // - plan is valid
                                // - card does NOT exist
                                // ACTION:
                                // - still let them use the service
                                // - unless they add a payment card, their account will go past-due
                                // - or they can cancel
                                :
                                  <div>
                                    <p>
                                      You do not currently have a payment card added. Please add a new payment method before that time to avoid losing access to FinallyFiltered!
                                    </p>
                                    <p className='stripepayment'>
                                      <a onClick={() => this.cancelClick(plan.id)}>Cancel Subscription</a>
                                    </p>
                                  </div>
                              }

                            </div>
                        }
                      </div>
                    </div>
                  :
                    <div>
                      {
                        plan.status === 'past_due' ?
                          <div>
                            <p>
                              <strong>Plan:</strong> {plan.plan.nickname}
                            </p>
                            {
                              plan.cancel_at_period_end === true ?
                                <div>
                                  <div><strong>Your last payment is past due! Please make sure your payment option is valid to continue service.</strong></div>
                                  <br />
                                  {
                                    // STATUS:
                                    // - plan is past due
                                    // - plan is set to end at end of current period
                                    // - card exists
                                    // ACTION:
                                    // - still let them use the service
                                    // - let Stripe keep retrying automatically
                                    // - let them manually retry
                                    card ?
                                      <div>
                                        We will automatically re-try the transaction every day for 3 days using <strong>{card}</strong>.
                                        If we are successful, then your subscription will continue uninterrupted until <strong>{nextChargeDate.toString()}</strong>.
                                        If not, then your subscription will be canceled until a valid payment card is entered.
                                      </div>
                                    // STATUS:
                                    // - plan is past due
                                    // - plan is set to end at end of current period
                                    // - card does NOT exist
                                    // ACTION:
                                    // - lock them out of the service
                                    // - let them enter card details
                                    :
                                      <div>
                                        Your subscription has been suspended for non-payment. To re-enable your subscription, add a valid payment card.
                                      </div>
                                  }
                                </div>
                              :
                                <div>
                                  <p><strong>Your last payment is past due! Please make sure your payment option is valid to continue service.</strong></p>
                                  {
                                    // STATUS:
                                    // - plan is past due
                                    // - card exists
                                    // ACTION:
                                    // - still let them use the service
                                    // - let Stripe keep retrying automatically
                                    // - let them manually retry
                                    card ?
                                      <div>
                                        <p>
                                          We will automatically re-try the transaction every day for 3 days using <strong>{card}</strong>.
                                          If we are successful, then your subscription will continue uninterrupted and your next billing cycle will begin on <strong>{nextChargeDate.toString()}</strong>.
                                          If not, then your subscription will be canceled until a valid payment card is entered.
                                        </p>
                                        <p>
                                          To have us try to re-bill your card now rather than wait until the next day, update your payment details and then click the button below.
                                        </p>
                                        <p>
                                          <Button color="primary" onClick={() => this.billNow(plan.id, nextChargeAmount)}>Charge My Card Now</Button>
                                        </p>
                                      </div>
                                    // STATUS:
                                    // - plan is past due
                                    // - NO card
                                    // ACTION:
                                    // - lock them out of the service
                                    // - let them enter card details
                                    :
                                      <div>
                                        Your subscription has been suspended for non-payment. To re-enable your subscription, add a valid payment card.
                                      </div>
                                  }
                                </div>
                            }
                          </div>
                        :
                          <p>Invalid plan</p>
                      }
                    </div>
                }
              </div>
            :
              <div>
                <p>Just $0.99 per month gives you unlimited access to our entire filtering library! (to be used with your existing Netflix, Hulu, Amazon Prime Video and YouTube accounts)</p>
                {
                  card ?
                    <Button color="primary" onClick={() => this.reactivateClick()}>Re-activate Subscription</Button>
                  :
                    <p>Please add a valid payment option first!</p>
                }
              </div>
          }
        </div>
      );
      // ###TEST-OR-LIVE###
      const cardRender = (
        <div>
          <h5>Payment Method</h5>
          {
            card ?
              <div>
                <p><strong>Current Card:</strong> {card}</p>
                <div>To update/change your payment method, fill in the form:</div>
                <StripeProvider apiKey="pk_live_jlbc9RTg1HjpuXZMnX0pwPPK">
                  <div className="stripepayment">
                    <Elements>
                      <CheckoutForm firebase={this.props.firebase} />
                    </Elements>
                  </div>
                </StripeProvider>
              </div>
            :
              <div>
                <div>You do not have a payment method on file. Please add one.</div>
                <StripeProvider apiKey="pk_live_jlbc9RTg1HjpuXZMnX0pwPPK">
                  <div className="stripepayment">
                    <Elements>
                      <CheckoutForm firebase={this.props.firebase} />
                    </Elements>
                  </div>
                </StripeProvider>
              </div>
          }
        </div>
      );

      return (
        <div>
          {planRender}
          {cardRender}
          {error && <Alert color="danger">{error}</Alert>}
        </div>
      );
    }
  }
}

const condition = authUser => !!authUser;

export default compose(
  withFirebase,
  withAuthorization(condition),
)(UserPaymentForm);
