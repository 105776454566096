import React, { Component } from 'react';

import {
  Input, Row, Col, Button, Alert
} from "reactstrap";

import { withFirebase } from '../Firebase';

/*import { IDisneyOpts } from '../../../node_modules/babbling/dist/apps/disney/config.js';
import {DisneyApp, DisneyApi} from '../../../node_modules/babbling/dist/apps/disney/index.js';
import { capture, instance, mock, when } from 'ts-mockito';
import request from "request-promise-native";
import fetch from "fetch";*/

//var babbling = require('babbling');

class StreamingChannels extends Component {

  constructor(props) {
    super(props);

    this.state = {
      disney: '',
    };

    this.onDismiss = this.onDismiss.bind(this);
  }

  // On mount, grab all of the filters
  componentDidMount() {

    // Wait for a login event to set things
    this.listener = this.props.firebase.onAuthUserListener(

      // If we have a logged in user
      currentUser => {

        /*request({
          url: 'https://www.disneyplus.com/home'
        }).then(function(result) {
          console.log('Read it!', result);
        }).catch(function(error) {
          console.log('Oops!', error);
        });*/

        /*fetch('https://www.disneyplus.com/home', {
          mode: 'no-cors',
        activi}).then(function(result) {
          console.log('Read it!', result);
        }).catch(function(error) {
          console.log('Oops!', error);
        });*/

        /*// Get a Disney API
        const playable = DisneyApp.createPlayerChannel().createPlayable(
          'https://www.disneyplus.com/video/ed92dc4e-7b61-4ade-aeab-6a9d0eff808b'
        ).then(function(stuff) {

          console.log('Got it!', stuff);

          //const app = mock(DisneyApp);
          const app = new DisneyApp({

          });

          const credentials = app.loadCredentials();

          console.log('Loaded!', credentials);

        }).catch(function(error) {
          console.log('Failed!', error);
        });*/

        // Set the state
        this.setState({
          disney: 'hi',
        });
      }
    );
  }

  componentWillUnmount() {
    this.listener();
  }

  // When they dismiss the error message
  onDismiss() {
    this.setState({ error: null });
  }

  render() {

    const name = this.state.name;
    const error = this.state.error;
    const isInvalid = name === '';

    return (
      <div>
        <h5>Streaming Channels</h5>

      </div>
    );
  }
}

export default withFirebase(StreamingChannels);
