import React from "react";
//import { Row } from "reactstrap";

class Privacy extends React.Component {
  render() {
    return (
      <div className="content">

        <h1>Privacy Policy</h1>
        <p>
          Effective 29 March 2019.
        </p>

        <h2>Contact</h2>
        <p>
          We're going to keep this pretty simple, yet GDPR compliant. If you have any questions about this policy, please contact us at support [at] finallyfiltered [dot] com. (our Data Protection Officer is also reachable at that email address).
        </p>

        <h2>Introduction</h2>
        <p>
          FinallyFiltered is a product (owned by Web Techtonics, LLC a Utah-based LLC) that allows people to watch online videos with certain parts muted, blocked or skipped. It is our mission to provide a meaningful service, while safeguarding and protecting our users' personal data. By signing up for and using our service, you are agreeing to these privacy policy terms as well as our <a href="/terms" target="_blank">terms of use</a> so that we can provide this service to you.
        </p>

        <h2>Personal Data</h2>
        <p>
          The following is information about what personal information we collect, when we collect it, how we store it, who we share it with, and how long we keep it.
        </p>

        <h3>Name, email, password</h3>
        <p>
          If/when you sign up for our service, we will ask you to provide your name, email address and a password for authentication purposes. This information is stored inside of a protected database on Google Cloud infrastructure. Your password is stored encrypted and is not even accessible by us. We store this information for as long as you have an account. We may use your email address to contact you with important details about your account, but we will never spam you nor share it with any other party other than mentioned here. You can delete your account at any time. Once your account is deleted, we still store your email address in perpetuity so that we can know when you sign up for the service again using that same email address. Your email address and your password are the means by which this personal information is protected. In order to better protect your data, please use a secure password and never share it with anyone (a representative of FinallyFiltered will never ask for your password).
        </p>

        <h3>Payment details</h3>
        <p>
          If/when you sign up for a paid plan/subscription, we will ask you to provide a payment card so that you can be billed for the service. Your card details are verified and stored inside of Stripe's payment processing platform. We never store your sensitive card data and only keep enough information (zip code, expiration date, and last 4 digits of the card) to help you identify which payment source you used. We keep this information as long as you have a paid plan. You can remove your payment details at any time.
        </p>

        <h3>User preferences</h3>
        <p>
          In order to improve our service, we may keep and store statistics on which videos, TV shows and movies you watch. We keep this data, even after you delete your account, though at that time it will not be linked to you in any way. We also allow users to set preferences on which types of filters they want to see or not see. We keep this information as long as you have an account with us. You can delete this at any time by deleting your account.
        </p>

        <h3>Non-identifying visitor information</h3>
        <p>
          We use Google Analytics to collect non-identifying personal information about our website visitors. This is collected when you visit our website and is shared with Google and stored indefinitely.
        </p>

        <h2>Transfer of Personal Data</h2>
        <p>
          Our cloud services and datacenters are located in the United States of America, so if you are outside of the United States, your personal information will be transferred to our infrastructure providers in the United States. Transfer and storage of this data is per the terms of service of each individual provider, but, insomuch as we can tell, this is done per adequate data protection guidelines. Using this software designates your consent to allow that transfer and storage to happen.
        </p>

        <h2>Your Rights</h2>
        <p>
          You have the right to cease using our service at any time. At any time you can contact us at the email address above to access your personal data, request correction of your data, or to request that we delete any and all data associated with your account.
        </p>

        <h2>3rd Parties</h2>
        <p>
          A note about 3rd parties (Amazon, Hulu, YouTube, etc.) This service is not an integration or tie-in to any of these 3rd party video services. We do not share any information with any of these services.
        </p>

        <h2>Changes</h2>
        <p>
          Changes to this privacy policy will be posted here and dated. We encourage you to visit this page frequently to stay up-to-date. Any significant or material change to our policy will be emailed out to all product users.
        </p>

      </div>
    );
  }
}

export default Privacy;
