import React from "react";
import PerfectScrollbar from "perfect-scrollbar";
import { Route, Switch, Redirect } from "react-router-dom";
import { AuthUserContext } from 'old-components/Session';
import Header from "components/Header/OpenHeader.jsx";
import Footer from "components/Footer/Footer.jsx";
import openRoutes from "constants/openRoutes.jsx";

var ps;

class HomeLayout extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      backgroundColor: "black",
      activeColor: "info",
    }
  }
  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.refs.mainPanel);
      document.body.classList.toggle("perfect-scrollbar-on");
    }
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
      document.body.classList.toggle("perfect-scrollbar-on");
    }
  }
  componentDidUpdate(e) {
    if (e.history.action === "PUSH") {
      this.refs.mainPanel.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
    }
  }
  handleActiveClick = (color) => {
    this.setState({ activeColor: color });
  }
  handleBgClick = (color) => {
    this.setState({ backgroundColor: color });
  }
  render() {
    return (
      <div className="wrapper">

        <div className="main-panel-nosidebar" ref="mainPanel">
          <AuthUserContext.Consumer>
            {authUser => (
              <Header
                {...this.props}
                authUser={authUser}
              />
            )}
          </AuthUserContext.Consumer>
          <Switch>
            {openRoutes.map((prop, key) => {
              if (prop.pro) {
                return null;
              }
              if (prop.redirect) {
                return <Redirect from={prop.path} to={prop.pathTo} key={key} />;
              }
              return (
                <Route
                  path={prop.path}
                  component={prop.component}
                  key={key}
                />
              );
            })}
          </Switch>
          <AuthUserContext.Consumer>
            {authUser => (
              <Footer
                fluid
                authUser={authUser}
              />
            )}
          </AuthUserContext.Consumer>
        </div>
      </div>
    );
  }
}

export default HomeLayout;
