import UserPage from "views/UserPage/UserPage.jsx";

import CardPage from 'views/CardPage/CardPage.jsx';
import FilterVideos from 'views/FilterVideos/FilterVideos.jsx';
import TraverseTree from 'views/TraverseTree/TraverseTree.jsx';
import TableList2 from 'views/TableList2/TableList2.jsx';
import TableList2a from 'views/TableList2/TableList2a.jsx';
import TableList2b from 'views/TableList2/TableList2b.jsx';
import WatchSet from 'views/WatchSet/WatchSet.jsx';

var controlRoutes = [
  {
    path: "/watch",
    name: "Watch",
    icon: "nc-icon nc-tv-2",
    component: CardPage
  },
  {
    path: "/watchset/:id",
    name: "Watch Set",
    hidden: true,
    icon: "nc-icon nc-circle-10",
    component: WatchSet,
  },
  {
    path: "/profile",
    name: "Profile",
    icon: "nc-icon nc-settings-gear-65",
    component: UserPage
  },
  {
    path: "/filter",
    name: "Filter",
    icon: "nc-icon nc-scissors",
    component: FilterVideos,
    permission: [ 'admin', 'supervisor', 'filterer' ]
  },
  {
    path: "/traverse",
    name: "Traverse",
    hidden: true,
    icon: "nc-icon nc-scissors",
    component: TraverseTree,
    permission: [ 'admin' ]
  },
  {
    path: "/filterset/:id",
    name: "Filter Set",
    hidden: true,
    icon: "nc-icon nc-circle-10",
    component: TableList2,
    permission: [ 'admin', 'supervisor', 'filterer' ]
  },
  {
    path: "/filternew/:id",
    name: "Filter New",
    hidden: true,
    icon: "nc-icon nc-circle-10",
    component: TableList2a,
    permission: [ 'admin', 'supervisor', 'filterer' ]
  },
  {
    path: "/filterset2/:id",
    name: "Filter New",
    hidden: true,
    icon: "nc-icon nc-circle-10",
    component: TableList2b,
    permission: [ 'admin', 'supervisor', 'filterer' ]
  },
  {
    redirect: true,
    path: "/",
    pathTo: "/watch",
    name: "Watch"
  }
];
export default controlRoutes;
