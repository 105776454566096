import React, { Component } from 'react';
import { Row, Col, Button, Input } from "reactstrap";
import { Link, Redirect } from 'react-router-dom';
import { AuthUserContext } from 'old-components/Session';
import { withFirebase } from 'old-components/Firebase';

const PasswordForgetPage = () => (
  <div className='justfy-content-center align-items-center text-center'>
    <br /><br /><br /><br /><br /><br />
    <br /><br /><br /><br /><br /><br />
    <AuthUserContext.Consumer>
    {authUser =>
      authUser ? (
        <Redirect from='/forgot' to='/watch' key='hey' />
      ) : (
        <p className='h6'>Enter your email address to reset your password</p>
      )
    }
    </AuthUserContext.Consumer>
    <PasswordForgetForm />
    <br /><br /><br /><br /><br /><br />
    <br /><br /><br /><br /><br /><br />
  </div>
);

const INITIAL_STATE = {
  email: '',
  error: null,
};

class PasswordForgetFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { email } = this.state;

    this.props.firebase
      .doPasswordReset(email)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
      })
      .catch(error => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email, error } = this.state;

    const isInvalid = email === '';

    return (
      <Row>
        <Col xs="12" sm={{ size: 6, offset: 3 }} md={{ size: 4, offset: 4}} lg={{ size: 4, offset: 4 }}>
          <form onSubmit={this.onSubmit}>
            <Input
              name="email"
              value={this.state.email}
              onChange={this.onChange}
              type="text"
              placeholder="Email Address"
            />
            <Button color="primary" disabled={isInvalid} type="submit">
              Reset My Password
            </Button>

            {error && <p>{error.message}</p>}
          </form>
        </Col>
      </Row>
    );
  }
}

const PasswordForgetLink = () => (
  <p>
    {/*<br />
    <hr style={{ color: 'red', backgroundColor: 'rgb(83,186,214)', width: '25%' }} />
    <br />*/}
    <Link to='/forgot'>Forgot Password?</Link>
  </p>
);

export default PasswordForgetPage;

const PasswordForgetForm = withFirebase(PasswordForgetFormBase);

export { PasswordForgetForm, PasswordForgetLink };
