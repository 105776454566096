import React, { Component } from 'react';
import { Row, Col, Button, Input } from "reactstrap";
import { Link, withRouter, Redirect } from 'react-router-dom';
import { compose } from 'recompose';
import { AuthUserContext } from 'old-components/Session';
import { SignUpLink } from 'views/Signup/Signup.jsx';
import { PasswordForgetLink } from 'old-components/PasswordForget';
import { withFirebase } from 'old-components/Firebase';

function SignInPage(props) {
  return <div className='justfy-content-center align-items-center text-center'>
    <br /><br /><br /><br /><br /><br />
    <br /><br /><br /><br /><br /><br />
    <AuthUserContext.Consumer>
    {authUser =>
      authUser ? (
        <Redirect from='/login' to='/watch' key='loginhey' />
      ) : (
        <div>
          <p className='h6'>Please sign in to your account</p>
          <hr style={{ color: 'red', backgroundColor: 'rgb(83,186,214)', width: '25%' }} />
          <br />
        </div>
      )
    }
    </AuthUserContext.Consumer>
    <SignInForm />
    {/*<Row>
      <SignInGoogle />
    </Row>*/}
    <PasswordForgetLink />
    <SignUpLink />
    <br /><br /><br /><br /><br /><br />
    <br /><br /><br /><br /><br /><br />
  </div>
}

const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
};

const ERROR_CODE_ACCOUNT_EXISTS =
  'auth/account-exists-with-different-credential';

const ERROR_MSG_ACCOUNT_EXISTS = `
  An account with an E-Mail address to
  this social account already exists. Try to login from
  this account instead and associate your social accounts on
  your personal account page.
`;

class SignInFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { email, password } = this.state;

    this.props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        this.props.history.push('/watch');
      })
      .catch(error => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {

    const { email, password, error } = this.state;

    const isInvalid = password === '' || email === '';

    if (this.props.authUser) {
      return <Redirect from='/login' to='/watch' key='loginhey' />;
    } else {
      return (
        <Row>
          <Col xs="12" sm={{ size: 6, offset: 3 }} md={{ size: 4, offset: 4}} lg={{ size: 4, offset: 4 }}>
            <form onSubmit={this.onSubmit}>
              <Input
                className="mb-2"
                name="email"
                value={email}
                onChange={this.onChange}
                type="text"
                placeholder="Email Address"
              />
              <Input
                className="mb-2"
                name="password"
                value={password}
                onChange={this.onChange}
                type="password"
                placeholder="Password"
              />
              <Button color="primary" disabled={isInvalid} type="submit">Sign In</Button>

              {error && <p>{error.message}</p>}

              {/*<br />
              <hr style={{ color: 'red', backgroundColor: 'rgb(83,186,214)', width: '25%' }} />
              <br />*/}

            </form>
          </Col>
        </Row>
      );
    }
  }
}

/*class SignInGoogleBase extends Component {
  constructor(props) {
    super(props);

    this.state = { error: null };
  }

  onSubmit = event => {
    this.props.firebase
      .doSignInWithGoogle()
      .then(socialAuthUser => {

        // Create a user in your Firebase Realtime Database too
        return this.props.firebase.user(socialAuthUser.user.uid).set(
          {
            //name: socialAuthUser.user.displayName,
            email: socialAuthUser.user.email,
            //permission: 'viewer',
          },
          { merge: true },
        );
      })
      .then(() => {
        this.setState({ error: null });
        this.props.history.push('/watch');
      })
      .catch(error => {
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS;
        }

        this.setState({ error });
      });

    event.preventDefault();
  };

  render() {
    const { error } = this.state;

    return (
      <Col xs="12" sm={{ size: 8, offset: 2 }} md={{ size: 4, offset: 4}} lg={{ size: 4, offset: 4 }}>
        <p className='h6'>Or</p>
        <form onSubmit={this.onSubmit}>
          <Button color="info" type="submit" className='w-75'>Sign In with Google2</Button>
          {error && <p>{error.message}</p>}
        </form>
      </Col>
    );
  }
}*/

/*class SignInFacebookBase extends Component {
  constructor(props) {
    super(props);

    this.state = { error: null };
  }

  onSubmit = event => {
    this.props.firebase
      .doSignInWithFacebook()
      .then(socialAuthUser => {

        // Create a user in your Firebase Realtime Database too
        return this.props.firebase.user(socialAuthUser.user.uid).set(
          {
            //name: socialAuthUser.additionalUserInfo.profile.name,
            email: socialAuthUser.additionalUserInfo.profile.email,
            //permission: 'viewer',
          },
          { merge: true },
        );
      })
      .then(() => {
        this.setState({ error: null });
        this.props.history.push('/watch');
      })
      .catch(error => {
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS;
        }

        this.setState({ error });
      });

    event.preventDefault();
  };

  render() {
    const { error } = this.state;

    return (
      <Col xs="12" sm="12" md="4" lg="4">
        <form onSubmit={this.onSubmit}>
          <Button color="info" type="submit" className='w-75'>Sign In with Facebook</Button>
          {error && <p>{error.message}</p>}
        </form>
      </Col>
    );
  }
}*/

/*class SignInTwitterBase extends Component {
  constructor(props) {
    super(props);

    this.state = { error: null };
  }

  onSubmit = event => {
    this.props.firebase
      .doSignInWithTwitter()
      .then(socialAuthUser => {
        // Create a user in your Firebase Realtime Database too
        return this.props.firebase.user(socialAuthUser.user.uid).set(
          {
            //name: socialAuthUser.additionalUserInfo.profile.name,
            email: socialAuthUser.additionalUserInfo.profile.email,
            //permission: 'viewer',
          },
          { merge: true },
        );
      })
      .then(() => {
        this.setState({ error: null });
        this.props.history.push('/watch');
      })
      .catch(error => {
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS;
        }

        this.setState({ error });
      });

    event.preventDefault();
  };

  render() {
    const { error } = this.state;

    return (
      <Col xs="12" sm="12" md="4" lg="4">
        <form onSubmit={this.onSubmit}>
          <Button color="info" type="submit" className='w-75'>Sign In with Twitter</Button>
          {error && <p>{error.message}</p>}
        </form>
      </Col>
    );
  }
}*/

const LoginLink = () => (
  <div>
    <p>
      Already have an account? <Link to={'/login'}>Log In</Link>
    </p>
    <p>
      By signing up for our service, you are certifying that you accept and agree to be bound by our <a href="/privacy" target="_blank">privacy policy</a> and <a href="/terms" target="_blank">terms of use</a>.
    </p>
  </div>
);

const SignInForm = compose(
  withRouter,
  withFirebase,
)(SignInFormBase);

/*const SignInGoogle = compose(
  withRouter,
  withFirebase,
)(SignInGoogleBase);*/

//const SignInFacebook = compose(
//  withRouter,
//  withFirebase,
//)(SignInFacebookBase);

//const SignInTwitter = compose(
//  withRouter,
//  withFirebase,
//)(SignInTwitterBase);

export default SignInPage;

export { SignInForm, LoginLink };
