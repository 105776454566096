import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import AuthUserContext from './context';
import { withFirebase } from '../Firebase';

const withAuthorization = condition => Component => {
  class WithAuthorization extends React.Component {

    constructor(props) {
      super(props);

      this.state = {
        stripeUser: null,
        stripePlan: null,
        stripeValid: null,
        stripeMessage: '',
      };
    }

    componentDidMount() {

      this.listener = this.props.firebase.onAuthUserListener(

        // If we got authentication data of some sort
        authUser => {

          // If the authentication data was NOT valid
          if (!condition(authUser)) {
            this.props.history.push('/login');
          }

          // We have a user, now try to get their stripe subscription
          this.props.firebase.db.collection('users').doc(authUser.uid).get()

          // Wait until we have our subscription info
          .then(function(result) {

            //console.log('[Debug] 1) We have a user object!');

            // If there is data
            if (result.data() && result.data().stripeCustomer === authUser.stripeCustomer) {

              //console.log('[Debug] 2) The user matches:', result.data());

              // If they actually have subscriptions
              if (result.data().stripeSubscription) {

                var item = result.data().stripeSubscription;

                //console.log('[Debug] 3) Checking the user subscription', item);

                // If they have an active plan
                if (item.plan && item.plan.active) {

                  //console.log('[Debug] 4) Their plan is active!');

                  // If they are past due
                  if (item.status === 'past_due' || item.status === 'unpaid') {

                    //console.log('[Debug] Subscription is past due or unpaid!');

                    // Set the state, then redirect
                    this.setState({
                      stripeUser: authUser.stripeCustomer,
                      stripePlan: item,
                      stripeValid: false,
                      stripeMessage: 'Your subscription is past due or unpaid. Please enter payment details to continue to use the service or cancel your subscription to avoid further charges.',
                    });
                  }

                  // If they canceled
                  else if (item.status === 'canceled') {

                    //console.log('[Debug] Subscription is canceled!');

                    // Set the state, then redirect
                    this.setState({
                      stripeUser: authUser.stripeCustomer,
                      stripePlan: item,
                      stripeValid: false,
                      stripeMessage: 'Your subscription was cancelled (either by you, or due to non-payment). Please enter payment details to continue to use the service.',
                    });
                  }

                  // If they are trialing
                  else if (item.status === 'trialing') {

                    //console.log('[Debug] Subscription is on trial!');

                    // Set the state, then redirect
                    this.setState({
                      stripeUser: authUser.stripeCustomer,
                      stripePlan: item,
                      stripeValid: true,
                      stripeMessage: 'Your free trial is active.',
                    });
                  }

                  // If they are active
                  else if (item.status === 'active') {

                    //console.log('[Debug] Subscription is active and paid up!');

                    this.setState({
                      stripeUser: authUser.stripeCustomer,
                      stripePlan: item,
                      stripeValid: true,
                      stripeMessage: '',
                    });
                  }
                }

                // No plan
                else {
                  this.setState({
                    stripeUser: authUser.stripeCustomer,
                    stripePlan: null,
                    stripeValid: false,
                    stripeMessage: 'You do not have a plan on your subscription. We are very sorry! If this keeps happening, please contact support (support@finallyfiltered.com)!',
                  }, function() {
                    if (this.props.location.pathname !== '/profile') {
                      this.props.history.push('/profile');
                    }
                  });
                }
              }
              else {

                //console.log('[Debug] There is no subscription on the user object!');

                this.setState({
                  stripeUser: authUser.stripeCustomer,
                  stripePlan: null,
                  stripeValid: false,
                  stripeMessage: 'You do not have a subscription. If you DO have a subscription and you keep getting this message, please contact support (support@finallyfiltered.com)!',
                }, function() {
                  if (this.props.location.pathname !== '/profile') {
                    this.props.history.push('/profile');
                  }
                });
              }
            }

            // No results
            else {

              //console.log('[Debug] No subscription data!');

              this.setState({
                stripeMessage: 'We could not retrieve your subscription data at this time. We are very sorry! If this keeps happening, please contact support (support@finallyfiltered.com)!',
              }, function() {
                if (this.props.location.pathname !== '/profile') {
                  this.props.history.push('/profile');
                }
              });
            }
          }.bind(this))

          // If there was an error
          .catch(function(error) {
            //console.log('[Debug] Error getting subscription!', error);
            this.setState({
              stripeMessage: 'We could not retrieve your subscription at this time. We are very sorry! If this keeps happening, please contact support (support@finallyfiltered.com)!',
            });
          }.bind(this));
        },

        // If we didn't get authentication data
        () => this.props.history.push('/login'),
      );
    }

    componentWillUnmount() {
      this.listener();
    }

    render() {

      if (this.state.stripeUser !== null || this.state.stripePlan !== null || this.state.stripeValid !== null || this.state.stripeMessage !== '') {
        return (
          <AuthUserContext.Consumer>
            {authUser =>
              condition(authUser) ? <Component {...this.props} stripeUser={this.state.stripeUser} stripePlan={this.state.stripePlan} stripeValid={this.state.stripeValid} stripeMessage={this.state.stripeMessage} /> : null
            }
          </AuthUserContext.Consumer>
        );
      }

      else {
        return null;
      }
    }
  }

  return compose(
    withRouter,
    withFirebase,
  )(WithAuthorization);
};

export default withAuthorization;
