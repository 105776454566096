//=============================================== OLD ===============================================

import React, { Component } from "react";
import { Card, CardHeader, CardBody, CardTitle, CardFooter, Row, Col, Input, Alert } from "reactstrap";

import CardAuthor from "components/CardElements/CardAuthor.jsx";
import FormInputs from "components/FormInputs/FormInputs.jsx";
import Button from "components/CustomButton/CustomButton.jsx";

import damirBosnjak from "assets/img/damir-bosnjak.jpg";
import mike from "assets/img/mike.jpg";
import ayoOgunseinde2 from "assets/img/faces/ayo-ogunseinde-2.jpg";
import joeGardner2 from "assets/img/faces/joe-gardner-2.jpg";
import clemOnojeghuo2 from "assets/img/faces/clem-onojeghuo-2.jpg";

//=============================================== NEW ===============================================

import { compose } from 'recompose';

import {
  AuthUserContext,
  withAuthorization,
  withEmailVerification,
} from 'old-components/Session';
import { withFirebase } from 'old-components/Firebase';
import { PasswordForgetForm } from 'old-components/PasswordForget';
import StreamingChannels from 'old-components/StreamingChannels';
import PasswordChangeForm from 'old-components/PasswordChange';
import UserDetailsForm from 'old-components/UserDetails';
import UserPaymentForm from 'old-components/UserPayment';
import UserDeleteForm from 'old-components/UserDelete';

const SIGN_IN_METHODS = [
  {
    id: 'password',
    provider: null,
  },
  {
    id: 'google.com',
    provider: 'googleProvider',
  },
  // {
  //   id: 'facebook.com',
  //   provider: 'facebookProvider',
  // },
  // {
  //   id: 'twitter.com',
  //   provider: 'twitterProvider',
  // },
];

class LoginManagementBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeSignInMethods: [],
      error: null,
    };

    this.onDismiss = this.onDismiss.bind(this);
  }

  // When they dismiss the error message
  onDismiss() {
    this.setState({ error: null });
  }

  componentDidMount() {
    this.fetchSignInMethods();
  }

  fetchSignInMethods = () => {
    this.props.firebase.auth
      .fetchSignInMethodsForEmail(this.props.authUser.email)
      .then(activeSignInMethods =>
        this.setState({ activeSignInMethods, error: null }),
      )
      .catch(error => this.setState({ error }));
  };

  onSocialLoginLink = provider => {
    this.props.firebase.auth.currentUser
      .linkWithPopup(this.props.firebase[provider])
      .then(this.fetchSignInMethods)
      .catch(error => this.setState({ error }));
  };

  onDefaultLoginLink = password => {
    const credential = this.props.firebase.emailAuthProvider.credential(
      this.props.authUser.email,
      password,
    );

    this.props.firebase.auth.currentUser
      .linkAndRetrieveDataWithCredential(credential)
      .then(this.fetchSignInMethods)
      .catch(error => this.setState({ error }));
  };

  onUnlink = providerId => {
    this.props.firebase.auth.currentUser
      .unlink(providerId)
      .then(this.fetchSignInMethods)
      .catch(error => this.setState({ error }));
  };

  render() {
    const { activeSignInMethods, error } = this.state;

    return (
      <div>
        {SIGN_IN_METHODS.map(signInMethod => {
          const onlyOneLeft = activeSignInMethods.length === 1;
          const isEnabled = activeSignInMethods.includes(
            signInMethod.id,
          );

          if (signInMethod.id === 'password') {
            return (
              <DefaultLoginToggle
                key={signInMethod.id}
                onlyOneLeft={onlyOneLeft}
                isEnabled={isEnabled}
                signInMethod={signInMethod}
                onLink={this.onDefaultLoginLink}
                onUnlink={this.onUnlink}
              />
            );
          } else {
            return (
              <SocialLoginToggle
                key={signInMethod.id}
                onlyOneLeft={onlyOneLeft}
                isEnabled={isEnabled}
                signInMethod={signInMethod}
                onLink={this.onSocialLoginLink}
                onUnlink={this.onUnlink}
              />
            );
          }
        })}
        {error && <Alert color="danger" toggle={this.onDismiss}>{error.message}</Alert>}
      </div>
    );
  }
}

const SocialLoginToggle = ({
  onlyOneLeft,
  isEnabled,
  signInMethod,
  onLink,
  onUnlink,
}) =>
  isEnabled ? (
    <Button
      color="primary"
      type="button"
      onClick={() => onUnlink(signInMethod.id)}
      disabled={onlyOneLeft}
    >
      Deactivate {signInMethod.id.replace('.com', '')} Login
    </Button>
  ) : (
    <Button
      color="primary"
      type="button"
      onClick={() => onLink(signInMethod.provider)}
    >
      Link {signInMethod.id.replace('.com', '')} Login
    </Button>
  );

class DefaultLoginToggle extends Component {
  constructor(props) {
    super(props);

    this.state = { passwordOne: '', passwordTwo: '' };
  }

  onSubmit = event => {
    event.preventDefault();

    this.props.onLink(this.state.passwordOne);
    this.setState({ passwordOne: '', passwordTwo: '' });
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const {
      onlyOneLeft,
      isEnabled,
      signInMethod,
      onUnlink,
    } = this.props;

    const { passwordOne, passwordTwo } = this.state;

    const isInvalid =
      passwordOne !== passwordTwo || passwordOne === '';

    return isEnabled ? (
      <Button
        color="primary"
        type="button"
        onClick={() => onUnlink(signInMethod.id)}
        disabled={onlyOneLeft}
      >
        Deactivate {signInMethod.id.replace('.com', '')} Login
      </Button>
    ) : (
      <form onSubmit={this.onSubmit}>
        <Row>
          <Col xs="12" sm="12" md="4" lg="4">
            <Input type="password" name="passwordOne" placeholder="Password" value={passwordOne} onChange={this.onChange} className="mb-3" />
          </Col>
          <Col xs="12" sm="12" md="4" lg="4">
            <Input type="password" name="passwordTwo" placeholder="Confirm Password" value={passwordTwo} onChange={this.onChange} className="mb-3" />
          </Col>
          <Col xs="12" sm="12" md="4" lg="4">
            <Button color="primary" type="submit">Enable {signInMethod.id} Login</Button>
          </Col>
        </Row>
      </form>
    );
  }
}

const AccountPage = () => (
  <AuthUserContext.Consumer>
    {authUser => (
      <div className="content">
        <Card>
          <CardBody>
            <UserDetailsForm authUser={authUser} />
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <UserPaymentForm authUser={authUser} />
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <StreamingChannels authUser={authUser} />
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <PasswordChangeForm authUser={authUser} />
            {/*
            <h5>Sign In Methods</h5>
            <LoginManagement authUser={authUser} />*/}
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <UserDeleteForm authUser={authUser} />
          </CardBody>
        </Card>
      </div>
    )}
  </AuthUserContext.Consumer>
);

class User extends React.Component {

  //=============================================== OLD ===============================================

  render() {
    return (
      <div className="content">
        <Row>
          <Col md={8} xs={12}>
            <Card className="card-user">
              <CardHeader>
                <CardTitle>Edit Profile</CardTitle>
              </CardHeader>
              <CardBody>
                <form>
                  <FormInputs
                    ncols={["col-md-5 pr-1", "col-md-3 px-1", "col-md-4 pl-1"]}
                    proprieties={[
                      {
                        label: "Company (disabled)",
                        inputProps: {
                          type: "text",
                          disabled: true,
                          defaultValue: "Creative Code Inc."
                        }
                      },
                      {
                        label: "Name",
                        inputProps: {
                          type: "text",
                          defaultValue: "chetfaker"
                        }
                      },
                      {
                        label: "Email address",
                        inputProps: {
                          type: "email",
                          placeholder: "Email"
                        }
                      }
                    ]}
                  />
                  <FormInputs
                    ncols={["col-md-6 pr-1", "col-md-6 pl-1"]}
                    proprieties={[
                      {
                        label: "First Name",
                        inputProps: {
                          type: "text",
                          placeholder: "First Name",
                          defaultValue: "Chet"
                        }
                      },
                      {
                        label: "Last Name",
                        inputProps: {
                          type: "text",
                          placeholder: "Last Name",
                          defaultValue: "Faker"
                        }
                      }
                    ]}
                  />
                  <FormInputs
                    ncols={["col-md-12"]}
                    proprieties={[
                      {
                        label: "Address",
                        inputProps: {
                          type: "text",
                          placeholder: "Home Address",
                          defaultValue:
                            "Melbourne, Australia"
                        }
                      }
                    ]}
                  />
                  <FormInputs
                    ncols={["col-md-4 pr-1", "col-md-4 px-1", "col-md-4 pl-1"]}
                    proprieties={[
                      {
                        label: "City",
                        inputProps: {
                          type: "text",
                          defaultValue: "Melbourne",
                          placeholder: "City"
                        }
                      },
                      {
                        label: "Country",
                        inputProps: {
                          type: "text",
                          defaultValue: "Australia",
                          placeholder: "Country"
                        }
                      },
                      {
                        label: "Postal Code",
                        inputProps: {
                          type: "number",
                          placeholder: "ZIP Code"
                        }
                      }
                    ]}
                  />
                  <FormInputs
                    ncols={["col-md-12"]}
                    proprieties={[
                      {
                        label: "About Me",
                        inputProps: {
                          type: "textarea",
                          defaultValue:
                            "Oh so, your weak rhyme You doubt I'll bother, reading into it",
                          placeholder: "Here can be your description"
                        }
                      }
                    ]}
                  />
                  <Row>
                    <div className="update ml-auto mr-auto">
                      <Button color="primary" round>Update Profile</Button>
                    </div>
                  </Row>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }

  //=============================================== NEW ===============================================

}

const LoginManagement = withFirebase(LoginManagementBase);

const condition = authUser => !!authUser;

export default compose(
  withFirebase,
  withEmailVerification,
  withAuthorization(condition),
)(AccountPage);
