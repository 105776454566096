import React, { Component } from 'react';

import {
  Input, Row, Col, Button, Alert
} from "reactstrap";

import { withFirebase } from '../Firebase';

class UserDeleteForm extends Component {

  constructor(props) {
    super(props);

    this.state = {
      email: '',
      id: '',
      name: '',
    };

    this.onDismiss = this.onDismiss.bind(this);
  }

  // On mount, grab all of the filters
  componentDidMount() {

    // Wait for a login event to set things
    this.listener = this.props.firebase.onAuthUserListener(

      // If we have a logged in user
      currentUser => {

        // Set the state
        this.setState({
          email: currentUser.email,
          id: currentUser.uid,
          name: currentUser.name,
        });
      }
    );
  }

  componentWillUnmount() {
    this.listener();
  }

  // When they dismiss the error message
  onDismiss() {
    this.setState({ error: null });
  }

  // When they actually hit the submit button
  onClick = event => {

    // Don't let it submit right away
    event.preventDefault();

    // Save the email
    const email = this.state.email;
    const id = this.state.id;
    const name = this.state.name;

    // If we have an email, we get a confirmation
    if (email !== '' && id !== '') {

      var c = window.confirm('Are you sure you want to delete this account (' + name + ')? This action is PERMANENT!');

      if (c === true) {

        // Mark the user for deletion (this sets a flag that the cloud function then handles)
        var userRef = this.props.firebase.db.collection('users').doc(this.props.firebase.auth.currentUser.uid);
        userRef.update({
          deleteMyAccountNow: 'DoItNow',
        })

        // If it worked!
        .then(function() {
          this.props.firebase.doSignOut();
        }.bind(this))

        // If there was a problem
        .catch(function(error) {

          console.log('Error! ', error);

          this.setState({
            error: 'There was a problem deleting your account. Please contact support (support@finallyfiltered.com).',
          });
        });
      }
    }

    // If we don't, then we don't let it proceed
    else {
      this.setState({
        error: 'We could not retrieve your account information.',
      });
    }
  };

  render() {

    const name = this.state.name;
    const error = this.state.error;
    const isInvalid = name === '';

    return (
      <div>
        <h5>Delete Account</h5>
        <form>
          <Row>
            <Col xs="12" sm="12" md="4" lg="4">
              <Button color="primary" disabled={isInvalid} onClick={this.onClick} type="submit">Delete Account</Button>
            </Col>
          </Row>

          {error && <Alert color="danger" toggle={this.onDismiss}>{error.message}</Alert>}
        </form>
      </div>
    );
  }
}

export default withFirebase(UserDeleteForm);
